


<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">{{ riskName }}</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex" @click="filterOpen" :class="riskAcceptanceObj.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                        <!-- <button class="btnprimary mr-2 flex items-center" >Risk History</button> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 text-xs">
            <div>
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
            </div>
        </div>
        <div>
            <newTable :tableRowData="riskAcceptanceObj" :headersData="riskHeaders" :openPopup="true" :loadData="dataLoading"></newTable>
            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>
        </div>

        <popup class="applyPopup" v-if="applyPopup" :popUp="applyPopup">
           
            <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
                <div class="relative w-full max-w-xl">
                    <!--content-->
                    <div class="border-0 rounded relative flex flex-col w-full bg-white risksAcceptance h-screen">
                        <!--header-->
                        <div class="flex items-center justify-between p-3 rounded popupheadingcontainer">
                            <h1 v-if="thisScreen == 'Acceptance'">Risk Accept</h1>
                            <h1 v-if="thisScreen == 'reset'">Risk Reset</h1>
                            <h1 v-if="thisScreen == 'deactive'">Risk Delete</h1>
                            <button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onCrossbtn()">
                                <!-- <XIcon class="h-5 w-5" /> -->
                                <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                            </button>
                        </div>
                        <div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
                        <div>
                            <div class="impactcontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-col">
                                        <div class="upperspacing">
                                            <label class="controllabel" v-if="systemHead">Systems</label>
                                            <label class="controllabel" v-if="processHead">Process</label>
                                            <label class="controllabel" v-if="organizationHead">Organization</label>
                                           <div class="text-xs flex flex-wrap">
                                                <div v-for="obj,index in mainSystemBind" :key="index" class="mr-2 mb-2 bg-primary text-white px-2 py-1 rounded">
                                                    {{obj.label}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="border-b border-solid border-lightgrey upperspacing"></div>
                                        <div class="w-full upperspacing">
                                            <label v-if="thisScreen == 'Acceptance'" class="controllabel">Reason for Accepting Risk / Justification</label>
                                            <label v-if="thisScreen == 'reset'" class="controllabel">Reason for Reset Risk / Justification</label>
                                            <label v-if="thisScreen == 'deactive'" class="controllabel">Reason for Delete Risk / Justification</label>
                                            <textarea type="text" class="inputboxstyling h-20 max-h-48" v-model.trim="applyObj.comments" placeholder="Reason / Justification"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--footer-->
                        <div class="buttonposition flex justify-center items-center">
                            <button :disabled="clickOnButton" @click="cancelAceeptResetBtn()" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="mr-3">Cancel</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'Acceptance'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="createTaskForAction(applyObj, 10403)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'reset'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary" @click="createTaskForAction(applyObj, 10407)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'deactive'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary" @click="createTaskForAction(applyObj, 10404)">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </popup>

        <popup v-if="showThreatPopup" :popUp="showThreatPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Risk</h1>
                    <button class="float-right text-3xl" @click="onCrossbtn()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-business">
                <div>
                    <div>
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Risk</label> 
                                            <textarea class="disabledtextarea" placeholder="Risk" v-model="riskThreatData.riskCategory" disabled />
                                        </div>
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-3/6">
                                            <div>
                                                <label class="controllabel">Threat Source</label>
                                                <input type="text" class="disabledinputboxstyling" v-model="riskThreatData.threatSource" disabled placeholder="Threat Source" />
                                            </div>
                                        </div>
                                        <div class="w-3/6 leftspacing">
                                            <div>
                                                <label class="controllabel">Threat Category</label>
                                                <input type="text" class="disabledinputboxstyling" v-model="riskThreatData.threatCategory" disabled placeholder="Threat Category" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-3/6">
                                            <div>
                                                <label class="controllabel">Risk Category</label>
                                                <input type="text" class="disabledinputboxstyling" v-model="riskThreatData.risk" placeholder="Risk" disabled />
                                            </div>
                                        </div>
                                        <div class="w-3/6 leftspacing">
                                            <div>
                                                <label class="controllabel">Threat Event</label>
                                                <input type="text" class="disabledinputboxstyling" v-model="riskThreatData.threatEvent" placeholder="Threat Event" disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                        <button class="filterButton" @click="editRiskThreatData(riskThreatData.mappedRisk, riskThreatData._id)">Edit</button>
                    </div>
                </div>
            </div>
        </popup>

        <popup v-if="openRiskPopup" :popUp="openRiskPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Risk Information</h1>
                    <button class="float-right text-3xl" @click="onCrossbtn()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <div class="popup-business">
                <div>
                    <div>
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Risk</label> 
                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Risk"
                                                @input ="restrictCharacters('Risk')"
                                                v-model.trim="riskThreatData.risk"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.risk.$error||displayErrorMessageOfRisk
                                                }"
                                            />
                                            <div v-if="v$.riskThreatData.risk.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.risk.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfRisk == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-3/6 ">
                                            <div>
                                                <label class="controllabel">Risk Category</label>
                                                
                                                <multiselect 
                                                    v-model="riskThreatData.riskCategory"
                                                    :options="riskCategoryList" 
                                                    :searchable="true" 
                                                    :close-on-select="false" 
                                                    :show-labels="false"
                                                    :hide-selected="true"
                                                    placeholder="Risk Category"
                                                    open-direction="bottom"
                                                    class="custom-multiselect"
                                                    :class="{
                                                        'is-invalid red-border': v$.riskThreatData.riskCategory.$error,
                                                        'has-options': riskCategoryList && riskCategoryList.length > 0,
                                                        'no-options':  !riskCategoryList.length,
                                                    }"
                                                >
                                                <template v-slot:noResult>
                                                        <span class="text-xs">No Categories Found</span>
                                                    </template>
                                                </multiselect>
                                                <div v-if="v$.riskThreatData.riskCategory.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.riskCategory.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-3/6 leftspacing">
                                            <label class="controllabel">Risk Owner</label> 
                                           
                                            <multiselect
                                                v-model="riskThreatData.riskOwner"
                                                :options="userOptions"
                                                :showNoOptions="false"
                                                @search-change="asyncFind"
                                                :limit-text="limitText"
                                                :multiple="false"
                                                :hide-selected="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Risk Owner"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid boredrRed': v$.riskThreatData.riskOwner.$error,
                                                    'has-options': userOptions && userOptions.length > 0,
                                                    'no-options': !userOptions.length &&  searchValue !== '',
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Members Found</span>
                                                </template>
                                            </multiselect>
                                            <div v-if="v$.riskThreatData.riskOwner.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.riskOwner.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Threat</label> 
                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Threat"
                                                @input ="restrictCharacters('Threat')"
                                                v-model.trim="riskThreatData.threatEvent"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.threatEvent.$error||displayErrorMessageOfThreat
                                                }"
                                            />

                                            <div v-if="v$.riskThreatData.threatEvent.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.threatEvent.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfThreat == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-3/6">
                                            <label class="controllabel">Threat Source</label>
                                            <multiselect 
                                                v-model="riskThreatData.threatSource"
                                                :options="threatSourceList" 
                                                :searchable="true" 
                                                :close-on-select="false" 
                                                :show-labels="false"
                                                :hide-selected="true"
                                                placeholder="Threat Source"
                                                open-direction="bottom"
                                                class="custom-multiselect"
                                                :class="{
                                                    'is-invalid red-border': v$.riskThreatData.threatSource.$error,
                                                    'has-options': threatSourceList && threatSourceList.length > 0,
                                                    'no-options':  !threatSourceList.length,
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Source Found</span>
                                                </template>
                                            </multiselect>
                                        </div>
                                        <div class="w-3/6 leftspacing">
                                            <label class="controllabel">Threat Category</label>
                                            
                                            <multiselect 
                                                v-model="riskThreatData.threatCategory"
                                                :options="threatCategoryList" 
                                                :searchable="true" 
                                                :close-on-select="false" 
                                                :show-labels="false"
                                                :hide-selected="true"
                                                placeholder="Risk Category"
                                                open-direction="bottom"
                                                class="custom-multiselect"
                                                :class="{
                                                    'is-invalid red-border': v$.riskThreatData.threatCategory.$error,
                                                    'has-options': threatCategoryList && threatCategoryList.length > 0,
                                                    'no-options':  !threatCategoryList.length,
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Categories Found</span>
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Vulnerability</label> 
                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Vulnerability"
                                                @input ="restrictCharacters('Vulnerability')"
                                                v-model.trim="riskThreatData.vulnerability"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.vulnerability.$error||displayErrorMessageOfVulnerability
                                                }"
                                            />

                                            <div v-if="v$.riskThreatData.vulnerability.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.vulnerability.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfVulnerability == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>
                                    <h1 class="upperspacing">Impact Analysis</h1>
                                    <div class="border-b pt-1 border-solid border-lightgrey"></div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Startegic</label> 
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.business"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.business.$error,'inputboxstylingForSelectAtPopup': riskThreatData.business === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.business !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in dbBussiness" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.business.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.business.required.$invalid">Required</div>
                                            </div>
                                        </div>

                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Financial</label> 

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.financial"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.financial.$error,'inputboxstylingForSelectAtPopup': riskThreatData.financial === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.financial !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in dbFinancial" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.financial.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.financial.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Operations</label> 
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.operations"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.operations.$error,'inputboxstylingForSelectAtPopup': riskThreatData.operations === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.operations !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in dbOperations" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.operations.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.operations.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Compliance</label> 
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.legal"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.legal.$error,'inputboxstylingForSelectAtPopup': riskThreatData.legal === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.legal !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in dbLegal" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.legal.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.legal.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <h1 class="upperspacing">Risk Analysis</h1>
                                    <div class="border-b pt-1 border-solid border-lightgrey"></div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        
                                            <div class="w-1/2">
                                            <label class="controllabel">Likelihood</label> 

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.likelihood"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.likelihood.$error,'inputboxstylingForSelectAtPopup': riskThreatData.likelihood === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.likelihood !== ''
                                                }"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in dbLikelihood" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.likelihood.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.likelihood.required.$invalid">Required</div>
                                            </div>
                                            </div>
                                            <div class="w-1/2 leftspacing">
                                            <label class="controllabel">Overall Impact</label>
                                            <select class="inputboxstyling" disabled v-model="riskThreatData.overallImpact">
                                                <option value="" selected hidden>Please select one</option>
                                                <option v-for="i in dbSeverity" :value="i.value" :key="i">
                                                    {{ i.key }}
                                                </option>
                                            </select>
                                            </div>
                                        </div>
                                    <div class="flex mt-2 justify-center">
                                        <button :disabled="clickOnButton" class="btnprimary mr-2 gap-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelRiskData()">Cancel</button>
                                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" @click="addOrUpdaterisk(riskThreatData)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>

        <popup v-if="showPriorityPopUp">
             <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Risk Prioritize</h1>

                    <button class="float-right text-3xl" @click="closePriorityPopup()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-priority">
                <div class="w-full popupbodycontainer">
                        <div class=" w-full upperspacing">
                            <label class="controllabel font-normal text-sm mb-1" for="vulnerability">Risk Name</label> 
                            <input 
                                v-model="riskPriorityShow.risk"
                                disabled        
                                placeholder="Name of the Risk"
                                id="id"
                                class="disabledinputboxstyling"
                                :class="{
                                            'is-invalid': v$.riskPriorityShow.risk.$error
                                        }"
                                  
                            />
                            <div class="text-red-500" v-if="v$.riskPriorityShow.risk.$error">
                                <div v-if="v$.riskPriorityShow.risk.required.$invalid" class="error-text text-xs" >Risk name is required</div>
                            </div>
                        </div>

                        <div class=" w-1/2 upperspacing">
                        <label class="controllabel">Priority</label>
                            <select class="inputboxstyling" v-model="riskPriorityShow.priority">
                                <option v-for="(priority,index) in dbPriority" :key="index" :value="priority.value">{{ priority.key }}</option>
                            </select>
                        </div>

                            <div class="text-red-500"  v-if="v$.riskPriorityShow.priority.$error">
                                <div v-if="v$.riskPriorityShow.priority.required.$invalid" class="error-text text-xs" >Please change priority</div>
                            </div>
                        <div v-if="this.riskPriorityShow.priority!==this.priorityDummy.priority">
                            <div class="row flex flex-row w-full">
                                <div class=" w-full upperspacing">
                                    <label for="Risk" class="controllabel">Justification</label> 
                                    <textarea class="inputboxstyling" placeholder="Justification" v-model.trim="riskPriorityShow.justification" :class="{
                                            'is-invalid': v$.riskPriorityShow.justification.$error
                                        }"/>
                                </div>
                            </div>
                            <div class="text-red-500"  v-if="v$.riskPriorityShow.justification.$error">
                                <div v-if="v$.riskPriorityShow.justification.required.$invalid" class="error-text text-xs" >Justification is required</div>
                            </div>
                        </div>


                        <div class="buttonposition flex justify-center items-center mt-9">
                            <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="JSON.stringify(riskPriorityShow) !== JSON.stringify(priorityDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelPriorityBtn()">Cancel</button>
                            <button :disabled="clickOnButton" @click="addOrUpdatePriority()" :class="JSON.stringify(riskPriorityShow) !== JSON.stringify(priorityDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                        </div> 
                </div>
                    
            </div>            
        </popup>
    </div>
</template>
<style scoped>
.borderRed{
    border: 1px solid red !important;
}
.positiontooltip {
    font-size: 12px;
}
.tip {
    display: inline;
}
.flexcontainer {
    overflow-x: hidden;
}
.z-index {
    z-index: 1000;
}
.dropdown {
    position: absolute;
    background-color: white !important;
    color: black;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { toast } from '@/main';
import popup from '@/components/popup.vue';
import store from '@/store';
import { emitter } from '@/main';
import breadCrumb from '@/components/breadCrumb.vue';
import pagination from '@/components/pagination.vue';
import dropdown from '@/components/dropdown.vue';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import toolTip from '@/components/toolTip.vue';
import { mapGetters } from 'vuex';
import newTable from '@/components/newTable.vue';

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            updateArray: [],
            pop: false,
            applyPopup: false,
            filterDummy: {
                severity: '',
                statusCode: ''
            },
            xPos: '',
            yPos: '',
            currentSort: 'name',
            currentSortDir: 'asc',
            classSort: '',
            searchValue: '',
            className: '',
            selected: '',
            selectedRowIndex: -1,
            showBlock: false,
            showDropdown: false,
            riskAcceptanceObj: [],
            selectedRisk: { risk: '', severity: '', riskCategory: '' },
            showFilters: false,
            openRiskPopup: false,
            showThreatPopup: false,
            organizationHead: false,
            processHead: false,
            systemHead: false,
            mainTierRiskData: null,
            clickOnButton: false,
            mainSystemBind: [],
            mappedSystemsOptions: [],
            applyObj: {
                comments: ''
            },
            riskHeaders: [],
            optionsValue: [
                {
                    id: 1,
                    value: 'Accept',
                    route: '/riskAcceptance'
                },
                {
                    id: 2,
                    value: 'Mitigate',
                    route: '/mitigateRisk'
                },
                {
                    id: 3,
                    value: 'Reset',
                    route: '/reset'
                },
                {
                    id: 4,
                    value: 'Delete',
                    route: '/deactive'
                }
            ],

            riskCategoryList: [],
            threatSourceList: [],
            threatCategoryList: [],

            riskThreatData: {
                status: '',
                risk: '',
                threatCategory: '',
                threatSource: '',
                threatEvent: '',
                vulnerability: '',
                business: '',
                operations: '',
                legal: '',
                financial: '',
                likelihood: '',
                riskCategory: '',
                overallImpact: '',
                severity: '',
                riskOwner: ''
            },
            riskName: '',
            thisScreen: '',
            tasksData: [],
            insertedIds: [],
            risksValue: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Accepted'
                },
                {
                    id: 10404,
                    value: 'Deleted'
                },
                {
                    id: 10405,
                    value: 'Mitigated'
                },
                {
                    id: 10406,
                    value: 'Partial'
                }
            ],
            // permissions: [],
            currentPage: '',
            pageSize: '',
            dynamicPagination: '',
            mainSystemRisk: null,
            optionsRiskList: [],
            selectedCategory: [],
            orgUsersList: [],
            optionsUserArray: [],
            riskObjCopy: null,

            mappedSystems: [],
            mappedProcess: [],
            entitiesSelect: [],
            mappedOrganization: [],
            businessProcessList: [],
            routesArray: [],
            presentSystemRiskId: '',
             showPriorityPopUp:false,
            riskPriorityShow:{},
            priorityDummy : {},
            userOptions:[],

            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
			displayErrorMessageOfRisk : false,
			displayErrorMessageOfThreat : false,
			displayErrorMessageOfVulnerability : false,
        };
    },
    components: {
        popup,
        breadCrumb,
        Multiselect,
        newTable,
        toolTip
    },

    computed: {
        ...mapGetters({ userInfo: 'userInfo',dbLikelihood:'dbLikelihood',dbBussiness:'dbBussiness',
            dbOrganization:'dbOrganization',dbOperations:'dbOperations',dbLegal:'dbLegal',
            dbFinancial:'dbFinancial',dbTechnical:'dbTechnical',dbPriority:'dbPriority',dbSeverity: 'dbSeverity',}),

        disableStatus: function (): any {
            let result;
            let objCopy = Object.assign({}, this.riskThreatData);

            if (_.isEqual(objCopy, this.riskObjCopy)) {
                result = false;
            } else {
                result = true;
            }
            return result;
        },
        sortedData: function (): any {
            return [...this.riskAcceptanceObj]
                .filter((row: any) => {
                    for (const key in this.filterDummy) {
                        const inputValue = this.filterDummy[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        }
    },
    validations() {
        return {
            riskThreatData: {
                risk: { required },
                threatCategory: { required },
                threatSource: { required },
                threatEvent: { required },
                vulnerability: { required },
                business: { required },
                operations: { required },
                legal: { required },
                financial: { required },
                likelihood: { required },
                riskCategory: { required },
                riskOwner: { required }
            },
            riskPriorityShow:{
                risk:{required},
                priority: { required:requiredIf(()=>{
                    return this.priorityDummy.priority===this.riskPriorityShow.priority;
                })},
                justification:{required}
            }
        };
    },
    
    methods: {
         async calculateOverallImpact(){
            let a1 = this.riskThreatData.business == '' ? 0 : this.riskThreatData.business;
            let a2 = this.riskThreatData.operations == '' ? 0 : this.riskThreatData.operations;
            let a3 = this.riskThreatData.legal == '' ? 0 : this.riskThreatData.legal;
            let a4 = this.riskThreatData.financial == '' ? 0 : this.riskThreatData.financial;

            let arr = [a1, a2, a3, a4]

            let avg =  arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
            this.riskThreatData.overallImpact = Math.max(Math.round(avg), 1);
        },
        limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any){
            this.searchValue = query
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        onCancelRiskData() {
            this.riskThreatData = { ...this.riskObjCopy };
                this.displayErrorMessageOfRisk = false
                this.displayErrorMessageOfThreat = false
                this.displayErrorMessageOfVulnerability = false
        },
        restrictCharacters(code: any){
            if(code === 'Threat'){
                if(this.riskThreatData.threatEvent.length > 300){
                this.displayErrorMessageOfThreat = true;
                }else if(this.riskThreatData.threatEvent.length < 300 || this.riskThreatData.threatEvent.length == 300){
                    this.displayErrorMessageOfThreat = false;
                }
            } else if(code === 'Risk'){
                if(this.riskThreatData.risk.length > 300){
                    this.displayErrorMessageOfRisk = true;
                }else if(this.riskThreatData.risk.length < 300 || this.riskThreatData.risk.length == 300){
                    this.displayErrorMessageOfRisk = false;
                }
            } else if(code === 'Vulnerability'){
                if(this.riskThreatData.vulnerability.length > 300){
                    this.displayErrorMessageOfVulnerability = true;
                }else if(this.riskThreatData.vulnerability.length < 300 || this.riskThreatData.vulnerability.length == 300){
                    this.displayErrorMessageOfVulnerability = false;
                }
            }
            
        },
        toggleDropdown(risk: any, index: any, e: any) {
            console.log('showBlockbef', this.showBlock);
            this.showBlock = !this.showBlock;
            this.selectedRowIndex = index;
            this.mainTierRiskData = risk;
            this.showDropdown = false;
            console.log('event', e);
            console.log('showBlockaft', this.showBlock);
        },
        changeRoute(risk: any, route: any) {
            this.mainTierRiskData = risk;

            if (route == '/riskAcceptance' && Object.keys(this.mainTierRiskData).includes('riskOwner')) {
                this.thisScreen = 'Acceptance';

                let popupTierObj:any = {};
                if (Object.keys(this.mainTierRiskData).includes('systemId')) {
                    this.systemHead = true;
                    popupTierObj = { value: this.mainTierRiskData.systemId, label: this.mainTierRiskData.systemName };
                } else if (Object.keys(this.mainTierRiskData).includes('processId')) {
                    this.processHead = true;
                    popupTierObj = { value: this.mainTierRiskData.processId, label: this.mainTierRiskData.processName };
                } else if (Object.keys(this.mainTierRiskData).includes('orgId')) {
                    this.organizationHead = true;
                    popupTierObj = { value: this.mainTierRiskData.orgId, label: this.mainTierRiskData.organizationName };
                }

                if (this.mainTierRiskData.statusCode == 10403) {
                    toast.error(`Cannot Accept the Risk in this Status`, {
                        timeout: 5000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainTierRiskData).includes('tasks')) {
                        let conditionForAccept = this.mainTierRiskData.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForAccept) {
                            this.applyPopup = true;
                            this.mappedSystemsOptions.push(popupTierObj);
                            this.mainSystemBind.push(popupTierObj);
                            this.applyObj.systems = [...this.mainSystemBind];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        if (Object.keys(this.mainTierRiskData).includes('systemId')) {
                            this.systemHead = true;
                            let name = this.mainTierRiskData.systemName;
                            let systemTierObj = { value: this.mainTierRiskData.systemId, label: name };
                            this.mappedSystemsOptions.push(systemTierObj);
                            this.mainSystemBind.push(systemTierObj);

                        } else if (Object.keys(this.mainTierRiskData).includes('processId')) {
                            this.processHead = true;
                            let name = this.mainTierRiskData.processName;
                            let processTierObj = { value: this.mainTierRiskData.processId, label: name };
                            this.mappedSystemsOptions.push(processTierObj);
                            this.mainSystemBind.push(processTierObj);

                        } else if (Object.keys(this.mainTierRiskData).includes('orgId')) {
                            this.organizationHead = true;
                            let name = this.mainTierRiskData.organizationName;
                            let organizationTierObj = { value: this.mainTierRiskData.orgId, label: name };
                            this.mappedSystemsOptions.push(organizationTierObj);
                            this.mainSystemBind.push(organizationTierObj);
                        }
                        this.applyPopup = true;
                        this.applyObj.systems = [...this.mainSystemBind];
                    }
                }
            } else if (route == '/mitigateRisk' && Object.keys(this.mainTierRiskData).includes('riskOwner')) {
                if (this.mainTierRiskData.statusCode == 10402 || this.mainTierRiskData.statusCode == 10406) {
                    this.thisScreen = 'Mitigate';
                    let systemObj:any = {};
                    const mitigateSystems = [];
                    

                    if (Object.keys(this.mainTierRiskData).includes('systemId')) {
                        systemObj = { value: this.mainTierRiskData.systemId, label: this.mainTierRiskData.systemName }
                        mitigateSystems.push(systemObj.value);
                        localStorage.setItem(this.mainTierRiskData.mappedRisk, JSON.stringify(mitigateSystems));
                        this.$router.push({name:'systemsControlsMapWithId',params:{riskId: this.mainTierRiskData.mappedRisk, type:'systems', id:this.mainTierRiskData.systemId},query:{systems:true,Risks:true}});

                    }else if (Object.keys(this.mainTierRiskData).includes('processId')) {
                        systemObj = { value: this.mainTierRiskData.processId, label: this.mainTierRiskData.processName }
                        mitigateSystems.push(systemObj.value);
                        localStorage.setItem(this.mainTierRiskData.mappedRisk, JSON.stringify(mitigateSystems));
                        this.$router.push({name:'systemsControlsMapWithId',params:{riskId: this.mainTierRiskData.mappedRisk, type:'process', id:this.mainTierRiskData.processId},query:{process:true,Risks:true}});
                        
                    }else if (Object.keys(this.mainTierRiskData).includes('orgId')) {
                        systemObj = { value: this.mainTierRiskData.orgId, label: this.mainTierRiskData.organizationName }
                        mitigateSystems.push(systemObj.value);
                        localStorage.setItem(this.mainTierRiskData.mappedRisk, JSON.stringify(mitigateSystems));
                        this.$router.push({name:'systemsControlsMapWithId',params:{riskId: this.mainTierRiskData.mappedRisk, type:'organization', id:this.mainTierRiskData.orgId},query:{organization:true,Risks:true}});

                    }              
                    

                } else if (Object.keys(this.mainTierRiskData).includes('tasks')) {
                    let conditionForMitigate = this.mainTierRiskData.tasks.every((taskId: any) => {
                        let a = this.tasksData.find((task: any) => {
                            return task._id == taskId;
                        });
                        return a.statusCode == 10403 || a.statusCode == 10410;
                    });

                    if (this.mainTierRiskData.statusCode != 10402 && this.mainTierRiskData.statusCode != 10406 && conditionForMitigate) {
                        this.thisScreen = 'Mitigate';
                        const mitigateSystems:any = [];

                        if (this.mainTierRiskData.statusCode == 10405 || this.mainTierRiskData.statusCode == 10403) {
                            toast.error(`Cannot Mitigate the Risk in this Status`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        } else {

                            if (Object.keys(this.mainTierRiskData).includes('systemId')) {
                                this.systemHead = true;
                                let name = this.mainTierRiskData.systemName;
                                let systemTierObj = { value: this.mainTierRiskData.systemId, label: name };
                                mitigateSystems.push(systemTierObj.value);
                                localStorage.setItem(this.mainTierRiskData.mappedRisk, JSON.stringify(mitigateSystems));
                                this.$router.push({name:'systemsControlsMapWithId',params:{riskId: this.mainTierRiskData.mappedRisk, type:'system', id:this.mainTierRiskData.systemId},query:{systems:true,Risks:true}});

                            } else if (Object.keys(this.mainTierRiskData).includes('processId')) {
                                this.processHead = true;
                                let name = this.mainTierRiskData.processName;
                                let processTierObj = { value: this.mainTierRiskData.processId, label: name };
                                mitigateSystems.push(processTierObj.value);
                                localStorage.setItem(this.mainTierRiskData.mappedRisk, JSON.stringify(mitigateSystems));
                                this.$router.push({name:'systemsControlsMapWithId',params:{riskId: this.mainTierRiskData.mappedRisk, type:'process', id:this.mainTierRiskData.processId},query:{process:true,Risks:true}});

                            } else if (Object.keys(this.mainTierRiskData).includes('orgId')) {
                                this.organizationHead = true;
                                let name = this.mainTierRiskData.organizationName;
                                let organizationTierObj = { value: this.mainTierRiskData.orgId, label: name };
                                mitigateSystems.push(organizationTierObj.value);
                                localStorage.setItem(this.mainTierRiskData.mappedRisk, JSON.stringify(mitigateSystems));
                                this.$router.push({name:'systemsControlsMapWithId',params:{riskId: this.mainTierRiskData.mappedRisk, type:'org', id:this.mainTierRiskData.orgId},query:{organization:true,Risks:true}});

                            }
                            
                        }
                    } else {
                        toast.error(`Tasks In pending for this Risk`, {
                            timeout: 5000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                } else {
                    this.thisScreen = 'Mitigate';

                    const mitigateSystems = [];

                    if (Object.keys(this.mainTierRiskData).includes('systemId')) {
                        this.systemHead = true;
                        let name = this.mainTierRiskData.systemName;
                        let systemTierObj = { value: this.mainTierRiskData.systemId, label: name };
                        mitigateSystems.push(systemTierObj.value);
                        localStorage.setItem(this.mainTierRiskData.mappedRisk, JSON.stringify(mitigateSystems));
                        this.$router.push({name:'systemsControlsMapWithId',params:{riskId: this.mainTierRiskData.mappedRisk, type:'system', id:this.mainTierRiskData.systemId},query:{systems:true,Risks:true}});

                    } else if (Object.keys(this.mainTierRiskData).includes('processId')) {
                        this.processHead = true;
                        let name = this.mainTierRiskData.processName;
                        let processTierObj = { value: this.mainTierRiskData.processId, label: name };
                        mitigateSystems.push(processTierObj.value);
                        localStorage.setItem(this.mainTierRiskData.mappedRisk, JSON.stringify(mitigateSystems));
                        this.$router.push({name:'systemsControlsMapWithId',params:{riskId: this.mainTierRiskData.mappedRisk, type:'process', id:this.mainTierRiskData.processId},query:{process:true,Risks:true}});

                    } else if (Object.keys(this.mainTierRiskData).includes('orgId')) {
                        this.organizationHead = true;
                        let name = this.mainTierRiskData.organizationName;
                        let organizationTierObj = { value: this.mainTierRiskData.orgId, label: name };
                        mitigateSystems.push(organizationTierObj.value);
                        localStorage.setItem(this.mainTierRiskData.mappedRisk, JSON.stringify(mitigateSystems));
                        this.$router.push({name:'systemsControlsMapWithId',params:{riskId: this.mainTierRiskData.mappedRisk, type:'org', id:this.mainTierRiskData.orgId},query:{organization:true,Risks:true}});

                    }
                    
                }
            } else if (route == '/reset' && Object.keys(this.mainTierRiskData).includes('riskOwner')) {
                this.thisScreen = 'reset';
                if (this.mainTierRiskData.statusCode == 10401) {
                    toast.error(`Cannot Reset the Risk in this Status`, {
                        timeout: 5000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    let systemObj:any = {};
                    if (Object.keys(this.mainTierRiskData).includes('systemId')) {
                        this.systemHead = true;
                        systemObj = { value: this.mainTierRiskData.systemId, label: this.mainTierRiskData.systemName }
                    }else if (Object.keys(this.mainTierRiskData).includes('processId')) {
                        this.processHead = true
                        systemObj = { value: this.mainTierRiskData.processId, label: this.mainTierRiskData.processName }
                    }else if (Object.keys(this.mainTierRiskData).includes('orgId')) {
                        this.organizationHead = true
                        systemObj = { value: this.mainTierRiskData.orgId, label: this.mainTierRiskData.organizationName }
                    }

                    if (Object.keys(this.mainTierRiskData).includes('tasks')) {
                        let conditionForReset = this.mainTierRiskData.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForReset) {
                            this.applyPopup = true;
                            this.mappedSystemsOptions.push(systemObj);
                            this.mainSystemBind.push(systemObj);
                            this.applyObj.systems = [...this.mainSystemBind];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        this.applyPopup = true;
                        this.mappedSystemsOptions.push(systemObj);
                        this.mainSystemBind.push(systemObj);
                        this.applyObj.systems = [...this.mainSystemBind];
                    }
                }
            } else if (route == '/deactive') {
                this.thisScreen = 'deactive';
                let systemObj:any = {};
                if (Object.keys(this.mainTierRiskData).includes('systemId')) {
                    systemObj = { value: this.mainTierRiskData.systemId, label: this.mainTierRiskData.systemName }
                }else if (Object.keys(this.mainTierRiskData).includes('processId')) {
                    systemObj = { value: this.mainTierRiskData.processId, label: this.mainTierRiskData.processName }
                }else if (Object.keys(this.mainTierRiskData).includes('orgId')) {
                    systemObj = { value: this.mainTierRiskData.orgId, label: this.mainTierRiskData.organizationName }
                }
                if (Object.keys(this.mainTierRiskData).includes('tasks')) {
                    let conditionForDelete = this.mainTierRiskData.tasks.every((taskId: any) => {
                        let a = this.tasksData.find((task: any) => {
                            return task._id == taskId;
                        });
                        return a.statusCode == 10403 || a.statusCode == 10410;
                    });
                    if (conditionForDelete) {
                        this.applyPopup = true;
                        this.mappedSystemsOptions.push(systemObj);
                        this.mainSystemBind.push(systemObj);
                        this.applyObj.systems = [...this.mainSystemBind];
                    } else {
                        toast.error(`Tasks in Pending for this Risk`, {
                            timeout: 5000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                } else {
                    this.applyPopup = true;
                    this.mappedSystemsOptions.push(systemObj);
                    this.mainSystemBind.push(systemObj);
                    this.applyObj.systems = [...this.mainSystemBind];
                }
            }else if(route == '/clone'){
                let tierType:any
                if(risk.tier == 1){
                    tierType = 'organization'
                }else if(risk.tier == 2){
                    tierType = 'process'
                }else if(risk.tier == 3){
                    tierType = 'systems'
                }

                this.$router.push({ name:'cloner',params:{ mappedRisk: risk.mappedRisk, riskId:risk._id, type: tierType },query:{ tierRisks:true } })
            }
        },
        async getRootRiskList() {
            this.optionsRiskList = [];
            this.riskCategoryList = [];
            this.threatCategoryList = [];
            this.threatSourceList = [];

            const result = await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/getall`);
            this.optionsRiskList = result.data;
            this.optionsRiskList.forEach((risk: any) => {
                risk.entityId = 20008;
                !this.riskCategoryList.includes(risk.riskCategory) ? this.riskCategoryList.push(risk.riskCategory) : '';

                !this.threatCategoryList.includes(risk.threatCategory) ? this.threatCategoryList.push(risk.threatCategory) : '';

                !this.threatSourceList.includes(risk.threatSource) ? this.threatSourceList.push(risk.threatSource) : '';
            });
            
        },
        async getBusinessProcess() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                this.businessProcessList = result.data;
            });
        },
        async addOrUpdaterisk(riskData: any) {
            this.v$.riskThreatData.$touch();
            // this.updateArray.push(riskData);
            if (!this.v$.riskThreatData.$invalid && this.displayErrorMessageOfRisk == false && this.displayErrorMessageOfThreat == false && this.displayErrorMessageOfVulnerability == false) {
                this.clickOnButton = true
                let copyOfriskData:any = {...riskData}
                let oldObj: any = {};
                let newObj: any = {};
                Object.keys(riskData).map((key: any) => {
                    if (copyOfriskData[key] != this.riskObjCopy[key] && key != 'updatedValues') {
                        oldObj[key] = this.riskObjCopy[key];
                        newObj[key] = copyOfriskData[key];
                    }
                });
                copyOfriskData.updatedValues = { oldRisk: [], newRisk: [] };
                copyOfriskData.updatedValues.oldRisk.push(oldObj);
                copyOfriskData.updatedValues.newRisk.push(newObj);

                this.updateArray.push(copyOfriskData);
                console.log('this is updated risk object', this.risk);
                await this.updateRisk();
                this.openRiskPopup = false;
            } else {
                toast.error(`errorr`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            this.updateArray = [];
        },
        async updateRisk() {
            try {
                let riskId = this.updateArray[0]._id;
                delete this.updateArray[0]._id;
                delete this.updateArray[0].overallImpact;
                delete this.updateArray[0].severity;
                this.updateArray[0].updatedAt = new Date();
                this.updateArray[0].updatedBy = this.userInfo._id;
                this.updateArray[0].riskOwner = this.updateArray[0].riskOwner.value

                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${riskId}/update?org==true`, this.updateArray[0]).then((result: any) => {
                    toast.info(`Uploaded succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
                this.showPriorityPopUp = false;
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            await this.getAllSystemsRisks();
        },
        onCrossbtn() {
            this.v$.$reset();
            this.displayErrorMessageOfRisk = false
            this.displayErrorMessageOfThreat = false
            this.displayErrorMessageOfVulnerability = false
            this.openRiskPopup = false;
            // this.showThreatPopup = false;
            this.applyPopup = false;
            this.mappedSystemsOptions = [];
            this.mainSystemBind = [];
            Object.keys(this.applyObj).forEach((prop) => {
                Array.isArray(this.applyObj[prop]) ? (this.applyObj[prop] = []) : (this.applyObj[prop] = '');
            });
        },
        cancelAceeptResetBtn() {
            this.applyObj.comments = '';
        },
        editRiskThreatData(mappedriskId: any, riskId: any) {
            this.$router.push({ name: 'riskedit', params: { mappedriskId: mappedriskId, riskId: riskId } });
        },
        getDateFormat(date: any) {
            if (date == '') {
                return 'None';
            } else {
                let now = moment(date);
                return now.format('DD-MM-YYYY');
            }
        },
        showPopup(riskItem: any) {
            console.log('ssss', riskItem);
            this.presentSystemRiskId = riskItem._id;
            this.openRiskPopup = true;
            // this.showThreatPopup = true;
            
            this.riskThreatData = {...riskItem};
            this.riskObjCopy = { ...riskItem };
            this.mainSystemRisk = { ...riskItem };
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
                console.log('systems', this.systems);
                this.dummySystems = result.data;
            });
        },
        async getAllSystemsRisks() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/${this.$route.params.riskId}/systems`).then((result: any) => {
                
                let mainTier:any;


                console.log('RESULT DATAAAAAA',result.data)
                
                if(result.data.length > 0){
                    if(this.$route.path.includes('systemRisks')){
                        mainTier = 3
                    }else if(this.$route.path.includes('processRisks')){
                        mainTier= 2
                    }else if(this.$route.path.includes('orgRisks')){
                        mainTier= 1
                    }

                    let filter:any = result.data.filter((risk: any) => {
                        return risk.tier == mainTier;
                    });

                    this.riskAcceptanceObj = filter;
                }else{
                    this.riskAcceptanceObj = []
                }
                
                for (let i = 0; i < this.riskAcceptanceObj.length; i++) {
                    let systemsObj: any = false;
                    systemsObj = Object.keys(this.riskAcceptanceObj[i]).includes('systemId');

                    let processObj: any = false;
                    processObj = Object.keys(this.riskAcceptanceObj[i]).includes('processId');

                    let organizationObj: any = false;
                    organizationObj = Object.keys(this.riskAcceptanceObj[i]).includes('orgId');

                    if (systemsObj == true) {
                        this.riskHeaders = ['severity', 'systemName', 'createdAt', 'updatedAt', 'statusCode', 'priority','History', 'actionButton'];
                        this.riskAcceptanceObj[i].systemName = this.systems.find((obj: any) => this.riskAcceptanceObj[i].systemId == obj._id).systemName;
                        // this.systems.map((obj: any) => {
                        //     if (this.riskAcceptanceObj[i].systemId == obj._id) {
                        //         this.mappedSystems.push(obj);
                        //     }
                        // });
                        let myObj:any = this.systems.find((obj:any)=>{return this.riskAcceptanceObj[i].systemId == obj._id});
                        if(myObj != undefined){
                            this.mappedSystems.push(myObj);
                        }

                    } else if (processObj == true) {
                        this.riskHeaders = ['severity', 'processName', 'createdAt', 'updatedAt', 'statusCode',  'priority','History', 'actionButton'];
                        this.riskAcceptanceObj[i].processName = this.businessProcessList.find((obj: any) => this.riskAcceptanceObj[i].processId == obj._id).businessProcessName;
                        // this.businessProcessList.map((obj: any) => {
                        //     if (this.riskAcceptanceObj[i].processId == obj._id) {
                        //         this.mappedProcess.push(obj);
                        //     }
                        // });
                        let myObj:any = this.businessProcessList.find((obj:any)=>{return this.riskAcceptanceObj[i].processId == obj._id});
                        if(myObj != undefined){
                            this.mappedSystems.push(myObj);
                        }
                    } else if (organizationObj == true) {
                        this.riskHeaders = ['severity', 'organizationName', 'createdAt', 'updatedAt', 'statusCode',  'priority','History', 'actionButton'];
                        console.log('entitiesSelect', this.entitiesSelect);
                        this.riskAcceptanceObj[i].organizationName = this.entitiesSelect.find((obj: any) => this.riskAcceptanceObj[i].orgId == obj.value).label;

                        // this.entitiesSelect.map((obj: any) => {
                        //     if (this.riskAcceptanceObj[i].orgId == obj.value) {
                        //         this.mappedOrganization.push(obj);
                        //     }
                        // });
                        let myObj:any = this.entitiesSelect.find((obj:any)=>{return this.riskAcceptanceObj[i].orgId == obj._id});
                        if(myObj != undefined){
                            this.mappedSystems.push(myObj);
                        }
                    }

                    let openList:any = this.riskAcceptanceObj[i];

                    let condition1 = Object.keys(this.riskAcceptanceObj[i]).includes('residual') && this.riskAcceptanceObj[i]['residual'].length > 0;
                    let averageImpact;
                    let impact;
                    let severity;

                    if (condition1 == true) {
                        let lengthOfArray = this.riskAcceptanceObj[i]['residual'].length;
                        let indexObj = this.riskAcceptanceObj[i]['residual'][lengthOfArray - 1];

                        if (indexObj.isActive == true) {
                            averageImpact = this.average([indexObj['business'], indexObj['operations'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);

                            let arr = [ 'business', 'operations', 'legal', 'financial', 'likelihood'];
                            for (let key of arr) {
                                this.riskAcceptanceObj[i][key] = indexObj[key];
                            }
                        } else {
                            averageImpact = this.average([ openList['business'], openList['operations'], openList['legal'], openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * openList['likelihood']);
                        }
                    } else if (condition1 == false) {
                        averageImpact = this.average([ openList['business'], openList['operations'], openList['legal'], openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * openList['likelihood']);
                    };

                    this.riskAcceptanceObj[i].impact = impact;
                    this.riskAcceptanceObj[i].severity = severity;

                    if (!Object.keys(this.riskAcceptanceObj[i]).includes('updatedAt') && this.riskAcceptanceObj[i].updatedAt != '') {
                        this.riskAcceptanceObj[i].updatedAt = '';
                    }

                    let userobj = this.optionsUserArray.find((obj:any)=>{return obj.value == this.riskAcceptanceObj[i].riskOwner});

                    userobj != undefined ? this.riskAcceptanceObj[i].riskOwner = userobj : this.riskAcceptanceObj[i].riskOwner = ''

                }
                this.riskName = this.riskAcceptanceObj[0].risk;
                // this.$store.dispatch('updateDotsLoading', false);
            });
            this.getRootRiskList();
        },
        async getAllTasks() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.tasksData = result.data;
            });
        },
        async getBusinessEntities(){
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                this.entitiesSelect = result.data.map((org: any) => {
                    return { value: org._id, label: org.entityName };
                });
            })
        },
        async getallUsers() {
            console.log('qwerty');
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                console.log('this.orgUsersList', this.orgUsersList);
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                    console.log('this.optionsUserArray', this.optionsUserArray);
                }
            });
            console.log('this.orgUsersList', this.orgUsersList);
        },
        async createTaskForAction(applyObj: any, actionCode: any) {
            this.clickOnButton = true
            let payload: any = [];
            let obj:any = {
                    mappedRisk: this.mainTierRiskData.mappedRisk,
                    createdAt: new Date(),
                    createdBy: this.userInfo._id,
                    action: actionCode
                }
            if(this.$route.path.includes('systemRisks')){
                obj.systems = []
                applyObj.systems.map((item:any)=>{obj.systems.push(item.value)})
                obj.tier = 3
            }else if(this.$route.path.includes('processRisks')){
                obj.process = []
                applyObj.systems.map((item:any)=>{obj.process.push(item.value)})
                obj.tier = 2
            }else if(this.$route.path.includes('orgRisks')){
                obj.org = []
                applyObj.systems.map((item:any)=>{obj.org.push(item.value)})
                obj.tier = 1
            }

            payload.push(obj)
               
            payload[0].comments = applyObj.comments;

            //workflow task initialize
            await this.createWorkFlowTask(payload);

            Object.keys(this.applyObj).forEach((prop) => {
                Array.isArray(this.applyObj[prop]) ? (this.applyObj[prop] = []) : (this.applyObj[prop] = '');
            });

            await this.getAllTasks();
            await this.getAllSystemsRisks();

            this.mainSystemBind = [];
        },
        async createActionForRisk(payload: any) {
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/accept`, payload).then((result: any) => {
                    // this.systemAssesmentList = result.data;
                });
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async createWorkFlowTask(payload: any) {
            try {
                //    payload.createdAt=new Date(),
                //    payload.createdBy=this.userInfo._id,
                // ${process.env.VUE_APP_API_URL}
                await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/task`, payload).then((result: any) => {
                    this.insertedIds = result.data;
                    toast.info('Task Created', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                payload[0].insertedIds = this.insertedIds;
                await this.createActionForRisk(payload);
                this.applyPopup = false;
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.riskAcceptanceObj;
            } else {
                this.riskAcceptanceObj = this.dummyList;
                for (const key in this.filterDummy) {
                    this.filterDummy[key] = '';
                }
            }
            emitter.emit('visible', this.showFilters);
        },
        average(arr: any[]) {
            return arr.reduce((p, c) => p + c, 0) / arr.length;
        },
        showPriorityPopUpAction(data: any) {
            this.mainTierRiskData = {...data};
            this.riskObjCopy = {...data}

            this.showPriorityPopUp = true;

            this.riskPriorityShow.risk=data.risk;
            this.riskPriorityShow.priority=data.priority;
            this.riskPriorityShow.justification = '';

            this.priorityDummy.risk=data.risk;
            this.priorityDummy.priority=data.priority;
            this.priorityDummy.justification = ''
        },

        closePriorityPopup() {
            this.resetRiskPriority();
            this.showPriorityPopUp = false;
        },

        cancelPriorityBtn() {
            this.riskPriorityShow.risk=this.priorityDummy.risk;
            this.riskPriorityShow.priority=this.priorityDummy.priority;
            this.riskPriorityShow.justification='';
            this.priorityDummy.justification='';
            this.v$.$reset();
        },

        async addOrUpdatePriority() {
            console.log("add clicked");
            this.v$.$touch();
            if (!this.v$.riskPriorityShow.$invalid) {
                this.clickOnButton = true
                
                this.mainTierRiskData.priority = this.riskPriorityShow.priority;
                this.mainTierRiskData.priorityComments = this.riskPriorityShow.justification;
                
                let riskData = {...this.mainTierRiskData}

                let oldObj: any = {};
                let newObj: any = {};
                Object.keys(riskData).map((key: any) => {
                    if (riskData[key] != this.riskObjCopy[key] && key != 'updatedValues' && key !='priorityComments') {
                        oldObj[key] = this.riskObjCopy[key];
                        newObj[key] = riskData[key];
                    }
                });
                riskData.updatedValues = { oldRisk: [], newRisk: [] };
                riskData.updatedValues.oldRisk.push(oldObj);
                riskData.updatedValues.newRisk.push(newObj);

                this.updateArray = [];

                this.updateArray.push(riskData);
                //updatingRisk
                await this.updateRisk();

                this.v$.$reset();
            }else {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }

        },

        resetRiskPriority() {
            this.riskPriorityShow.risk = '';
            this.riskPriorityShow.priority = null;
            this.riskPriorityShow.justification='';
            // this.riskPriorityShow._id=null;
            this.priorityDummy.risk = '';
            this.priorityDummy.priority = null;
            this.priorityDummy.justification='';
            // this.priorityDummy._id=null;
            this.v$.$reset();
        },
    },
  
    async created(){
        this.riskHeaders = ['severity', '', 'createdAt', 'updatedAt', 'statusCode', 'priority','History', 'actionButton'];

        this.dataLoading = true;

        await this.getallUsers();
        await this.getSystems();

        await this.getBusinessProcess();
        await this.getBusinessEntities();
        
        await this.getAllTasks();
        await this.getAllSystemsRisks();

        this.dataLoading = false;
    },
    async mounted() {
        //this.$store.dispatch('updateDotsLoading', true);
        let id = this.$route.params.id;
        this.routesArray = [
            { name: 'Risks', routeName: 'risks' },
            { name: 'Tier-Risks', routeName: 'tierRisks', id: `${id}` }
        ];
        
        emitter.on('*', (type, booleanValue) => {
            if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
                this.showBlock == true ? (this.showBlock = false) : '';
            } else {
                if (this.showDropdown === true) {
                    this.showBlock == true ? (this.showBlock = booleanValue) : '';
                } else if (this.showDropdown === false) {
                    this.showBlock == true ? '' : (this.showBlock = booleanValue);
                    this.showDropdown = true;
                }
            }
        });

        emitter.off('showData123');
        emitter.on('showData123', (isData: any) => {
            let comingId = isData.entityData._id;
            let showObj = this.riskAcceptanceObj.find((obj: any) => {
                return obj._id === comingId;
            });
            this.showPopup(showObj);
        });

        emitter.off('showPriorityPopup');
        emitter.on('showPriorityPopup', (isData: any) => {
            this.showPriorityPopUpAction(isData);
        } );

        emitter.off('changeRouteInTierRisks');
        emitter.on('changeRouteInTierRisks', (selectedActionObj: any) => {
            let risk = selectedActionObj.data;
            let route = selectedActionObj.route;
            this.changeRoute(risk, route);
        });

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    }
});
</script>

