

<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Revised Risk</h1>
        </div>
        <div class="p-3 bg-white rounded mt-3">
            <div>
                <div class="statuscontainer p-2">
                    <div class="formcontainer flex flex-col">
                        <div class="impactLevel">
                            <h1 class="ml-2">Revised Impact</h1>
                            <div class="border-b border-solid mt-3 border-lightgrey"></div>
                            <div class="row flex flex-row w-full">
                                <div class="col-md-6 w-6/12 p-2">
                                    <label class="controllabel">Startegic</label>
                                    <select
                                        v-on:change="getMaxImpactValue()"
                                        class="inputboxstyling"
                                        v-model="riskObj.business"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option v-for="obj in dbBussiness" :key="obj" :value="obj.value">{{obj.key}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 w-6/12 p-2">
                                    <label class="controllabel">Operation</label>
                                    <select
                                        v-on:change="getMaxImpactValue()"
                                        class="inputboxstyling"
                                        v-model="riskObj.operations"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option v-for="obj in dbOperations" :key="obj" :value="obj.value">{{obj.key}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row flex flex-row w-full">
                                <div class="col-md-6 w-6/12 p-2">
                                    <label class="controllabel">Compliance</label>
                                    <select
                                        v-on:change="getMaxImpactValue()"
                                        class="inputboxstyling"
                                        v-model="riskObj.legal"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option v-for="obj in dbLegal" :key="obj" :value="obj.value">{{obj.key}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 w-6/12 p-2">
                                    <label class="controllabel">Financial</label>
                                    <select
                                        v-on:change="getMaxImpactValue()"
                                        class="inputboxstyling"
                                        v-model="riskObj.financial"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option v-for="obj in dbFinancial" :key="obj" :value="obj.value">{{obj.key}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row flex flex-row w-full">
                                <div class="col-md-6 w-6/12 p-2">
                                    <label class="controllabel">Overall Impact</label>
                                    <select
                                        class="disabledinputboxstyling"
                                        disabled
                                        v-model="mainImpact"
                                        v-on:input="getMaxResidualRiskValue()"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option v-for="obj in dbSeverity" :key="obj" :value="obj.value">{{obj.key}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="likelihoodlevel mt-3">
                            <h1 class="ml-2">Revised Likelihood</h1>
                            <div class="border-b border-solid mt-3 border-lightgrey"></div>
                            <div class="row flex flex-row w-full">
                                <div class="col-md-6 w-6/12 p-2">
                                    <label class="controllabel">Revised Likelihood</label>
                                    <select
                                        v-on:change="getMaxResidualRiskValue()"
                                        class="inputboxstyling"
                                        v-model="riskObj.likelihood"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option v-for="obj in dbLikelihood" :key="obj" :value="obj.value">{{obj.key}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="residualrisklevel mt-3">
                            <h1 class="ml-2">Residual Risk</h1>
                            <div class="border-b border-solid mt-3 border-lightgrey"></div>
                            <div class="row flex flex-row w-full">
                                <div class="col-md-6 w-6/12 p-2">
                                    <label class="controllabel">Security Level</label>
                                    <select
                                        class="disabledinputboxstyling"
                                        disabled
                                        v-model="mainResiudalRisk"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option v-for="obj in dbSeverity" :key="obj" :value="obj.value">{{obj.key}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttonposition flex justify-center items-center mt-2 mb-4 mx-0">
                <button class="btnprimary mr-1.5" @click="cancelRisk()" :class="disableStatus?'btndisabled pointer-events-none':'btnprimary'">Cancel</button>
                <button class="btnprimary" @click="mitigateRisk()" :class="disableStatus?'btndisabled pointer-events-none':'btnprimary'">Mitigate</button>
            </div>
        </div>
            
    </div>
    
</template>
<style scoped>

</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { required,helpers,requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { toast } from '../../main';
import breadCrumb from '@/components/breadCrumb.vue';
import { mapGetters } from 'vuex';
export default defineComponent({
    data(): any {
        return {
            // v$: useVuelidate(),
            systems:[],
            incomingRisksArray:[],
            optionsUserArray:[],
            mainImpact:'',
            mainLikelihood:'',
            mainResiudalRisk:'',
            insertedIds:[],
            riskObj:{
                legal:'',
                operations:'',
                financial:'',
                business:'',
                likelihood:'',
            },
            mainRisk:{},
            mitigatePayloadForRisk:'',
            linkedSystemsArray:[],
            dummy:{},
            routesArray:[],
        };
    },
    components: {
        breadCrumb
    },
    async mounted() {
        await this.getSystems();

        let data:any = localStorage.getItem(this.$route.params.riskId);
        this.linkedSystemsArray = JSON.parse(data);
        await this.getAllSystemsRisks();

        this.getMaxImpactValue();
        this.getMaxResidualRiskValue();

        const Mitigate:any = localStorage.getItem('MITIGATE_DATA');
        this.mitigatePayloadForRisk = JSON.parse(Mitigate);

        if(Object.keys(this.$route.query).includes('risks')){
            this.routesArray = [{name:'Risks',routeName:'risks'},{ name:'Tier-Risks',routeName:'systemRisk', id:this.$route.params.riskId},{name:'Risk-Mitigate',routeName:'riskMitigate'}];

            // this.routesArray = [{name:'Risks',routeName:'risks'},
            // {name:'System-Risk',routeName:'systemRisk',id:this.$route.params.riskId},
            // // {name:'Risk-Mitigate',routeName:'systemsControlsMapWithId',riskId:this.$route.params.riskId,systemRiskId:this.$route.params.systemRiskId},
            // {name:'Residual-Risk',routeName:'residualRisk'}];
        }else if(Object.keys(this.$route.query).includes('systems')){
            this.routesArray = [
                { name: 'Systems', routeName: 'systems' },
                { name: 'System-Information', routeName: 'systemInformation', id: this.$route.params.id},
                // {name:'Risk-Mitigate',routeName:'systemsControlsMapWithId',riskId:this.$route.params.riskId,systemId:this.$route.params.systemId},
                {name:'Residual-Risk',routeName:'residualRisk'}
            ];
        }else if(Object.keys(this.$route.query).includes('process')){
            this.routesArray = [
                { name: 'Business Process', routeName: 'businessProcess' },
                { name: 'Process-Information', routeName: 'processInformation', id: `${ this.$route.params.id}` },
                {name:'Risk-Mitigate',routeName:'riskMitigate'}
            ];
        }else if(Object.keys(this.$route.query).includes('organization')){
            this.routesArray = [
                { name: 'Organization', routeName: 'organization' },
                { name: 'Organization-Information', routeName: 'worklogFromOrganization', id: `${ this.$route.params.id }` },
                {name:'Risk-Mitigate',routeName:'riskMitigate'}
            ];
        }
    },
  
    computed: {
        ...mapGetters({ userInfo: 'userInfo',dbSeverity:'dbSeverity',dbLikelihood:'dbLikelihood',dbBussiness:'dbBussiness',
            dbOrganization:'dbOrganization',dbOperations:'dbOperations',dbLegal:'dbLegal',
            dbFinancial:'dbFinancial',dbTechnical:'dbTechnical',dbProcessCriticality:'dbProcessCriticality',
            dbAvailability:'dbAvailability',dbConfidentiality:'dbConfidentiality',dbIntegrity:'dbIntegrity',
            dbInformationClassification:'dbInformationClassification',dbSecurityCategory:'dbSecurityCategory',dbPriority:'dbPriority' }),

        disableStatus: function (): any {
            let disableStatusCondition;
            if (JSON.stringify(this.riskObj) == JSON.stringify(this.dummy)) {
                disableStatusCondition = true;
            } else {
                disableStatusCondition = false;
                }
            return disableStatusCondition;
        },
    },
    methods: {
        getMaxImpactValue(){
            let averageImpact = Math.round(this.average([this.riskObj.business, this.riskObj.operations, this.riskObj.legal, this.riskObj.financial]));
            
            this.mainImpact = averageImpact;
            this.getMaxResidualRiskValue();
        },
        average(arr: any) {
            return arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
        },
        getMaxResidualRiskValue(){
            if(this.mainImpact != ''){
                this.mainResiudalRisk = this.riskResidual(this.riskObj.likelihood * this.mainImpact);
            }         
        },
        riskResidual(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        cancelRisk(){
            this.riskObj = {...this.dummy};
            this.getMaxImpactValue();
            
        },
        async mitigateRisk(){                     
            // this.linkedSystemsArray.map((system:any)=>{
            //     let present = this.incomingRisksArray.find((obj:any)=>{return obj.systemId == system});
            //     Object.keys(this.riskObj).map((key:any)=>{
            //         present[key] = this.riskObj[key]
            //     })
            //     this.updateRisk(present);
            // })  
            await this.createWorkFlowTask(this.mitigatePayloadForRisk); 
        },
        async getSystems() {
             let entityId='20005'
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
                console.log("systems",this.systems)
            });
       
        },

        //change
        async getAllSystemsRisks(){
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/${this.$route.params.riskId}/systems`).then((result: any) => {
                this.incomingRisksArray = result.data;
                if(Object.keys(this.$route.query).includes('systems')){
                    this.mainRisk = this.incomingRisksArray.find((obj:any)=>{return obj.systemId == this.$route.params.id});
                }else if(Object.keys(this.$route.query).includes('process')){
                    this.mainRisk = this.incomingRisksArray.find((obj:any)=>{return obj.processId == this.$route.params.id});
                }else if(Object.keys(this.$route.query).includes('organization')){
                    this.mainRisk = this.incomingRisksArray.find((obj:any)=>{return obj.orgId == this.$route.params.id});
                }
                
                Object.keys(this.riskObj).map((key:any)=>{
                    this.riskObj[key] = this.mainRisk[key]
                });
                this.dummy = {...this.riskObj}
            })
        },

        async updateRisk(object:any){
            try {
                let riskId = object._id;
                delete object._id;
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${riskId}/update?org==true`, object).then((result: any) => {
                    
                    console.log('updatedoneeeeeeeeeee')
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            await this.getAllSystemsRisks();
        },
        async createTask(payload:any) {
            if(JSON.stringify(this.riskObj) != JSON.stringify(this.dummy)){
                payload[0].residual = [this.riskObj];
                payload[0].residual[0].isActive = false;
                payload[0].updatedBy = this.userInfo._id;
                payload[0].updatedAt = new Date();
            }else{
                payload[0].residual = [];
            }
            
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/mitigate`, payload).then((result: any) => {
                    toast.info('Mitigated', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                    localStorage.removeItem('MITIGATE_DATA');
                    localStorage.removeItem(this.$route.params.riskId);
                    
                    if(Object.keys(this.$route.query).includes('risks')){
                        let name:any
                        if(Object.keys(this.$route.query).includes('systems')){
                            name ='systemRisks'
                        } else if(Object.keys(this.$route.query).includes('process')){
                            name ='processRisks'
                        } else if(Object.keys(this.$route.query).includes('organization')){
                            name ='orgRisks'
                        } 
                        this.$router.push({ name: 'tierRisks', params: { riskId: this.$route.params.riskId, type:name } });

                    }else if(Object.keys(this.$route.query).includes('systems')){
                        this.$router.push({ name: 'systems' });

                    } else if(Object.keys(this.$route.query).includes('process')){
                        this.$router.push({ name: 'processInformation', params: { processId: payload[0].mappedControls[0].process[0] } });

                    } else if(Object.keys(this.$route.query).includes('organization')){
                        this.$router.push({name: 'organizationInformation', params: { orgId: payload[0].mappedControls[0].org[0] }});

                    } 
                });
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
         async createWorkFlowTask(){
            try {
                await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/task`, this.mitigatePayloadForRisk).then((result: any) => {
                    this.insertedIds = result.data
                    
                });
                this.mitigatePayloadForRisk[0].insertedIds = this.insertedIds;
                await this.createTask(this.mitigatePayloadForRisk)
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
    }
});
</script>



