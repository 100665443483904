<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="flex justify-between items-center h-8">
            <div>
                <h1>System Information - {{ system.systemName }}</h1>
            </div>
            <div class="flex items-center">
                <button class="mr-3" v-if="selectedIndex == 3 && systemAssesmentList.length != 0 && userInfo.currentRole == 10207" @click="createAssesmentTask()" :class="checkedItems.length > 0 ? 'btnprimary mr-3' : 'btndisabled pointer-events-none mr-3'">Create</button>
                <button class="btnprimary mr-3 flex items-center" @click="addRiskForSystem" v-if="selectedIndex == 0">Add</button>
                <button class="btnprimary mr-3 flex items-center" @click="showInformation" v-if="selectedIndex == 1">Add</button>
                <button
                    v-if="selectedIndex != 2"
                    :class="(selectedIndex == 0 && riskList.length != 0) || (selectedIndex == 3 && systemAssesmentList.length != 0) || (selectedIndex == 1 && infoTableRow.length != 0) ? 'filterButton' : 'btndisabled pointer-events-none'"
                    class="flex"
                    @click="filterOpen"
                >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        
        <div class="mt-5 px-5 pb-5 bg-white w-full border-lightgrey pt-5 relative rounded">
            <div class="group-buttons" v-if="isSmallScreen">
                <!-- Group navigation buttons for smaller screens -->
                <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                    <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                </div>
                <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedIndex === carouselHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                    <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                </div>
            </div>
          
            <div>
                <div>
                    <div class="flex flex-row borderBottom cursor-pointer tab-container" ref="tabContainer" @scroll="handleScroll">
                        <div class="pb-1" :class="selectedIndex == tab.id ? 'adjust header font-semibold primary':'text-sm text-disabledgrey'" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab)">
                            <div>
                                <div ref="actualIndex" class="cursor-pointer">{{ tab.displayName }}</div>
                                <div :class="{ progress: selectedIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
 
                <div class="progressbarB rounded h-1"></div>
            </div>
            <div class="w-full">
                <div v-if="selectedIndex == 0">
                    <newTable :tableRowData="riskList" :headersData="riskHeaders" :openPopup="true" :loadData="dataLoading"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
                <div class="businesssPopup" v-if="selectedIndex == 1">
                    <newTable :tableRowData="infoTableRow" :headersData="informationHeaders" :openPopup="true" :loadData="dataLoading"></newTable>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
                <div v-if="selectedIndex == 2">
                    <fieldset :disabled="true">
                        <!-- <div class="card"> -->
                        <div class="statuscontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">System Name</label>
                                        <div class="text-sm">{{system.systemName}}</div>
                                        
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Owned Entity</label>
                                        <div class="text-sm">
                                            {{system.ownedBy && Object.keys(system.ownedBy).includes('label') ? `${system.ownedBy.label}`:'N/A'}}
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Description</label>
                                        <div class="text-sm">{{system.description}}</div>
                                        
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Managed By</label>
                                         <div class="text-sm">
                                            {{system.managedBy && Object.keys(system.managedBy).includes('label') ? `${system.managedBy.label}`:'N/A'}}
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row grid grid-cols-2">
                                    <div class="w-full upperspacing">
                                        <label class="controllabel">Public Facing?</label>
                                        <div class="text-sm">  {{ yesNo.find(option => option.id === system.publicFacing)?.value || "N/A" }}</div>
                                        
                                    </div>
                                    <div class="w-full upperspacing leftspacing">
                                        <label class="controllabel">Owner</label>
                                        <div class="text-sm">
                                            {{system.owner && Object.keys(system.owner).includes('label') ? `${system.owner.label}`:'N/A'}}
                                            
                                        </div>
                                        
                                    </div>
                                    <div class="w-full upperspacing" v-if="system.publicFacing == '1'">
                                        <label class="controllabel">Add <span class="text-xs">(URL / IP Address / Domain Name)</span></label>
                                        <div class="text-sm">{{system.add}}</div>
                                        
                                    </div>
                                    <div class="w-full upperspacing">
                                        <label class="controllabel">Hosting type</label>
                                        <div class="text-sm">
                                            {{ system.hostingType }}
                                        </div>
                                        
                                    </div>
                                    <div class="w-full upperspacing leftspacing">
                                        <label class="controllabel">Provider</label>
                                        <div class="text-sm">{{system.hostingProvider}}</div>
                                        
                                    </div>
                                    <div class="w-full upperspacing">
                                        <label class="controllabel">Country</label>
                                        <div class="text-sm">{{system.country}}</div>
                                        
                                    </div>
                                    <div class="w-full upperspacing leftspacing">
                                        <label class="controllabel">City</label>
                                        <div class="text-sm">{{system.city}}</div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div v-if="selectedIndex == 3">
                    <div v-if="discovery == true">
                        <div class="flex-col justify-center items-center">
                            <div class="loader mr-2"></div>
                        </div>
                        <p class="text-center font-semibold">Discovering Risks</p>
                    </div>
                    <div class="tableCornerRadius" v-if="discovery == false">
                        <div class="table-container">
                        <table>
                            <thead>
                                <th class="header cursor-pointer w-1/12">
                                    <div class="flex items-center">
                                        <input type="checkbox" class="checkbox cursor-pointer ml-4" v-model="selectAll" :disabled="sortedData.length == 0" @change="selectAllOptions($event)" />
                                    </div>
                                </th>
                                <th class="header cursor-pointer w-1/5" @click="sort('severity')">
                                    <div class="flex items-center">
                                        <div>Severity</div>

                                        <div class="sort cursor-pointer" :class="className == 'severity' ? classSort : 'opacity-50'"></div>
                                    </div>
                                </th>
                                <th class="header cursor-pointer w-1/4" @click="sort('risk')">
                                    <div class="flex items-center">
                                        <div>Risk</div>

                                        <div class="sort cursor-pointer" :class="className == 'risk' ? classSort : 'opacity-50'"></div>
                                    </div>
                                </th>
                                <th class="header cursor-pointer w-28" @click="sort('riskCategory')">
                                    <div class="flex items-center">
                                        <div>Category</div>

                                        <div class="sort cursor-pointer" :class="className == 'riskCategory' ? classSort : 'opacity-50'"></div>
                                    </div>
                                </th>
                                <!-- <th class="header cursor-pointer" @click="sort('overallImpact')">
                                        <div class="flex items-center ml-4">
                                            <div>Impact</div>

                                            <div class="sort cursor-pointer" :class="className == 'overallImpact' ? classSort : 'opacity-50'"></div>
                                        </div>
                                    </th> -->
                                <th class="header cursor-pointer w-1/5" @click="sort('likelihood')">
                                    <div class="flex items-center ml-3">
                                        <div>Likelihood</div>

                                        <div class="sort cursor-pointer" :class="className == 'likelihood' ? classSort : 'opacity-50'"></div>
                                    </div>
                                </th>

                                <tr v-if="showFilters">
                                    <td></td>
                                    <td class="w-36">
                                        <select class="widthForSelect" :class="filterDummy.severity == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="filterDummy.severity">
                                            <option value="" selected disabled hidden>Select</option>
                                            <option v-for="pair in dbSeverity" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                        </select>
                                    </td>
                                    <td class="searchRow">
                                        <input type="text" class="inputboxStylingForFilter filter-font-size" placeholder="Search" v-model="filterDummy.risk" />
                                    </td>
                                    <td class="searchRow">
                                        <input type="text" class="searchForInputBoxStyling filter-font-size" placeholder="Search" v-model="filterDummy.riskCategory" />
                                    </td>
                                    <!-- <td>
                                            <select class="inputboxstyling resize" v-model="filterDummy.overallImpact">
                                                <option value="" selected disabled hidden>Select</option>
                                                <option v-for="pair in risksSeverityValue" :key="pair" :value="pair.id">{{ pair.value }}</option>
                                            </select>
                                        </td> -->
                                    <td class="searchRow">
                                        <select class="widthForSelect" :class="filterDummy.likelihood == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="filterDummy.likelihood">
                                            <option value="" selected disabled hidden>Select</option>
                                            <option v-for="pair in dbLikelihood" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                        </select>
                                    </td>
                                </tr>
                            </thead>
                            <tbody v-if="sortedData.length == 0">
                                <tr class="cursor-pointer rowHeight">
                                    <td :colspan="6">
                                        <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="nonEmptyTable" v-else>
                                <tr v-for="(riskItem, index) in sortedData" class="rowHeight" :key="index">
                                    <td @mouseout="doFalse()">
                                        <input class="checkbox ml-4" type="checkbox" v-model="checkedItems" :value="riskItem._id" @change="changeInfo(riskItem, $event)" />
                                    </td>
                                    <td class="relative cursor-pointer" @mouseout="doFalse()">
                                        <div class="relative w-36 regular pl-2">
                                            <div class="flex items-center justify-center rounded-full ml-2 w-5 h-5" @mousemove="toolTip(riskItem, 'severity', riskItem.severity, index, $event)" :class="colorPropBg('border', riskItem.severity)">
                                                <div class="severityCircle rounded-full" :class="colorPropBg('circle', riskItem.severity)"></div>
                                            </div>
                                        </div>
                                    </td>

                                    <td class="relative" @mouseout="doFalse()">
                                        <div class="table-ellipsis">
                                            <p class="cursor-pointer text-xs" @mousemove="toolTip(riskItem, 'risk', riskItem.risk, index, $event)">{{ riskItem.risk }}</p>
                                        </div>
                                    </td>
                                    <td class="relative" @mouseout="doFalse()">
                                        <div class="table-ellipsis">
                                            <p class="cursor-pointer text-xs" @mousemove="toolTip(riskItem, 'riskCategory', riskItem.riskCategory, index, $event)">{{ riskItem.riskCategory }}</p>
                                        </div>
                                    </td>
                                    
                                    <td class="font-normal text-sm whitespace-nowrap group cursor-pointer relative">
                                        <div class="flex items-center relative rounded regular text-xs w-24 h-5" :class="colorRiskText(riskItem.likelihood)">
                                            <div class="w-full h-full absolute rounded" :class="colorRiskBg(riskItem.likelihood)"></div>
                                            <div class="flex rounded w-24 items-center regular text-xs tip">
                                                <div v-if="!isNaN(riskItem.likelihood)">
                                                    <div class="flex w-24 items-center justify-center regular pb-px">
                                                        {{ getRiskStateValue(riskItem.likelihood) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        <div v-if="sortedData.length > 0">
                            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
                        </div>
                    </div>
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>
            </div>
        </div>
        
        <popup v-if="openRiskPopup" :popUp="openRiskPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Risk Information</h1>
                    <button class="float-right text-3xl" @click="onCrossbtn()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <div class="popup-business">
                <div>
                    <div>
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full">
                                        <div class="upperspacing w-full">
                                            <label for="Risk" class="controllabel">Risk</label>
                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Risk"
                                                v-model.trim="riskThreatData.risk"
                                                @input ="restrictCharacters('Risk')"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.risk.$error||displayErrorMessageOfRisk
                                                }"
                                            />
                                            <div v-if="v$.riskThreatData.risk.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.risk.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfRisk == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <div>
                                                <label class="controllabel">Risk Category</label>
                                                <Multiselect
                                                    v-model="riskThreatData.riskCategory"
                                                    :options="riskCategoryList"
                                                    :multiple="false"
                                                    :searchable="true"
                                                    :hide-selected="true"
                                                    open-direction="bottom"
                                                    :close-on-select="true"
                                                    :show-labels="false"
                                                    class="custom-multiselect"
                                                    placeholder="Risk Category"
                                                    :class="{
                                                        'is-invalid borderRed': v$.riskThreatData.riskCategory.$error,
                                                        'has-options': riskCategoryList && riskCategoryList.length > 0,
                                                    'no-options':  !riskCategoryList.length,
                                                    }"
                                                >
                                                    <template v-slot:noResult>
															<span class="text-xs">No Categories Found</span>
														</template>
                                                </Multiselect>
                                                <div v-if="v$.riskThreatData.riskCategory.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.riskCategory.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-3/6 leftspacing">
                                            <label class="controllabel">Risk Owner</label>
                                            <multiselect
                                                v-model="riskThreatData.riskOwner"
                                                :options="userOptions"
                                                :showNoOptions="false"
                                                @search-change="asyncFind"
                                                :limit-text="limitText"
                                                :multiple="false"
                                                :hide-selected="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Risk Owner"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid borderRed': v$.riskThreatData.riskOwner.$error,
                                                    'has-options': userOptions && userOptions.length > 0,
                                                    'no-options': !userOptions.length && searchValue !== '',
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Members Found</span>
                                                </template>
                                            </multiselect>
                                            <div v-if="v$.riskThreatData.riskOwner.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.riskOwner.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Threat</label>
                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Threat"
                                                @input ="restrictCharacters('Threat')"
                                                v-model.trim="riskThreatData.threatEvent"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.threatEvent.$error||displayErrorMessageOfThreat
                                                }"
                                            />

                                            <div v-if="v$.riskThreatData.threatEvent.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.threatEvent.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfThreat == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Threat Source</label>
                                            <Multiselect
                                                v-model="riskThreatData.threatSource"
                                                :options="threatSourceList"
                                                :multiple="false"
                                                :searchable="true"
                                                :hide-selected="true"
                                                open-direction="bottom"
                                                :close-on-select="true"
                                                :show-labels="false"
                                                class="custom-multiselect"
                                                placeholder="Threat Source"
                                                :class="{
                                                    'has-options': threatSourceList && threatSourceList.length > 0,
                                                    'no-options':  !threatSourceList.length,
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Source Found</span>
                                                </template>
                                            </Multiselect>
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Threat Category</label>
                                            <Multiselect
                                                v-model="riskThreatData.threatCategory"
                                                :options="threatCategoryList"
                                                :multiple="false"
                                                :searchable="true"
                                                :hide-selected="true"
                                                open-direction="bottom"
                                                :close-on-select="true"
                                                :show-labels="false"
                                                class="custom-multiselect"
                                                placeholder="Threat Category"
                                                :class="{
                                                    'has-options': threatCategoryList && threatCategoryList.length > 0,
                                                    'no-options':  !threatCategoryList.length,
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Categories Found</span>
                                                </template>
                                            </Multiselect>
                                        </div>
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Vulnerability</label>
                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Vulnerability"
                                                @input ="restrictCharacters('Vulnerability')"
                                                v-model.trim="riskThreatData.vulnerability"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.vulnerability.$error||displayErrorMessageOfVulnerability
                                                }"
                                            />

                                            <div v-if="v$.riskThreatData.vulnerability.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.vulnerability.required.$invalid">Required</div>
                                            </div>
                                            <div v-else-if="displayErrorMessageOfVulnerability == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 class="upperspacing">Impact Analysis</h1>
                                    <div class="border-b pt-1 border-solid border-lightgrey"></div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Strategic</label>
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.business"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.business.$error,'inputboxstylingForSelectAtPopup': riskThreatData.business === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.business !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="i in dbBussiness" :value="i.value" :key="i">
                                                    {{ i.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.business.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.business.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Financial</label> 

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.financial"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.financial.$error,'inputboxstylingForSelectAtPopup': riskThreatData.financial === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.financial !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="i in dbFinancial" :value="i.value" :key="i">
                                                    {{ i.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.financial.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.financial.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Operations</label>
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.operations"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.operations.$error,'inputboxstylingForSelectAtPopup': riskThreatData.operations === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.operations !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="i in dbOperations" :value="i.value" :key="i">
                                                    {{ i.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.operations.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.operations.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Compliance</label>
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.legal"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.legal.$error,'inputboxstylingForSelectAtPopup': riskThreatData.legal === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.legal !== ''
                                                }"
                                                @change="calculateOverallImpact()"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="i in dbLegal" :value="i.value" :key="i">
                                                    {{ i.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.legal.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.legal.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <h1 class="upperspacing">Risk Analysis</h1>
                                    <div class="border-b pt-1 border-solid border-lightgrey"></div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-6/12">
                                            <label class="controllabel">Likelihood</label> 

                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="riskThreatData.likelihood"
                                                :class="{
                                                    'is-invalid': v$.riskThreatData.likelihood.$error,'inputboxstylingForSelectAtPopup': riskThreatData.likelihood === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.likelihood !== ''
                                                }"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="i in dbLikelihood" :value="i.value" :key="i">
                                                    {{ i.key }}
                                                </option>
                                            </select>
                                            <div v-if="v$.riskThreatData.likelihood.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.riskThreatData.likelihood.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                        <div class="w-6/12 leftspacing">
                                            <label class="controllabel">Overall Impact</label>
                                            <select class="inputboxstyling" disabled v-model="riskThreatData.overallImpact">
                                                <option value="" selected hidden>Please select one</option>
                                                <option v-for="i in dbOverAllImpact" :value="i.value" :key="i">
                                                    {{ i.key }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="flex mt-2 justify-center">
                                        <button :disabled="clickOnButton" class="btnprimary mr-2 gap-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelRiskData()">Cancel</button>
                                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-if="addingRisk" @click="addOrUpdaterisk(riskThreatData)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Add</button>
                                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-else @click="addOrUpdaterisk(riskThreatData)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>

        <popup v-if="showInformationPopup" :popUp="showInformationPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Information</h1>
                    <button class="float-right text-3xl" @click="onCloseInformation()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full" v-if="information.information != '' && !addInfo">
                                    <label class="controllabel">Information Category</label>
                                    <div class="text-sm">
                                        {{information.informationCategory}}
                                    </div>
                                    </div>
                                    <div class="w-full" v-else>
                                        <label class="controllabel">Information Category</label>
                                        <Multiselect
                                            v-model="information.informationCategory"
                                            :options="infoCategoryList"
                                            :multiple="false"
                                            :searchable="true"
                                            :hide-selected="true"
                                            open-direction="bottom"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            :show-labels="false"
                                            class="custom-multiselect userSelect"
                                            @select="changeInfoCategory(information.informationCategory)"
                                            :disabled="!addInfo"
                                            placeholder="Information Category"
                                            :class="{
                                                'is-invalid borderRed': v$.information.informationCategory.$error,
                                                'has-options': infoCategoryList && infoCategoryList.length > 0,
                                                'no-options':  !infoCategoryList.length,
                                            }"
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Categories Found</span>
                                            </template>
                                        </Multiselect>
                                        <div v-if="v$.information.informationCategory.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.informationCategory.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing">
                                    <div class="w-full" v-if="!addInfo">
                                        <label class="controllabel">Information</label>
                                        <div class="text-sm">{{information.information}}
                                        </div>
                                    </div>
                                    <div class="w-full" v-else>
                                        <label class="controllabel">Information</label>
                                        <Multiselect
                                            v-model="information.information"
                                            :options="infoList"
                                            :multiple="false"
                                            :searchable="true"
                                            :hide-selected="true"
                                            open-direction="bottom"
                                            :close-on-select="true"
                                            :show-labels="false"
                                            class="custom-multiselect"
                                            :disabled="!addInfo || information.informationCategory == ''"
                                            @select="changeInformation(information.information)"
                                            @deselect="changeInformation(information.information)"
                                            placeholder="Information"
                                            :class="{
                                                'is-invalid borderRed': v$.information.information.$error,
                                                'has-options': infoList && infoList.length > 0,
                                                'no-options':  !infoList.length,
                                            }"
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Information Found</span>
                                            </template>
                                        </Multiselect>
                                        <div v-if="v$.information.information.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.information.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="information.information != '' && !addInfo">
                                        <label class="controllabel">Information Owner</label>
                                        <div class="text-sm">
                                            {{information.informationOwner.label}}
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-else>
                                        <label class="controllabel">Information Owner</label>
                                        <Multiselect
                                            v-model="information.informationOwner"
                                            :options="optionsUserArray"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            label="label"
                                            track-by="value"
                                            :searchable="true"
                                            :hideArrow="false"
                                            deselectLabel=""
                                            selectLabel=""
                                            class="custom-multiselect"
                                            disabled
                                            placeholder="Information Owner"
                                            
                                        ></Multiselect>

                                        <!-- :class="{
                                                'is-invalid borderRed': v$.information.informationOwner.$error
                                            }"
                                        <div v-if="v$.information.informationOwner.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.informationOwner.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                    <div class="w-6/12" :class="information._id ? '' : 'leftspacing'" v-if="this.addInfoMethod.confidentiality == information.confidentiality || !addInfo || !addCIAInfo">
                                        <label class="controllabel">Confidentiality</label>
                                        <div v-for="(dataValue, indexNum) in dbConfidentiality" :key="indexNum">
                                            <div v-if="information.confidentiality == dataValue.value">
                                                <div class="text-sm">{{dataValue.key}}</div>
                                                <!-- <input type="text" class="inputboxstyling" disabled v-model="dataValue.key" placeholder="Security Category" /> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing" v-else>
                                        <label class="controllabel">Confidentiality</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            disabled
                                            v-model="information.confidentiality"
                                            
                                        >
                                            <option value="" selected disabled hidden>Confidentiality</option>
                                            <option v-for="obj in dbConfidentiality" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <!-- :class="{
                                                'is-invalid': v$.information.confidentiality.$error
                                            }"
                                        <div v-if="v$.information.confidentiality.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.confidentiality.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="this.addInfoMethod.integrity == information.integrity || !addInfo || !addCIAInfo">
                                        <label class="controllabel">Integrity</label>
                                        <div v-for="(dataValue, indexNum) in dbIntegrity" :key="indexNum">
                                            <div v-if="information.integrity == dataValue.value">
                                                 <div class="text-sm">{{dataValue.key}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-else>
                                        <label class="controllabel">Integrity</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            disabled
                                            v-model="information.integrity"
                                            
                                        >
                                            <option value="" selected disabled hidden>Integrity</option>
                                            <option v-for="obj in dbIntegrity" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <!-- :class="{
                                                'is-invalid': v$.information.integrity.$error
                                            }"
                                        <div v-if="v$.information.integrity.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.integrity.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                    <div class="w-6/12" :class="information._id ? '' : 'leftspacing'" v-if="this.addInfoMethod.availability == information.availability || !addInfo || !addCIAInfo">
                                        <label class="controllabel">Availability</label>
                                        <div v-for="(dataValue, indexNum) in dbAvailability" :key="indexNum">
                                            <div v-if="information.availability == dataValue.value">
                                                 <div class="text-sm">{{dataValue.key}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing" v-else>
                                        <label class="controllabel">Availability</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            v-model="information.availability"
                                            disabled
                                            
                                        >
                                            <option value="" selected disabled hidden>Availability</option>
                                            <option v-for="obj in dbAvailability" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>
                                        
                                        <!-- :class="{
                                                'is-invalid': v$.information.availability.$error
                                            }"
                                        <div v-if="v$.information.availability.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.availability.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="information.classification != '' || !addInfo">
                                        <label class="controllabel">Classification</label>
                                        <div v-for="(dataValue, indexNum) in dbInformationClassification" :key="indexNum">
                                            <div v-if="information.classification == dataValue.value">
                                                 <div class="text-sm">{{dataValue.key}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-else>
                                        <label class="controllabel">Classification</label> 
                                        <select
                                            class="disabledinputboxstyling disabledselect inputboxstyling"
                                            v-model="information.classification"
                                            placeholder="Classification"
                                            disabled
                                            :class="{
                                                'is-invalid': v$.information.classification.$error
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Classification</option>
                                            <option v-for="obj in dbInformationClassification" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <div v-if="v$.information.classification.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.classification.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-if="information.securityCategory != '' && !addInfo">
                                        <div v-for="(dataValue, indexNum) in dbSecurityCategory" :key="indexNum">
                                            <div v-if="information.securityCategory == dataValue.value">
                                                <label class="controllabel">Security Category</label>
                                                <div class="text-sm">{{dataValue.key}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="buttonposition flex justify-center items-center mb-4 mx-0" v-if="addInfo">
                            <button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn(information)">Cancel</button>
                            <button @click="addInformationToObject()" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Add</button>
                        </div>
                        <div class="border-b mx-4 border-solid border-lightgrey" v-if="addInformationObj.length > 0"></div>
                        <div class="flex flex-row px-4 justify-between" v-for="(info, index) in addInformationObj" :key="info">
                            <div class="statuscontainer w-11/12 my-2 border-solid border border-lightgrey rounded p-2" v-if="addInformationObj.length > 0">
                                <div class="text-sm font-light text-gray bg-white">
                                    <ul>
                                        <li class="text-sm flex flex-row items-center justify-between">
                                            {{ info.information }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="flex justify-center items-center">
                                <button class="float-right" @click="onInfoDelete(index)">
                                    <img src="@/assets/deleteIcon.svg" class="h-5 w-5 cursor-pointer" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="buttonposition flex justify-center items-center mb-4 mx-0" v-if="addInformationObj.length > 0">
                        <button class="btnprimary mr-1.5" @click="onResetbtn(information)">Reset</button>
                        <button class="btnprimary" @click="saveInformation()">Save</button>
                    </div>
                </div>
            </fieldset>
        </popup>

        <popup class="applyPopup" v-if="applyPopup" :popUp="applyPopup">
            <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
                <div class="relative w-full max-w-xl">
                    <!--content-->
                    <div class="border-0 rounded relative flex flex-col w-full bg-white risksAcceptance h-screen">
                        <!--header-->
                        <div class="flex items-center justify-between p-3 rounded popupheadingcontainer">
                            <h1 v-if="thisScreen == 'Acceptance'">Risk Accept</h1>
                            <h1 v-if="thisScreen == 'reset'">Risk Reset</h1>
                            <h1 v-if="thisScreen == 'deactive'">Risk Delete</h1>
                            <button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onCrossbtn()">
                                <!-- <XIcon class="h-5 w-5" /> -->
                                <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                            </button>
                        </div>
                        <div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
                        <div>
                            <div class="impactcontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-col">
                                        <div class="upperspacing">
                                            <label class="controllabel">Systems</label>
                                            <div class="text-xs flex flex-wrap">
                                                <div v-for="system in mainSystem" :key="system" class="mr-2 mb-2 px-2 py-1 bg-primary text-white rounded">
                                                    {{ mappedSystemsOptions.find(option => option.value === mainSystem[0])?.label || "-" }}
                                                </div>
                                                <div v-if="mainSystem.length === 0">No Systems selected</div>
                                            </div>
                                            <!-- <Multiselect 
                                                v-model="mainSystem"
                                                :options="mappedSystemsOptions" 
                                                :multiple="false"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                label="label"
                                                track-by="value"
                                                :searchable="true"
                                                :hideArrow="false"
                                                deselectLabel=""
                                                selectLabel=""
                                                class="custom-multiselect"
                                                :disabled=true
                                                placeholder="Systems">
                                            </Multiselect> -->
                                        </div>
                                        <div class="border-b border-solid border-lightgrey mt-4"></div>
                                        <div class="w-full upperspacing">
                                            <label v-if="thisScreen == 'Acceptance'" class="controllabel">Reason for Accepting Risk / Justification</label>
                                            <label v-if="thisScreen == 'reset'" class="controllabel">Reason for Reset Risk / Justification</label>
                                            <label v-if="thisScreen == 'deactive'" class="controllabel">Reason for Delete Risk / Justification</label>
                                            <textarea type="text" class="inputboxstyling h-20 max-h-48" v-model.trim="applyObj.comments" placeholder="Reason / Justification"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--footer-->
                        <div class="buttonposition flex justify-center items-center">
                            <button :disabled="clickOnButton" @click="cancelAceeptResetBtn()" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="mr-3">Cancel</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'Acceptance'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="createTaskForAction(applyObj, 10403)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'reset'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary" @click="createTaskForAction(applyObj, 10407)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'deactive'" :class="applyObj.comments == '' ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary" @click="createTaskForAction(applyObj, 10404)">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </popup>

        <popup v-if="showPriorityPopUp">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Risk Prioritize</h1>

                    <button class="float-right text-3xl" @click="closePriorityPopup()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-priority">
                <div class="w-full popupbodycontainer">
                    <div class="w-full">
                        <label class="controllabel font-normal text-sm mb-1" for="vulnerability">Risk Name</label> 
                        <input
                            v-model="riskPriorityShow.risk"
                            disabled
                            placeholder="Name of the Risk"
                            id="id"
                            class="disabledinputboxstyling"
                            :class="{
                                'is-invalid': v$.riskPriorityShow.risk.$error
                            }"
                        />
                        <div class="text-red-500" v-if="v$.riskPriorityShow.risk.$error">
                            <div v-if="v$.riskPriorityShow.risk.required.$invalid" class="error-text text-xs">Risk name is required</div>
                        </div>
                    </div>

                    <div class="w-1/2 upperspacing">
                        <label class="controllabel">Priority</label>
                        <select class="inputboxstyling" placeholder="Priority" v-model="riskPriorityShow.priority">
                            <option v-for="(priority, index) in dbPriority" :key="index" :value="priority.value">{{ priority.key }}</option>
                        </select>
                    </div>

                    <div class="text-red-500" v-if="v$.riskPriorityShow.priority.$error">
                        <div v-if="v$.riskPriorityShow.priority.required.$invalid" class="error-text text-xs">Please change priority</div>
                    </div>
                    <div v-if="this.riskPriorityShow.priority !== this.priorityDummy.priority">
                        <div class="row flex flex-row w-full">
                            <div class="w-full upperspacing">
                                <label for="Risk" class="controllabel">Justification</label> 
                                <textarea
                                    class="inputboxstyling"
                                    placeholder="Justification"
                                    v-model.trim="riskPriorityShow.justification"
                                    :class="{
                                        'is-invalid': v$.riskPriorityShow.justification.$error
                                    }"
                                />
                            </div>
                        </div>
                        <div class="text-red-500" v-if="v$.riskPriorityShow.justification.$error">
                            <div v-if="v$.riskPriorityShow.justification.required.$invalid" class="error-text text-xs">Justification is required</div>
                        </div>
                    </div>

                    <div class="buttonposition flex justify-center items-center mt-9">
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="JSON.stringify(riskPriorityShow) !== JSON.stringify(priorityDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelPriorityBtn()">Cancel</button>
                        <button :disabled="clickOnButton" @click="addOrUpdatePriority()" :class="JSON.stringify(riskPriorityShow) !== JSON.stringify(priorityDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                    </div>
                </div>
            </div>
        </popup>
    </div>
</template>
<style scoped>
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 60px !important; 
}
.tooltipwidth {
    width: 180px !important;
}
.borderRed {
    border: 1px solid red !important;
}
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #152a43;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}
.midHead {
    width: 160px !important;
}
.widthForSelect{
    width : 100px !important;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.nonEmptyTable tr:last-child {
    border-bottom: 1px solid #e9e9e9;
}
.anime-left {
    animation-name: moveLeft;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
@keyframes moveLeft {
    0% {
        opacity: 1;
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0);
    }
}
.anime-right {
    animation-name: moveRight;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
@keyframes moveRight {
    0% {
        opacity: 1;
        transform: translateX(-100vw);
    }
    100% {
        transform: translateX(0);
    }
}
/* .progressbarSI {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    position: relative;
    top: -8px;
    border-radius: 2px;
}
.progress {
    background: #152a43;
} */
.setBox {
    width: initial;
}

.box {
    border: 2px solid red;
}
.adjust {
    flex-shrink: 0;
}
.button-adjust {
    position: relative;
    top: -30px;
    z-index: 1;
    cursor: pointer;
}
.borderBottom {
    position: relative;
    z-index: 2;
    cursor: pointer;
}
.businesssPopup label {
    font-size: 16px;
    font-family: Open Sans !important;
}
.z-index {
    z-index: 100;
}
.accept {
    width: 100px;
}
.ellipsis-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import popup from '@/components/popup.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers, requiredIf } from '@vuelidate/validators';
import { emitter, toast } from '@/main';
import moment from 'moment';
import store from '@/store';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import dropdown from '@/components/dropdown.vue';
import breadCrumb from '@/components/breadCrumb.vue';

import pagination from '@/components/pagination.vue';
const alphabet = helpers.regex(/^[a-zA-Z]+$/);
import newTable from '@/components/newTable.vue';
import toolTip from '@/components/toolTip.vue';

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            suggestionSwitch: '',
            //Tooltip
            pop: false,
            xPos: '',
            yPos: '',
            routesArray: [],
            //Pagination
            paginationObj: [],
            pageSize: 20,
            currentPage: 1,
            pageBoxes: 3,
            pageIndexStart: [],
            leftBox: 'left',
            rightBox: 'right',
            pageIndexEnd: [],
            totalPages: '',
            pagesArray: [],
            clickOnButton: false,
            start: '',
            end: '',
            //Sort
            currentSort: 'name',
            currentSortDir: 'asc',
            className: '',
            classSort: '',
            //Carousel
            selectedTabObj: { displayName: 'Risks', name: 'Risk', id: 0 },
            carouselHead: [
                { displayName: 'Risks', name: 'Risk', id: 0 },

                { displayName: 'Information', name: 'Information', id: 1 },

                { displayName: 'Properties', name: 'Properties', id: 2 },
                { displayName: 'Risk Discovery', name: 'Risk Discovery', id: 3 }
            ],
            sideButton: false,
            //BreadCrumb
            index: 0,
            navIndexNums: [],

            //Permissions
            disabled: false,
            // permissions: [],
            tipIndex: '',
            tipColumn: '',
            //Risk Assesment
            selectAll: false,
            checkedItems: [],
            systemAssesmentList: [],
            rootRiskList: [],
            selectedIndex: null,
            rowData: [],
            discovery: false,
            insertedIds: [],
            newInformationObj: [],
            allSystems: [],
            informationList: [],
            userOptions: [],
            searchValue: '',
            addInfoMethod: {},
            addInfo: false,
            infoId: [],
            orgUsersList: [],
            optionsUserArray: [],
            applyPopup: false,
            applyObj: {
                systems: [],
                comments: ''
            },
            showAddRiskPopup: false,
            thisScreen: '',
            mainSystem: [],
            mappedSystemsOptions: [],
            providerList: ['AWS', 'Microsoft Azure', 'Google Cloud', 'DigitalOcean', 'IBM', 'Rackspace', 'Oracle', 'Red Hat', 'Alibaba'],
            information: {
                id: '',
                information: '',
                informationCategory: '',
                informationOwner: '',
                confidentiality: '',
                integrity: '',
                availability: '',
                securityCategory: '',
                // systems: []
                classification: ''
            },
            addInformationObj: [],
            infoTableRow: [],
            systems: [],
            riskList: [],
            showFilters: false,
            filterOptionOpen: true,

            riskId: [],
            dummyList: [],
            assesmentlist: [],
            tenantsSelect: [],

            selectedRowIndex: -1,
            showBlock: false,
            showDropdown: false,
            addingRisk: false,
            riskListData: [],

            filterDummy: {
                severity: '',
                overallImpact: '',
                likelihood: '',
                statusCode: ''
            },

            openRiskPopup: false,
            showInformationPopup: false,
            selectedCategory: [],
            categories: [],
            likelihoodMapping: [],

            optionsList: [],
            filteredOptionsList: [],
            groupByInfos: '',
            editPop: false,
            risk: [],
            managedByOptions: [],
            businessEntityList: [],
            tenants: [],
            riskThreatData: {
                risk: '',
                threatCategory: '',
                threatSource: '',
                threatEvent: '',
                vulnerability: '',
                business: '',
                operations: '',
                legal: '',
                financial: '',
                likelihood: '',
                riskCategory: '',
                overallImpact: '',
                severity: '',
                riskOwner: '',
            },

            //Systems
            system: {
                systemName: '',
                ownedBy: '',
                description: '',
                managedBy: '',
                owner: '',
                hostingType: '',
                hostingProvider: '',
                publicFacing: '',
                add: '',
                country: '',
                state: '',
                city: ''
            },
            addCIAInfo: true,
            dummySystems: {},
            dynamicPagination: '',
            tasksData: [],
            mainRisk: null,
            objToBeModified: {},
            presentTip: '',

            informationHeaders: ['entityId', '_id', 'category', 'information', 'securityCategory'],
            riskHeaders: ['severity', 'risk', 'riskOwnerName', 'createdAt', 'updatedAt', 'statusCode', 'priority', 'History', 'actionButton'],

            countriesList: [],
            statesList: [],
            citiesList: [],
            stateInfoList: [],

            countriesStatesCities: [
                {
                    CountryName: 'India',
                    States: [
                        {
                            StateName: 'Maharashtra',
                            Cities: ['Pune', 'Nagpur', 'Mumbai']
                        },
                        {
                            StateName: 'Kerala',
                            Cities: ['Kochi', 'Munnar']
                        },
                        {
                            StateName: 'Telangana',
                            Cities: ['Hyderabad', 'Nizamabad', 'Warangal']
                        },
                        {
                            StateName: 'Andhra Pradesh',
                            Cities: ['Vijayawada', 'Vizag', 'Rajamundry']
                        }
                    ]
                },
                {
                    CountryName: 'Australia',
                    States: [
                        {
                            StateName: 'Aukland',
                            Cities: ['GlenField', 'Henderson', 'MilFord']
                        },
                        {
                            StateName: 'Melbourne',
                            Cities: ['Melbourne', 'South Oakleigh']
                        }
                    ]
                }
            ],
            cloud: ['AWS', 'Microsoft Azure', 'Google Cloud', 'DigitalOcean', 'IBM', 'Rackspace', 'Oracle', 'Red Hat', 'Alibaba'],
            hostingTypeObj: [
                {
                    id: 1,
                    value: 'On-premise'
                },
                {
                    id: 2,
                    value: 'Data center'
                },
                {
                    id: 3,
                    value: 'Cloud'
                }
            ],
            yesNo: [
                {
                    id: 1,
                    value: 'Yes'
                },
                {
                    id: 2,
                    value: 'No'
                }
            ],
            riskCategoryList: [],
            threatCategoryList: [],
            threatSourceList: [],
            infoList: [],
            infoCategoryList: [],
            rootRisksList: [],
            showPriorityPopUp: false,
            riskPriorityShow: {},
            priorityDummy: {},
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0, // Total number of groups for small screens
            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
			displayErrorMessageOfRisk : false,
			displayErrorMessageOfThreat : false,
			displayErrorMessageOfVulnerability : false,
        };
    },
    components: {
        popup,
        breadCrumb,
        Multiselect,
        pagination,
        newTable,
        toolTip
    },
    validations() {
        return {
            system: {
                systemName: { required },
                ownedBy: { required },
                description: { required },
                managedBy: { required },
                hostingType: { required },
                owner: { required },
                publicFacing: { required },
                add: {
                    required: requiredIf(() => {
                        return this.system.publicFacing == '1';
                    })
                },
                city: { required, alphabet },
                state: { required, alphabet },
                country: { required, alphabet },
                hostingProvider: { required }
            },
            information: {
                informationCategory: { required },
                information: { required },
                informationOwner: { required },
                confidentiality: { required },
                integrity: { required },
                availability: { required },
                securityCategory: { required },
                classification: { required }
            },
            riskThreatData: {
                risk: { required },
                threatEvent: { required },
                vulnerability: { required },
                business: { required },
                operations: { required },
                legal: { required },
                financial: { required },
                likelihood: { required },
                riskCategory: { required },
                riskOwner: { required }
            },
            riskPriorityShow: {
                risk: { required },
                priority: {
                    required: requiredIf(() => {
                        return this.priorityDummy.priority === this.riskPriorityShow.priority;
                    })
                },
                justification: { required }
            }
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
            dbSeverity: 'dbSeverity',
            dbLikelihood: 'dbLikelihood',
            dbBussiness: 'dbBussiness',
            dbOrganization: 'dbOrganization',
            dbOperations: 'dbOperations',
            dbLegal: 'dbLegal',
            dbFinancial: 'dbFinancial',
            dbTechnical: 'dbTechnical',
            dbProcessCriticality: 'dbProcessCriticality',
            dbAvailability: 'dbAvailability',
            dbConfidentiality: 'dbConfidentiality',
            dbIntegrity: 'dbIntegrity',
            dbInformationClassification: 'dbInformationClassification',
            dbSecurityCategory: 'dbSecurityCategory',
            dbPriority: 'dbPriority',
            dbOverAllImpact: 'dbOverAllImpact'
        }),
        disableStatus: function (): any {
            if (this.selectedIndex == 0) {
                let disableStatusCondition;
                if (this.riskThreatData && !this.addingRisk) {
                    if (JSON.stringify(this.riskThreatData) == JSON.stringify(this.riskObjCopy)) {
                        disableStatusCondition = false;
                    } else {
                        disableStatusCondition = true;
                    }
                } else {
                    let valueArray: any = [];
                    Object.values(this.riskThreatData).forEach((value: any) => {
                        valueArray.push(value);
                    });
                    for (let value of valueArray) {
                        if (value != '') {
                            disableStatusCondition = true;
                        }
                    }
                }
                return disableStatusCondition;
            } else if (this.selectedIndex == 2) {
                let disableStatusCondition;
                if (this.objToBeModified) {
                    if (JSON.stringify(this.objToBeModified) == JSON.stringify(this.dummySystems)) {
                        disableStatusCondition = false;
                    } else {
                        disableStatusCondition = true;
                    }
                }
                return disableStatusCondition;
            } else {
                let output;
                if (this.editPop) {
                    let objCopy = Object.assign({}, this.information);
                    if (JSON.stringify(objCopy) == JSON.stringify(this.dummyObj)) {
                        output = false;
                    } else {
                        output = true;
                    }
                } else {
                    let val: any = [];
                    Object.values(this.information).forEach((value: any) => {
                        val.push(value);
                    });
                    for (let n of val) {
                        if (n != '') {
                            output = true;
                        }
                    }
                }
                return output;
            }
        },
        sortedData: function (): any {
            return [...this.paginationObj]
                .filter((row: any) => {
                    for (const key in this.filterDummy) {
                        const inputValue = this.filterDummy[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
        tabHead(): any {
            return this.carouselHead.filter((row: any, index: any) => {
                let start = (this.currentPage - 1) * this.pageSize;
                this.index = 0;
                let end = this.currentPage * this.pageSize;

                if (index >= start && index < end) return true;
            });
        }
    },

    async mounted() {
        this.dataLoading = true;

        this.visibleTabs = [...this.carouselHead];
        // this.selectedIndex = 0;
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // Initial setup
        this.updateButtonStates(); // Initial update to button states
        await this.getSystems();
        await this.getAllTenants();
        await this.getSystemInformation();
        if (this.userInfo.currentRole == 10210 || this.userInfo.currentRole == 10209) {
            this.carouselHead.pop();
        }
        await this.getAllTasks();
        let savedTabKey: any = sessionStorage.getItem('selectedTab');
        console.log("savedTabKey",savedTabKey)
		if (savedTabKey) {
            savedTabKey = parseInt(savedTabKey)
		    const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
            console.log("savedTab", savedTab)
            if (savedTab) {
                await this.toggleTabs(savedTab);
            }else{
                this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
                this.selectedIndex = 0;
                this.navIndexNums = { index: 0, name: 'Risks' };
            }
		}
		else{
			this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
            this.navIndexNums = { index: 0, name: 'Risks' };
			this.selectedIndex = 0;
            await this.toggleTabs(this.selectedTabObj);
		}
        // await this.toggleTabs(0);

        await this.getBusinessEntities();
        await this.getThirdParties();

        await this.getRisksList();
        await this.getRootRiskList();

        this.dataLoading = false;


        let systemId = this.$route.params.id;
        this.routesArray = [
            { name: 'Systems', routeName: 'systems' },
            { name: this.system.systemName, routeName: 'systemInformation', id: `${systemId}` }
        ];
        this.navIndexNums = { index: 0, name: 'Risks' };

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        emitter.off('showData123');
        emitter.on('showData123', (isData: any) => {
            let comingId = isData.entityData._id;
            if (this.selectedIndex == 1) {
                let showObj = this.optionsList.find((obj: any) => {
                    return obj._id === comingId;
                });
                this.showEditPopUp(showObj);
                console.log("showObj",showObj)
            } else {
                let showObj = this.paginationObj.find((obj: any) => {
                    return obj._id === comingId;
                });
                this.showPopupbyRow(showObj);
            }
        });

        emitter.off('changeRouteInSystemInfo');
        emitter.on('changeRouteInSystemInfo', (selectedActionObj: any) => {
            let risk = selectedActionObj.data;
            let route = selectedActionObj.route;
            this.changeRoute(risk, route);
        });

        emitter.off('showPriorityPopup');
        emitter.on('showPriorityPopup', (isData: any) => {
            this.showPriorityPopUpAction(isData);
        });

        this.scalesInfo = store.getters.scalesInfo;
        await store.dispatch('getScalesInfo').then(async (res: any) => {
            this.scalesInfo = store.getters.scalesInfo;
            this.likelihoodMapping = this.scalesInfo.filter((scale: any) => {
                return scale.entity == 'Likelihood';
            });
            this.severityMapping = this.scalesInfo.filter((scale: any) => {
                return scale.entity == 'Likelihood';
            });
            this.impactMapping = this.scalesInfo.filter((scale: any) => {
                return scale.entity == 'Impact';
            });
        });
    },
    methods: {
        asyncFind(query:any){
            this.searchValue = query
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
         async calculateOverallImpact(){
            let a1 = this.riskThreatData.business == '' ? 0 : this.riskThreatData.business;
            let a2 = this.riskThreatData.operations == '' ? 0 : this.riskThreatData.operations;
            let a3 = this.riskThreatData.legal == '' ? 0 : this.riskThreatData.legal;
            let a4 = this.riskThreatData.financial == '' ? 0 : this.riskThreatData.financial;

            let arr = [a1, a2, a3, a4]

            let avg =  arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
            this.riskThreatData.overallImpact = Math.max(Math.round(avg), 1);
        },
        changeInfoCategory(id: any) {
            this.infoList = [];
            this.information.confidentiality = '';
            this.information.availability = '';
            this.information.integrity = '';
            this.information.securityCategory = '';
            this.information.information = '';
            
            this.optionsList.map((obj: any) => {
                if (obj.informationCategory === id) {
                    const isInTable = this.infoTableRow.some((row: any) => row.information === obj.information);
                    if (!isInTable) {
                        this.infoList.push(obj.information);
                    }
                }
            });
        },
        changeInformation(name: any) {
            if (name == '' || name == null || name == undefined) {
                this.addCIAInfo = true;
                this.information.confidentiality = '';
                this.information.availability = '';
                this.information.integrity = '';
                this.information.informationOwner = '';
                this.information.id = '';
            } else {
                this.addCIAInfo = false;
                let findObj: any = this.optionsList.find((obj: any) => {
                    return obj.information == name;
                });
                this.information.confidentiality = findObj.confidentiality;
                this.information.availability = findObj.availability;
                this.information.integrity = findObj.integrity;
                this.information.classification = findObj.classification
                this.information.informationOwner = this.optionsUserArray.find((obj:any)=>{return obj.value == findObj.informationOwner});
                this.information.id = findObj._id;
                this.getMaxIdValue();
            }
        },
        restrictCharacters(code: any){
            if(code === 'Threat'){
                if(this.riskThreatData.threatEvent.length > 300){
                this.displayErrorMessageOfThreat = true;
                }else if(this.riskThreatData.threatEvent.length < 300 || this.riskThreatData.threatEvent.length == 300){
                    this.displayErrorMessageOfThreat = false;
                }
            } else if(code === 'Risk'){
                if(this.riskThreatData.risk.length > 300){
                    this.displayErrorMessageOfRisk = true;
                }else if(this.riskThreatData.risk.length < 300 || this.riskThreatData.risk.length == 300){
                    this.displayErrorMessageOfRisk = false;
                }
            } else if(code === 'Vulnerability'){
                if(this.riskThreatData.vulnerability.length > 300){
                    this.displayErrorMessageOfVulnerability = true;
                }else if(this.riskThreatData.vulnerability.length < 300 || this.riskThreatData.vulnerability.length == 300){
                    this.displayErrorMessageOfVulnerability = false;
                }
            }
            
        },
        getRiskStateValue(value: any) {
            let label = this.dbLikelihood.find((l: any) => {
                return l.value == value;
            });
            if (label == undefined) {
                return '';
            } else {
                return label.key;
            }
        },
        paginationUpdations(currentPage: any, pageSize: any) {
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        async addOrUpdaterisk(riskData: any) {
            this.v$.riskThreatData.$touch();
            // this.risk.push(riskData);

            if (!this.v$.riskThreatData.$invalid && this.displayErrorMessageOfRisk == false && this.displayErrorMessageOfThreat == false && this.displayErrorMessageOfVulnerability == false) {
                this.clickOnButton = true
                if (this.addingRisk == true) {
                    await this.addRiskList();
                } else {
                    let copyOfriskData:any = {...riskData}
                    let oldObj: any = {};
                    let newObj: any = {};
                    Object.keys(copyOfriskData).map((key: any) => {
                        if (copyOfriskData[key] != this.riskObjCopy[key] && key != 'updatedValues') {
                            oldObj[key] = this.riskObjCopy[key];
                            newObj[key] = copyOfriskData[key];
                        }
                    });
                    copyOfriskData.updatedValues = { oldRisk: [], newRisk: [] };
                    copyOfriskData.updatedValues.oldRisk.push(oldObj);
                    copyOfriskData.updatedValues.newRisk.push(newObj);

                    this.risk.push(copyOfriskData);
                    await this.updateRisk();
                }
            } 
            // else {
            //     toast.error(`error`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
            this.risk = [];
        },
        async addRiskList() {
            const finalArray: Record<string, any> = [];
            let copyOfriskThreatData:any = {...this.riskThreatData}
            finalArray.push(copyOfriskThreatData);
            finalArray.map((obj: any) => {
                obj.statusCode = 10401;
                obj.createdAt = new Date();
                obj.createdBy = this.userInfo._id;
                obj.tier = 3;
                obj.systems = [];
                obj.riskOwner = obj.riskOwner.value;
                let systemId: any = this.$route.params.id;
                obj.systems.push(systemId);
            });
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/create?org==true`, finalArray).then((result: any) => {
                    let riskId = result.data.insertedIds[0];
                    const payload: Record<string, any> = [];
                    console.log("finalArray",finalArray)
                    finalArray[0].systems.map((presentSystemId: any) => {
                        let newObj = { systemId: '', riskId: [`${riskId}`], statusCode: 10401 };
                        newObj.systemId = presentSystemId;

                        payload.push(newObj);
                    });
                    this.createRiskTask(payload);
                });
                this.openRiskPopup = false;
            } catch (e) {
                toast.info('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally{
                this.clickOnButton = false
            }
        },
        async createRiskTask(payload: any) {
            try {
                payload.createdAt = new Date();
                payload[0].tier = 3;
                payload[0].createdAt = new Date();
                payload[0].createdBy = this.userInfo._id;
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/create/risks`, payload).then((result: any) => {
                    this.systemAssesmentList = result.data;
                    toast.info('created', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                this.openRiskPopup = false;
                this.v$.$reset();
                this.addingRisk = false;
                await this.getOrgSystemRisks();
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async updateRisk() {
            let copyOfrisk = [...this.risk]
            try {
                let riskId = copyOfrisk[0]._id;
                delete copyOfrisk[0]._id;
                delete copyOfrisk[0].overallImpact;
                delete copyOfrisk[0].severity;
                copyOfrisk[0].riskOwner = copyOfrisk[0].riskOwner.value;
                copyOfrisk[0].updatedAt = new Date();
                copyOfrisk[0].updatedBy = this.userInfo._id;
                console.log("copyOfrisk[0]",copyOfrisk[0]);
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${riskId}/update?org==true`, copyOfrisk[0]).then((result: any) => {
                    toast.info(`Updated succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
                this.openRiskPopup = false;
                this.showPriorityPopUp = false;
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
            await this.getOrgSystemRisks();
        },
        showPopupbyRow(obj: any) {
            this.openRiskPopup = true;
            this.riskThreatData = { ...obj };
            this.riskObjCopy = { ...obj };
            this.addingRisk = false;
        },
        doFalse() {
            emitter.emit('deActiviteToolTip')
        },
        addRiskForSystem() {
            this.v$.$reset();
            this.openRiskPopup = true;
            this.addingRisk = true;
            this.riskThreatData = { risk: '', threatCategory: '', threatSource: '', threatEvent: '', vulnerability: '', business: '', operations: '', legal: '', financial: '', likelihood: '', riskCategory: '',overallImpact:'' };
        },
        changeRoute(risk: any, route: any) {
            this.mainRisk = risk;
            if (route == '/riskAcceptance' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'Acceptance';
                if (this.mainRisk.statusCode == 10403) {
                    toast.error(`Cannot Accept the Risk in this Status`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForAccept = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForAccept) {
                            let systemObj = { value: this.$route.params.id, label: this.system.systemName };
                            this.applyPopup = true;
                            this.mappedSystemsOptions.push(systemObj);
                            this.mainSystem.push(systemObj.value);
                            this.applyObj.systems = [...this.mainSystem];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let systemObj = { value: this.$route.params.id, label: this.system.systemName };
                        this.applyPopup = true;
                        this.mappedSystemsOptions.push(systemObj);
                        this.mainSystem.push(systemObj.value);
                        this.applyObj.systems = [...this.mainSystem];
                    }
                }
            } else if (route == '/mitigateRisk' && Object.keys(this.mainRisk).includes('riskOwner')) {
                if (this.mainRisk.statusCode == 10402 || this.mainRisk.statusCode == 10406) {
                    this.thisScreen = 'Mitigate';
                    let systemObj = { value: this.$route.params.id, label: this.system.systemName };
                    const mitigateSystems = [];
                    mitigateSystems.push(systemObj.value);
                    localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateSystems));
                    this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'system', id: this.mainRisk.systemId }, query: { systems: true } });
                } else if (Object.keys(this.mainRisk).includes('tasks')) {
                    let conditionForMitigate = this.mainRisk.tasks.every((taskId: any) => {
                        let a = this.tasksData.find((task: any) => {
                            return task._id == taskId;
                        });
                        return a.statusCode == 10403 || a.statusCode == 10410;
                    });
                    if (this.mainRisk.statusCode != 10402 && this.mainRisk.statusCode != 10406 && conditionForMitigate) {
                        this.thisScreen = 'Mitigate';
                        let systemObj = { value: this.$route.params.id, label: this.system.systemName };
                        const mitigateSystems = [];
                        mitigateSystems.push(systemObj.value);
                        if (this.mainRisk.statusCode == 10405 || this.mainRisk.statusCode == 10403) {
                            toast.error(`Cannot Mitigate the Risk in Mitigate Status`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        } else {
                            localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateSystems));
                            this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'system', id: this.mainRisk.systemId }, query: { systems: true } });
                        }
                    } else {
                        toast.error(`Tasks In pending for this Risk`, {
                            timeout: 5000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                } else {
                    this.thisScreen = 'Mitigate';
                    let systemObj = { value: this.$route.params.id, label: this.system.systemName };
                    const mitigateSystems = [];
                    mitigateSystems.push(systemObj.value);
                    localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateSystems));
                    this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'system', id: this.mainRisk.systemId }, query: { systems: true } });
                }
            } else if (route == '/reset' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'reset';
                if (this.mainRisk.statusCode == 10401) {
                    toast.error(`Cannot Reset the Risk in this Status`, {
                        timeout: 5000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForReset = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForReset) {
                            let systemObj = { value: this.$route.params.id, label: this.system.systemName };
                            this.applyPopup = true;
                            this.mappedSystemsOptions.push(systemObj);
                            this.mainSystem.push(systemObj.value);
                            this.applyObj.systems = [...this.mainSystem];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let systemObj = { value: this.$route.params.id, label: this.system.systemName };
                        this.applyPopup = true;
                        this.mappedSystemsOptions.push(systemObj);
                        this.mainSystem.push(systemObj.value);
                        this.applyObj.systems = [...this.mainSystem];
                    }
                }
            } else if (route == '/deactive') {
                this.thisScreen = 'deactive';
                if (Object.keys(this.mainRisk).includes('tasks')) {
                    let conditionForDelete = this.mainRisk.tasks.every((taskId: any) => {
                        let a = this.tasksData.find((task: any) => {
                            return task._id == taskId;
                        });
                        return a.statusCode == 10403 || a.statusCode == 10410;
                    });
                    if (conditionForDelete) {
                        let systemObj = { value: this.$route.params.id, label: this.system.systemName };
                        this.applyPopup = true;
                        this.mappedSystemsOptions.push(systemObj);
                        this.mainSystem.push(systemObj.value);
                        this.applyObj.systems = [...this.mainSystem];
                    } else {
                        toast.error(`Tasks in Pending for this Risk`, {
                            timeout: 5000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                } else {
                    let systemObj = { value: this.$route.params.id, label: this.system.systemName };
                    this.applyPopup = true;
                    this.mappedSystemsOptions.push(systemObj);
                    this.mainSystem.push(systemObj.value);
                    this.applyObj.systems = [...this.mainSystem];
                }
            } else if (route == '/clone') {
                console.log('riskkkkkk', risk);
                let tierType: any;
                if (risk.tier == 1) {
                    tierType = 'organization';
                } else if (risk.tier == 2) {
                    tierType = 'process';
                } else if (risk.tier == 3) {
                    tierType = 'systems';
                }

                this.$router.push({ name: 'cloner', params: { mappedRisk: risk.mappedRisk, riskId: risk._id, type: tierType }, query: { systems: true } });
            }
        },
        colorRiskBg(status: any) {
            if (status == 1) {
                return ['bg-lightgreen', 'opacity-10'];
            } else if (status == 2) {
                return ['bg-green', 'opacity-10'];
            } else if (status == 3) {
                return ['bg-yellow', 'opacity-10'];
            } else if (status == 4) {
                return ['bg-error', 'opacity-10'];
            } else if (status == 5) {
                return ['bg-darkred', 'opacity-10'];
            }
        },
        colorRiskSeverity(status: any) {
            if (status == 1) {
                return ['font-semibold text-lightgreen'];
            } else if (status == 2) {
                return ['font-semibold text-green'];
            } else if (status == 3) {
                return ['font-semibold text-yellow'];
            } else if (status == 4) {
                return ['font-semibold text-error'];
            } else if (status == 5) {
                return ['font-semibold text-darkred'];
            }
        },
        colorRiskText(status: any) {
            if (status == 1) {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
            } else if (status == 2) {
                return ['text-green', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-green'];
            } else if (status == 3) {
                return ['text-yellow', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-yellow'];
            } else if (status == 4) {
                return ['text-error', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-error'];
            } else if (status == 5) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            }
        },
        colorPropBg(format: any, scaleScore: any) {
            if (format == 'circle') {
                if (scaleScore == 5) {
                    return ['bg-darkred'];
                } else if (scaleScore == 4) {
                    return ['bg-error'];
                } else if (scaleScore == 3) {
                    return ['bg-yellow'];
                } else if (scaleScore == 2) {
                    return ['bg-green'];
                } else if (scaleScore == 1) {
                    return ['bg-lightgreen'];
                }
            } else if (format == 'border') {
                if (scaleScore == 5) {
                    return ['border-darkred border-2'];
                } else if (scaleScore == 4) {
                    return ['border-error border-2'];
                } else if (scaleScore == 3) {
                    return ['border-yellow border-2'];
                } else if (scaleScore == 2) {
                    return ['border-green border-2'];
                } else if (scaleScore == 1) {
                    return ['border-lightgreen border-2'];
                }
            }
        },
        getMaxIdValue() {
            this.information.securityCategory = Math.max(this.information.confidentiality, this.information.availability, this.information.integrity);
        },
        selectAllOptions(e: any) {
            this.checkedItems = [];
            const checked = e.target.checked;
            if (checked) {
                this.riskId = this.checkedItems;
                for (let obj of this.systemAssesmentList) {
                    this.checkedItems.push(obj._id);
                    this.objLen = this.checkedItems.length;
                }
            } else {
                this.checkedItems = [];
            }
        },
        changeInfo(risk: any, e: any) {
            this.riskId = this.checkedItems;
            if (this.checkedItems.length === this.systemAssesmentList.length) {
                this.selectAll = true;
            } else {
                this.selectAll = false;
            }
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        average(arr: any) {
            return arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
        },
        showEditPopUp(obj: any) {
            this.information = {...obj};
            this.information.informationOwner = this.optionsUserArray.find((value: any)=>{return value.value == obj.informationOwner});
            this.addInfo = false;
            this.dummyObj = { ...this.information };
            this.showInformationPopup = true;
        },
        async addInformationToObject() {
            this.v$.information.$touch();
            if (!this.v$.information.$invalid) {
                let obj = { ...this.information };
                this.addInformationObj.push(obj);
                this.infoList = [];
                console.log("addInformationObj",this.addInformationObj)
                Object.keys(this.information).forEach((prop) => {
                    Array.isArray(this.information[prop]) ? (this.information[prop] = []) : (this.information[prop] = '');
                });

                this.selectedCategory = [];
                this.addCIAInfo = true;
                this.v$.$reset();
            } 
            // else {
            //     toast.error(`error`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.allSystems = result.data.map((sys: any) => {
                    return { value: sys._id, label: sys.systemName };
                });
            });
        },
        async saveInformation() {
            this.showInformationPopup = false;
            var systemId = this.$route.params.id;
            let payload: any = [];

            this.addInformationObj.map((obj: any) => {
                payload.push(obj.id);
            });

            await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/system/${systemId}/add/information`, payload).then(async (result: any) => {
                console.log("addInformationObj111111111111",this.addInformationObj)
                this.addInformationObj = [];
                let infoTabObj = { displayName: 'Information', name: 'Information', id: 1 };
                await this.toggleTabs(infoTabObj);

                toast.info('Info Added', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            });
        },
        onInfoDelete(index: any): any {
            this.addInformationObj.splice(index, 1);
            toast.error('Inormation Deleted', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
        },
        onResetbtn() {
            this.addInformationObj = [];
            this.addCIAInfo = true;
        },
        onCancelRiskData() {
            if (this.addingRisk) {
                this.riskThreatData = { risk: '', threatCategory: '', threatSource: '', threatEvent: '', vulnerability: '', organization: '', business: '', operations: '', technical: '', legal: '', financial: '', likelihood: '', riskCategory: '' };
                this.displayErrorMessageOfRisk = false
                this.displayErrorMessageOfThreat = false
                this.displayErrorMessageOfVulnerability = false
                this.v$.$reset();
            } else {
                this.riskThreatData = { ...this.riskObjCopy };
                this.displayErrorMessageOfRisk = false
                this.displayErrorMessageOfThreat = false
                this.displayErrorMessageOfVulnerability = false
            }
        },
        async updateInformation(item: any, itemId: any) {
            try {
                let informationId = item._id;

                delete item._id;
                item.updatedAt = new Date();
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/${informationId}/update?org==true`, item).then((result: any) => {
                    toast.info(`Uploaded succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
                const payload: Record<string, any> = [];

                item.systems.forEach((itemSystem: any) => {
                    this.allSystems.forEach((sys: any) => {
                        let systemId = sys.value;
                        if (systemId === itemSystem) {
                            let systemValue = {
                                systemId: systemId,
                                information: [informationId]
                            };
                            payload.push(systemValue);
                        }
                    });
                });
                await this.updateMapping(payload);
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            this.infoTableRow = [];
            this.showInformationPopup = false;
        },
        async addInformation() {
            var systemId = this.$route.params.id;

            if ((!this.information._id && !this.addInfoMethod._id) || this.information.informationOwner != this.addInfoMethod.informationOwner) {
                this.informationList = [];
                this.newInformationObj = {
                    informationCategory: this.information.informationCategory,
                    information: this.information.information,
                    informationOwner: this.information.informationOwner,
                    confidentiality: this.information.confidentiality,
                    integrity: this.information.integrity,
                    availability: this.information.availability,
                    securityCategory: this.information.securityCategory,
                    systems: [systemId]
                };
                this.addInformationObj.push(this.newInformationObj);
            } else {
                let addInfooooo = [];
                addInfooooo = { _id: this.addInfoMethod._id, ...this.information };
                this.addInformationObj.push(addInfooooo);
            }
            this.selectedCategory = [];
            this.v$.$reset();
        },
        async updateMapping(payload: any) {
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/mapping/create`, payload).then(async (result: any) => {
                    console.log('updated');
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        onCancelbtn(status: any, type: any) {
            this.addCIAInfo = true;
            if (type == 'system') {
                this.system = { ...this.dummySystems };
                this.objToBeModified = this.system;
            } else {
                this.v$.$reset();
                Object.keys(this.information).forEach((prop) => {
                    Array.isArray(this.information[prop]) ? (this.information[prop] = []) : (this.information[prop] = '');
                });
            }
        },
        onCloseInformation() {
            if(this.addInformationObj && this.addInformationObj.length >0){
                this.addInformationObj = [];
            }
            this.v$.$reset();
            this.showInformationPopup = false;
        },
        showInformation() {
            Object.keys(this.information).forEach((prop) => {
                Array.isArray(this.information[prop]) ? (this.information[prop] = []) : (this.information[prop] = '');
            });
            this.editPop = false;
            this.addInfo = true;
            this.showInformationPopup = true;
        },
        async getRisksDiscoveryForSystem() {
            try {
                await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/system/${this.$route.params.id}/assesment`).then((result: any) => {
                    let AssesmentRisks = result.data;
                    this.systemAssesmentList = [];
                    let filteredAssesmentArrayOfRisks: any = [];
                    this.selectAll = false;

                    //Assesment Logic
                    filteredAssesmentArrayOfRisks = AssesmentRisks.filter((assObj: any) => {
                        let findMainObjInRiskList = this.riskList.find((obj: any) => {
                            return obj.mappedRisk == assObj._id;
                        });
                        if (findMainObjInRiskList == undefined) {
                            return true;
                        } else if (findMainObjInRiskList != undefined && findMainObjInRiskList.statusCode == 10404) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    this.systemAssesmentList = filteredAssesmentArrayOfRisks;

                    //SevCalcoulation for Root data
                    this.systemAssesmentList.forEach((risk: any) => {
                        let averageImpact = Math.round(this.average([ risk.business, risk.operations, risk.legal, risk.financial]));
                        risk.overallImpact = averageImpact;
                        risk.severity = this.riskSeverity(risk.overallImpact * risk.likelihood);
                    });
                    this.paginationObj = [...this.systemAssesmentList];

                    this.dummyList = result.data;
                });
            } catch (e) {
                console.log('error');
            }
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.systemAssesmentList;
            } else {
                this.systemAssesmentList = this.dummyList;
                for (const key in this.filterDummy) {
                    this.filterDummy[key] = '';
                }
            }
            emitter.emit('visible', this.showFilters);
        },
        async getRisksList() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risks/getall`).then((result: any) => {
                this.riskListData = result.data;
            });
        },
        async getRootRiskList() {
            this.rootRiskList = [];
            this.riskCategoryList = [];
            this.threatCategoryList = [];
            this.threatSourceList = [];

            const result = await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/getall`);
            result.data.map((risk: any) => {
                if(risk.riskCategory){
                    !this.riskCategoryList.includes(risk.riskCategory) ? this.riskCategoryList.push(risk.riskCategory) : '';
                }
                if(risk.threatCategory){
                    !this.threatCategoryList.includes(risk.threatCategory) ? this.threatCategoryList.push(risk.threatCategory) : '';
                }
                if(risk.threatSource){
                    !this.threatSourceList.includes(risk.threatSource) ? this.threatSourceList.push(risk.threatSource) : '';
                }

                if (risk.operations) {
                    let averageImpact = Math.round(this.average([risk.business, risk.operations, risk.legal, risk.financial]));
                    risk.overallImpact = averageImpact;
                    risk.severity = this.riskSeverity(risk.overallImpact * risk.likelihood);
                    this.rootRisksList.push(risk);
                }
            });
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },

        toolTip(risk: any, key: any, columnValue: any, index: any, e: any) {
            if (key == 'severity') {
                 let findVal = this.dbSeverity.find((obj:any)=>{return obj.value == columnValue})

                if(findVal != undefined){
                    this.toolTipText = findVal.key
                }else{
                    this.toolTipText = columnValue
                }

                let obj:any = {
                    tipText : this.toolTipText
                }
                emitter.emit('activiteToolTip',obj)
            } else {
                this.handleTextTooltip(e, key, columnValue, index);
            }
        },
        handleTextTooltip(e: any, key: any, columnValue: any, index: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                let obj:any = {
                    tipText : this.toolTipText
                }

                emitter.emit('activiteToolTip',obj)
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        async createAssesmentTask() {
            try {
                let payload = [];
                this.checkedItems = this.checkedItems.filter((x: any) => !this.assesmentlist.includes(x));

                let found = false;
                for (var i = 0; i < this.checkedItems.length; i++) {
                    for (var j = 0; j < this.systemAssesmentList.length; j++) {
                        if (this.systemAssesmentList[j]._id == this.checkedItems[i]) {
                            delete this.systemAssesmentList[j]._id;
                            this.systemAssesmentList[j].riskId = this.riskId;
                            this.systemAssesmentList[j].systemId = this.$route.params.id;
                            this.systemAssesmentList[j].statusCode = 10401;
                            payload.push({
                                systemId: this.systemAssesmentList[j].systemId,
                                riskId: this.systemAssesmentList[j].riskId,
                                statusCode: this.systemAssesmentList[j].statusCode,
                                createdAt: new Date(),
                                createdBy: this.userInfo._id,
                                updatedStatus: 10401,
                                tier: 3,
                                riskOwner: this.userInfo._id
                            });
                            found = true;
                            break;
                        }
                        if (found) {
                            break;
                        }
                    }
                }

                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/create/risks`, payload).then((result: any) => {
                    this.checkedItems = [];
                    toast.info('created', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            await this.getOrgSystemRisks();
            await this.getRisksDiscoveryForSystem();
        },

        onCrossbtn() {
            this.openRiskPopup = false;
            this.addingRisk = false;
            this.applyPopup = false;
            this.mappedSystemsOptions = [];
            this.mainSystem = [];
            Object.keys(this.applyObj).forEach((prop) => {
                Array.isArray(this.applyObj[prop]) ? (this.applyObj[prop] = []) : (this.applyObj[prop] = '');
            });

            Object.keys(this.riskThreatData).forEach((prop) => {
                Array.isArray(this.riskThreatData[prop]) ? (this.riskThreatData[prop] = []) : (this.riskThreatData[prop] = '');
            });
            this.displayErrorMessageOfRisk = false
            this.displayErrorMessageOfThreat = false
            this.displayErrorMessageOfVulnerability = false
            this.v$.$reset();
        },
        cancelAceeptResetBtn() {
            this.applyObj.comments = '';
        },
        groupBy(arr: any) {
            return arr.reduce((group: any, product: any) => {
                const { informationCategory } = product;
                group[informationCategory] = group[informationCategory] ?? [];
                group[informationCategory].push(product);
                return group;
            }, {});
        },
        async getInformations() {
            this.infoTableRow = [];
            this.optionsList = [];
            // await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/system/${this.$route.params.id}/mappedinformation`).then(async (result: any) => {
            //     this.rowData = result.data;
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall?org=true`).then(async (result: any) => {
                result.data.forEach((info: any) => {
                    this.optionsList.push(info);
                });
            });
            // await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall`).then((result: any) => {

            //     result.data.forEach((info: any) => {
            //         this.optionsList.push(info);
            //     });
            // });
            this.optionsList.forEach((info: any) => {
                !this.infoCategoryList.includes(info.informationCategory) ? this.infoCategoryList.push(info.informationCategory) : '';
                // (!this.infoList.includes(info.information)) ? this.infoList.push(info.information) : '';
            });

            this.groupByInfos = this.groupBy(this.optionsList);

            if (this.optionsList.length > 0) {
                let present = this.optionsList.filter((obj: any) => {
                    if(this.system.informations)
                    {
                        return this.system.informations.includes(obj._id);
                    }
                    else{
                        return false;
                    }
                });

                present.forEach((information: any) => {
                    information.entityId = 20007;
                    this.categories.push(information.informationCategory);
                    this.infoTableRow.push({
                        entityId: information.entityId,
                        _id: information._id,
                        category: information.informationCategory,
                        information: information.information,
                        informationOwner: information.informationOwner,
                        confidentiality: information.confidentiality,
                        integrity: information.integrity,
                        availability: information.availability,
                        securityCategory: information.securityCategory
                    });
                });
            }
            // });
        },
        async getSystemInformation() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/system/${this.$route.params.id}/edit`).then((result: any) => {
                this.system = result.data[0];
                
                this.system.ownedBy=this.businessEntityList.find((obj:any)=>{return this.system.ownedBy == obj.value});
                this.system.managedBy=this.managedByOptions.find((obj:any)=>{return this.system.managedBy == obj.value})
                this.system.owner = this.optionsUserArray.find((obj:any)=>{return obj.value == this.system.owner});

                this.objToBeModified = this.system;
                this.dummySystems = { ...result.data[0] };
            });
        },
        async getallUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
            });
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id: org._id, name: org.organizationName };
                    this.tenants.push(obj);
                    const object = { value: org._id, label: org.organizationName };
                    this.tenantsSelect.push(object);
                });
            });
        },
        async addOrUpdate(obj: any, entityId: number) {
            this.v$.system.$touch();
            let Id = obj._id;
            if (!this.v$.system.$invalid) {
                if (Id) {
                    delete obj._id;
                    obj.updatedAt = new Date();
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/update/${Id}/${entityId}`, obj).then((result: any) => {
                        toast.info('updated', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    });
                }
                this.systemData = [];
                this.getSystemInformation();
            } else {
                toast.error(`cannot save`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async getOrgSystemRisks() {
            this.riskList = [];
            this.rowData = [];
            try{
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/system/${this.$route.params.id}/risks/get`).then((result: any) => {
                this.rowData = result.data;
                this.rowData.forEach((risk: any) => {
                    this.openList = risk;

                    let condition1 = Object.keys(risk).includes('residual') && risk['residual'].length > 0;
                    let averageImpact;
                    let impact;
                    let severity;

                    if (condition1 == true) {
                        let lengthOfArray = risk['residual'].length;
                        let indexObj = risk['residual'][lengthOfArray - 1];

                        if (indexObj.isActive == true) {
                            averageImpact = this.average([indexObj['business'], indexObj['operations'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);

                            risk.business = indexObj['business'];
                            risk.operations = indexObj['operations'];
                            risk.legal = indexObj['legal'];
                            risk.financial = indexObj['financial'];
                            risk.likelihood = indexObj['likelihood'];
                        } else {
                            averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    } else if (condition1 == false) {
                        averageImpact = this.average([this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    risk.overallImpact = impact;
                    risk.severity = severity;

                    if (!Object.keys(risk).includes('updatedAt') && risk.updatedAt != '') {
                        risk.updatedAt = '';
                    }
                    risk.riskOwner = this.optionsUserArray.find((value: any)=>{return value.value == risk.riskOwner});
                    risk.riskOwnerName = risk.riskOwner != undefined ? risk.riskOwner.label : 'N/A'
                    this.riskList.push(risk);
                });
            });
            this.paginationObj = [...this.riskList];
}

            catch(error:any) {
        console.error('GET request error:', error.response ? error.response.data : error.message);
    };
          
        },
        async createTaskForAction(applyObj: any, actionCode: any) {
            this.clickOnButton = true
            let payload: any = [
                {
                    systems: applyObj.systems,
                    mappedRisk: this.mainRisk.mappedRisk,
                    createdAt: new Date(),
                    createdBy: this.userInfo._id,
                    action: actionCode
                }
            ];
            payload[0].comments = applyObj.comments;
            await this.createWorkFlowTask(payload);

            Object.keys(this.applyObj).forEach((prop) => {
                Array.isArray(this.applyObj[prop]) ? (this.applyObj[prop] = []) : (this.applyObj[prop] = '');
            });
            await this.getAllTasks();
            await this.getOrgSystemRisks();

            this.mainSystem = [];
        },
        async createActionForRisk(payload: any) {
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/accept`, payload).then((result: any) => {
                    // this.systemAssesmentList = result.data;
                    // toast.info('created', {
                    //     timeout: 1000,
                    //     closeOnClick: true,
                    //     closeButton: 'button',
                    //     icon: true
                    // });
                });
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async createWorkFlowTask(payload: any) {
            // payload.systems = payload.systems.value;
            console.log("payload",payload[0].systems)
            try {
                payload[0].tier = 3;
                if (Object.keys(payload[0]).includes('insertedIds')) {
                    delete payload[0].insertedIds;
                }
                if (Object.keys(payload[0]).includes('residual')) {
                    delete payload[0].residual;
                }
                await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/task`, payload).then((result: any) => {
                    this.insertedIds = result.data;
                    toast.info('Task Created', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                payload[0].insertedIds = this.insertedIds;
                await this.createActionForRisk(payload);
                this.applyPopup = false;
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            
            } finally {
                this.clickOnButton = false
            }
        },
        async getAllTasks() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.tasksData = result.data;
            });
        },
        async getBusinessEntities() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                result.data.map((obj: any) => {
                    var mainName = this.tenantsSelect.find((tenant: any) => {
                        return tenant.value == obj.entityName;
                    });
                    if (mainName == undefined && !this.managedByOptions.includes(obj.entityName)) {
                        this.managedByOptions.push({label:obj.entityName,value:obj._id});
                        this.businessEntityList.push({ label: obj.entityName, value: obj._id });
                    } else if (mainName != undefined && !this.managedByOptions.includes(mainName.label)) {
                        this.managedByOptions.push(mainName.label);
                        this.businessEntityList.push(mainName.label);
                    }
                });
            });
        },
        async getThirdParties() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20003}`).then((result: any) => {
                result.data.map((obj: any) => {
                    if (!this.managedByOptions.includes(obj.name)) {
                        this.managedByOptions.push({label:obj.name,value:obj._id});
                    }
                });
            });
        },
        async toggleTabs(navIndex: any) {
            this.dataLoading = true;

            this.index = navIndex.id;
            this.selectedIndex = navIndex.id;
            sessionStorage.setItem('selectedTab', navIndex.id);
            this.selectedTabObj = navIndex;
            this.infoTableRow = [];
            console.log("navIndex", navIndex)
            if (navIndex.id == 0) {
                await this.getOrgSystemRisks();
                this.navIndexNums.index = 0;
                this.navIndexNums.name = 'Risks';
                emitter.emit('visible', false);
                this.showFilters = false;
            } else if (navIndex.id == 1) {
                await this.getSystemInformation();
                await this.getInformations();
                this.navIndexNums.index = 1;
                this.navIndexNums.name = 'Information';
                emitter.emit('visible', false);
                this.showFilters = false;
            } else if (navIndex.id == 2) {
                await this.getSystemInformation();
                this.navIndexNums.index = 2;
                this.navIndexNums.name = 'Properties';

                // this.changeCountry(this.system.country);
                // this.changeState(this.system.state);
            } else if (navIndex.id == 3 && this.userInfo.currentRole == 10207) {
                this.discovery = true;
                await this.getRisksDiscoveryForSystem();
                this.checkedItems = [];
                this.navIndexNums.index = 3;
                this.navIndexNums.name = 'Risk Discovery';
                emitter.emit('visible', false);
                this.showFilters = false;
                this.loadAndDisplayTable();
            }

            this.dataLoading = false
        },
        loadAndDisplayTable() {
            setTimeout(() => {
                this.discovery = false;
            }, 300);
        },

        showPriorityPopUpAction(data: any) {
            this.mainRisk = { ...data };
            this.riskObjCopy = { ...data };
            this.showPriorityPopUp = true;

            this.riskPriorityShow.risk = data.risk;
            this.riskPriorityShow.priority = data.priority;
            this.riskPriorityShow.justification = '';

            this.priorityDummy.risk = data.risk;
            this.priorityDummy.priority = data.priority;
            this.priorityDummy.justification = '';
        },

        closePriorityPopup() {
            this.resetRiskPriority();
            this.showPriorityPopUp = false;
        },

        cancelPriorityBtn() {
            this.riskPriorityShow.risk = this.priorityDummy.risk;
            this.riskPriorityShow.priority = this.priorityDummy.priority;
            this.riskPriorityShow.justification = '';
            this.priorityDummy.justification = '';
            this.v$.$reset();
        },

        async addOrUpdatePriority() {
            console.log('add clicked');
            this.v$.$touch();
            if (!this.v$.riskPriorityShow.$invalid) {
                this.clickOnButton = true
                this.mainRisk.priority = this.riskPriorityShow.priority;
                this.mainRisk.priorityComments = this.riskPriorityShow.justification;

                let riskData = { ...this.mainRisk };

                let oldObj: any = {};
                let newObj: any = {};
                Object.keys(riskData).map((key: any) => {
                    if (riskData[key] != this.riskObjCopy[key] && key != 'updatedValues' && key != 'priorityComments') {
                        oldObj[key] = this.riskObjCopy[key];
                        newObj[key] = riskData[key];
                    }
                });
                riskData.updatedValues = { oldRisk: [], newRisk: [] };
                riskData.updatedValues.oldRisk.push(oldObj);
                riskData.updatedValues.newRisk.push(newObj);

                this.risk = [];

                this.risk.push(riskData);
                //updatingRisk
                await this.updateRisk();

                this.v$.$reset();
                this.mainRisk = {};
            } else {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },

        resetRiskPriority() {
            this.riskPriorityShow.risk = '';
            this.riskPriorityShow.priority = null;
            this.riskPriorityShow.justification = '';
            // this.riskPriorityShow._id=null;
            this.priorityDummy.risk = '';
            this.priorityDummy.priority = null;
            this.priorityDummy.justification = '';
            // this.priorityDummy._id=null;
            this.v$.$reset();
        },
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
        handleResize() {
            this.isSmallScreen = window.innerWidth <= 768;
            if (this.isSmallScreen) {
                this.calculateTabsPerGroup();
                if (this.currentGroupIndex > this.totalGroups - 1) {
                    this.scrollTabs('left');
                }
            } else {
                this.tabsPerGroup = this.calculateTabsForLargeScreen();
                this.updateVisibleTabs();
            }
            this.updateButtonStates();
        },
        calculateTabsPerGroup() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            let sumWidth = 0;
            let count = 0;
            const tabs = this.carouselHead;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            this.tabsPerGroup = count;
            this.updateVisibleTabs();
        },
        calculateTabsForLargeScreen() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            const tabs = this.carouselHead;
            let sumWidth = 0;
            let count = 0;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            return count;
        },
        getTabWidth(tab: any) {
            // Simulate tab width calculation; replace with your actual logic
            const dummyDiv = document.createElement('div');
            dummyDiv.innerHTML = tab.displayName;
            dummyDiv.style.display = 'inline-block';
            dummyDiv.style.visibility = 'hidden';
            document.body.appendChild(dummyDiv);
            const width = dummyDiv.clientWidth;
            document.body.removeChild(dummyDiv);
            return width + 24; // Adjust padding or margin as needed
        },
        async updateVisibleTabs() {
            if (!this.isSmallScreen) {
                this.visibleTabs = [...this.carouselHead];
            } else {
                const startIdx = this.currentGroupIndex * this.tabsPerGroup;
                const endIdx = startIdx + this.tabsPerGroup;
                this.visibleTabs = this.carouselHead.slice(startIdx, endIdx);
                if (this.visibleTabs) {
                    let savedTabKey: any = sessionStorage.getItem('selectedTab');
                    if (savedTabKey) {
                        savedTabKey = parseInt(savedTabKey)
                        const savedTab = this.visibleTabs.find((tab: any) => tab.id == savedTabKey);
                        if (savedTab) {
                            await this.toggleTabs(savedTab);
                        }else{
                            this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
                            this.selectedIndex = 0;
                            this.navIndexNums = { index: 0, name: 'Risks' };
                        }
                    }
                    else{
                        this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
                        this.navIndexNums = { index: 0, name: 'Risks' };
                        this.selectedIndex = 0;
                        await this.toggleTabs(this.selectedTabObj);
                    }
                }
                this.totalGroups = Math.ceil(this.carouselHead.length / this.tabsPerGroup);
            }
            this.updateButtonStates();
        },
        scrollTabs(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    const scrollAmount = this.calculateScrollAmount(direction);
                    if (direction === 'left') {
                        tabContainer.scrollLeft -= scrollAmount;
                    } else if (direction === 'right') {
                        tabContainer.scrollLeft += scrollAmount;
                    }
                    this.updateButtonStates();
                } else {
                    if (direction === 'left' && this.currentGroupIndex > 0) {
                        this.currentGroupIndex--;
                    } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                        this.currentGroupIndex++;
                    }
                    this.updateVisibleTabs();
                    this.updateButtonStates(); // Update button states after changing currentGroupIndex
                }
            }
        },
        calculateScrollAmount(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            const visibleTabs = this.visibleTabs;
            let sumWidth = 0;
            let scrollAmount = 0;

            if (direction === 'left') {
                for (let i = visibleTabs.length - 1; i >= 0; i--) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            } else if (direction === 'right') {
                for (let i = 0; i < visibleTabs.length; i++) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            }
            return scrollAmount;
        },
        
        handleScroll() {
            this.updateButtonStates();
        }
    },
    async created() {
        this.getallUsers();
        this.countriesStatesCities.map((obj: any) => {
            this.countriesList.push(obj.CountryName);
        });
        // this.permissions = this.userInfo.permissions[0].resources.filter((userPer: any) => {
        //     return userPer.id == 20008;
        // });
        // this.permissions = this.permissions[0].permissions;
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab')
    }
});
</script>

