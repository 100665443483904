<template >
    <div>
        <div class="flexcontainer pt-3 pl-5 pr-5">
            <div class="row w-full justify-between flex">
                <div class="title flex items-center">
                    <h1>Process Master</h1>
                </div>
                <div class="flex items-center">
                    <button class="btnprimary ml-3" @click="importDoc()">Import</button>
                    <input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" />
                    <button class="btnprimary ml-3 flex items-center" @click="showPopupByAdd">Add</button>
                    <button class="ml-3 flex" @click="filterOpen" :class="displayListInTable.length == 0?'btndisabled pointer-events-none':'filterButton'">
                        Filters
                        <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                    </button>
                </div>
            </div>
            <div class="mt-3 text-xs">
                <div>
                Risk Master has been developed to help risk managers, claim adjusters, attorneys and safety managers to process, manage and analyze critical claims data. This data includes: general liability, workers' compensation, automotive liability, first reports of injury, reporting, policies, actuarial reporting and integrated disability management.This course helps you to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
                </div>
            </div>
            <div class="row">
                <newTable :tableRowData="displayListInTable" :headersData="headers" :openPopup="true" :loadData="dataLoading"></newTable>
                <div v-if="toolTipActive">
                    <tool-tip :tipText="toolTipText"></tool-tip>
                </div>
            </div>

            <popup v-if="showRiskMasterPopup" :popUp="showRiskMasterPopup">
                <template v-slot:header>
                    <div class="flex items-center justify-between popupheadingcontainer rounded">
                        <h1>Risk Master</h1>

                        <button class="float-right text-3xl" @click="onCrossbtn()">
                            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                        </button>
                    </div>
                </template>

                <div class="popup-business">
                    <div>
                        <div>
                            <div>
                                <div class="statuscontainer popupbodycontainer">
                                    <div class="formcontainer flex flex-col">
                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="w-full">
                                                <label for="Risk" class="controllabel">Risk</label> 

                                                <textarea
                                                    class="inputboxstyling"
                                                    placeholder="Risk"
                                                    @input ="restrictCharacters('Risk')"
                                                    v-model.trim="riskThreatData.risk"
                                                    :class="{
                                                        'is-invalid': (v$.riskThreatData.risk.$error||displayErrorMessageOfRisk)
                                                    }"
                                                />

                                                <div v-if="v$.riskThreatData.risk.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.risk.required.$invalid">Required</div>
                                                </div>
                                                <div v-else-if="displayErrorMessageOfRisk == true">
                                                    <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="w-full">
                                                <label for="Risk" class="controllabel">Threat</label> 

                                                <textarea
                                                    class="inputboxstyling"
                                                    placeholder="Threat"
                                                    @input ="restrictCharacters('Threat')"
                                                    v-model.trim="riskThreatData.threatEvent"
                                                    :class="{
                                                        'is-invalid': (v$.riskThreatData.threatEvent.$error||displayErrorMessageOfThreat)
                                                    }"
                                                />

                                                <div v-if="v$.riskThreatData.threatEvent.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.threatEvent.required.$invalid">Required</div>
                                                </div>
                                                <div v-else-if="displayErrorMessageOfThreat == true">
                                                    <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="w-full">
                                                <div>
                                                    <label class="controllabel">Risk Category</label>

                                                    <!-- <dropdown :dataprops="selectedCategory" :inputValue="riskThreatData.riskCategory" :placeholderValue="'Risk Category'" @name="getName" :error="v$.riskThreatData.riskCategory.$error"></dropdown> -->
                                                    <Multiselect
                                                        v-model.trim="riskThreatData.riskCategory"
                                                        :options="searchRiskCategoryList"
                                                        @search-change="asyncRiskCategoryDataFind"
                                                        @select="selectedRiskCategoryOption"
                                                        :multiple="false"
                                                        :searchable="true"
                                                        :hide-selected="true"
                                                        open-direction="bottom"
                                                        :close-on-select="true"
                                                        :clear-on-select="true"
                                                        :hideArrow="false"
                                                        placeholder="Risk Category"
                                                        deselectLabel=""
                                                        :showNoOptions="false"
                                                        selectLabel=""
                                                        class="custom-multiselect userSelect"
                                                        :class="{
												            'is-invalid redBorder': v$.riskThreatData.riskCategory.$error,
                                                            'has-options': searchRiskCategoryList && searchRiskCategoryList.length > 0,
                                                            'no-options': !searchRiskCategoryList.length &&  searchValue !== '',
                                                        }"
                                                        >
                                                        <template v-slot:noResult>
                                                            <span class="text-xs">No Categories Found</span>
                                                        </template>
                                                    </Multiselect>
                                                    <div v-if="v$.riskThreatData.riskCategory.$error" class="text-red-500">
                                                        <div class="error-text text-xs" v-if="v$.riskThreatData.riskCategory.required.$invalid">Required</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="w-6/12">
                                                <div>
                                                    <label class="controllabel">Threat Category</label>
                                                    <Multiselect
                                                        v-model.trim="riskThreatData.threatCategory"
                                                        :options="searchThreatCategoryList"
                                                        @search-change="asyncthreatCategoryDataFind"
                                                        @select="selectedOption"
                                                        :multiple="false"
                                                        :searchable="true"
                                                        :hide-selected="true"
                                                        open-direction="bottom"
                                                        :close-on-select="true"
                                                        :clear-on-select="true"
                                                        :hideArrow="false"
                                                        placeholder="Threat Category"
                                                        deselectLabel=""
                                                        :showNoOptions="false"
                                                        selectLabel=""
                                                        class="custom-multiselect userSelect"
                                                        :class="{
                                                            'has-options': searchThreatCategoryList && searchThreatCategoryList.length > 0,
                                                            'no-options': !searchThreatCategoryList.length &&  searchValue !== '',
                                                        }"
                                                    >
                                                        <template v-slot:noResult>
                                                            <span class="text-xs">No Categories Found</span>
                                                        </template>
                                                    </Multiselect>
                                                    <!-- <dropdown :dataprops="selectedCategory" :inputValue="riskThreatData.threatCategory" :placeholderValue="'Threat Category'" @name="getName" ></dropdown> -->
                                                </div>
                                            </div>
                                            <div class="w-6/12 leftspacing">
                                                <div>
                                                    <label class="controllabel">Threat Source</label>
                                                    <Multiselect
                                                        v-model.trim="riskThreatData.threatSource"
                                                        :options="searchThreatSourceList"
                                                        @search-change="asyncthreatSourceDataFind"
                                                        @select="selectedThreatSourceOption"
                                                        :multiple="false"
                                                        :searchable="true"
                                                        :hide-selected="true"
                                                        open-direction="bottom"
                                                        :close-on-select="true"
                                                        :clear-on-select="true"
                                                        :hideArrow="false"
                                                        placeholder="Threat Source"
                                                        deselectLabel=""
                                                        :showNoOptions="false"
                                                        selectLabel=""
                                                        class="custom-multiselect userSelect"
                                                        :class="{
                                                            'has-options': searchThreatSourceList && searchThreatSourceList.length > 0,
                                                            'no-options': !searchThreatSourceList.length &&  searchValue !== '',
                                                        }"
                                                        >
                                                        <template v-slot:noResult>
                                                            <span class="text-xs">No Source Found</span>
                                                        </template>
                                                    </Multiselect>
                                                    <!-- <dropdown :dataprops="selectedCategory" :inputValue="riskThreatData.threatSource" :placeholderValue="'Threat Source'" @name="getName"></dropdown> -->
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="w-full">
                                                <label for="Risk" class="controllabel">Vulnerability</label> 

                                                <textarea
                                                    class="inputboxstyling"
                                                    placeholder="Vulnerability"
                                                    @input ="restrictCharacters('Vulnerability')"
                                                    v-model.trim="riskThreatData.vulnerability"
                                                    :class="{
                                                        'is-invalid': (v$.riskThreatData.vulnerability.$error||displayErrorMessageOfVulnerability)
                                                    }"
                                                />

                                                <div v-if="v$.riskThreatData.vulnerability.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.vulnerability.required.$invalid">Required</div>
                                                </div>
                                                <div v-else-if="displayErrorMessageOfVulnerability == true">
                                                    <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <h1 class="upperspacing">Risk Analysis</h1>
                                        <div class="border-b pt-1 border-solid border-lightgrey"></div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="col-md-6 w-6/12">
                                                <label class="controllabel">Strategic</label> 

                                                <!-- <input type="text" class="inputboxstyling" placeholder="Business" v-model="riskThreatData.Business" /> -->

                                                <select
                                                    class="inputboxstylingForSelect"
                                                    v-model="riskThreatData.business"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.business.$error,'inputboxstylingForSelectAtPopup': riskThreatData.business === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.business !== ''
                                                    }"
                                                    @change="calculateOverallImpact()"
                                                >
                                                    <option value='' selected disabled hidden>Please select one</option>

                                                    <option class="optionColors" v-for="option in dbBussiness" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.business.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.business.required.$invalid">Required</div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 w-6/12 leftspacing">
                                                <label class="controllabel">Financial</label> 

                                                <select
                                                    class="inputboxstylingForSelect"
                                                    v-model="riskThreatData.financial"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.financial.$error,'inputboxstylingForSelectAtPopup': riskThreatData.financial === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.financial !== ''
                                                    }"
                                                    @change="calculateOverallImpact()"
                                                >
                                                    <option value="" selected disabled hidden>Please select one</option>

                                                    <option class="optionColors" v-for="option in dbFinancial" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.financial.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.financial.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="col-md-6 w-6/12">
                                                <label class="controllabel">Operations</label> 

                                                <!-- <input type="text" class="inputboxstyling" placeholder="Operations" v-model="riskThreatData.Operations" /> -->

                                                <select
                                                    class="inputboxstylingForSelect"
                                                    v-model="riskThreatData.operations"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.operations.$error,'inputboxstylingForSelectAtPopup': riskThreatData.operations === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.operations !== ''
                                                    }"
                                                    @change="calculateOverallImpact()"
                                                >
                                                    <option value="" selected disabled hidden>Please select one</option>

                                                    <option class="optionColors" v-for="option in dbOperations" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.operations.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.operations.required.$invalid">Required</div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 w-6/12 leftspacing">
                                                <label class="controllabel">Compliance</label> 

                                                <select
                                                    class="inputboxstylingForSelect"
                                                    v-model="riskThreatData.legal"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.legal.$error,'inputboxstylingForSelectAtPopup': riskThreatData.legal === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.legal !== ''
                                                    }"
                                                    @change="calculateOverallImpact()"
                                                >
                                                    <option value="" selected disabled hidden>Please select one</option>

                                                    <option class="optionColors" v-for="option in dbLegal" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.legal.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.legal.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <h1 class="upperspacing">Impact Analysis</h1>
                                        <div class="border-b pt-1 border-solid border-lightgrey"></div>

                                        <div class="row flex flex-row w-full upperspacing">
                                                <div class="w-1/2">
                                                <label class="controllabel">Likelihood</label> 

                                                <select
                                                    class="inputboxstylingForSelect"
                                                    v-model="riskThreatData.likelihood"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.likelihood.$error,'inputboxstylingForSelectAtPopup': riskThreatData.likelihood === '','stylingForInputboxstylingForSelectAtPopup': riskThreatData.likelihood !== ''
                                                    }"
                                                >
                                                    <option value="" selected disabled hidden>Please select one</option>

                                                    <option class="optionColors" v-for="option in dbLikelihood" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.likelihood.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.riskThreatData.likelihood.required.$invalid">Required</div>
                                                </div>
                                                </div>
                                                <div class="w-1/2 leftspacing">
                                                    <label class="controllabel">Overall Impact</label>
                                                    <select
                                                        class="inputboxstyling"
                                                        v-model="riskThreatData.overallImpact"
                                                        disabled
                                                    >
                                                        <option value="" selected disabled hidden>Please select one</option>

                                                        <option v-for="option in dbOverAllImpact" :key="option" :value="option.value">
                                                            {{ option.key }}
                                                        </option>
                                                    </select>
                                                </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                            <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn()">Cancel</button>
                            <button :disabled="clickOnButton" v-if="riskThreatData._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdatethreat(riskThreatData)">Update</button>
                            <button :disabled="clickOnButton" v-else :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdatethreat(riskThreatData)">Save</button>
                        </div>
                    </div>
                </div>
            </popup>
        </div>
    </div>
</template>

<style scoped>
.popup-business {
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 0px;
}
.redBorder {
	border: 1px solid red !important;
	border-radius: 5px !important;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import store from '../../store';
import { emitter, toast } from '@/main';
import popup from '@/components/popup.vue';
import dropdown from '@/components/dropdown.vue';
import newTable from '@/components/newTable.vue';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            mandatoryKeys: ['risk', 'threatCategory', 'threatSource', 'threatEvent', 'vulnerability', 'business', 'operations', 'legal', 'financial', 'likelihood', 'riskCategory'],
            fullKeys: ['risk', 'threatCategory', 'threatSource', 'threatEvent', 'vulnerability', 'business', 'operations', 'legal', 'financial', 'likelihood', 'riskCategory','riskOwner'],
            editpop: false,
            showFilters: false,
            dummyList: [],
            riskList: [],
            optionsUserArray: [],
            orgUsersList:[],
            displayListInTable: [],
            scalesInfo: [],
            likelihoodMapping: [],
            searchValue: '',
            severityMapping: [],
            impactMapping: [],
            riskCategoryArray: [],
            clickOnButton: false,
            searchRiskCategoryList: [],
            showRiskMasterPopup: false,
            byAdd: false,
            byRow: false,
            selectedCategory: [],
            autoSuggestion: false,
            riskCopy: null,
            optionsList:[],
            headers:[ "entityId","_id","severity","risk","category","impact","likelihood"],
            riskThreatData: {
                risk: '',
                threatCategory: '',
                threatSource: '',
                threatEvent: '',
                vulnerability: '',
                business: '',
                operations: '',
                legal: '',
                financial: '',
                likelihood: '',
                riskCategory: '',
                overallImpact:''
            },
            notImportedRiskList:[],

            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
            threatCategoryArray:[],
            threatSourceArray:[],
			displayErrorMessageOfRisk : false,
			displayErrorMessageOfThreat : false,
			displayErrorMessageOfVulnerability : false,
            searchThreatSourceList:[],
            searchThreatCategoryList:[],
        };
    },
    validations() {
        return {
            riskThreatData: {
                risk: { required },
                riskCategory: { required },
                threatEvent: { required },
                vulnerability: { required },
                business: { required },
                operations: { required },
                legal: { required },
                financial: { required },
                likelihood: { required },
            }
        };
    },
    components: {
		Multiselect,
        popup,
        dropdown,
        newTable,
        toolTip,
    },
    computed: {
        ...mapGetters({dbSeverity:'dbSeverity',dbLikelihood:'dbLikelihood',dbBussiness:'dbBussiness',
            dbOrganization:'dbOrganization',dbOperations:'dbOperations',dbLegal:'dbLegal',
            dbFinancial:'dbFinancial',dbTechnical:'dbTechnical',dbOverAllImpact:'dbOverAllImpact'
        }),
        disableStatus: function (): any {
            let result;
            if (this.editpop) {
                let objCopy = Object.assign({}, this.riskThreatData);

                if (_.isEqual(objCopy, this.riskCopy)) {
                    result = false;
                } else {
                    result = true;
                }
            } else {
                let valueArray: any = [];
                Object.values(this.riskThreatData).forEach((value: any) => {
                    valueArray.push(value);
                });
                for (let value of valueArray) {
                    if (value != '') {
                        result = true;
                    }
                }
            }
            return result;
        },
        e: function (): any {
            return false;
        }
    },

    async mounted() {
        this.dataLoading = true;

        this.scalesInfo = store.getters.scalesInfo;

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        if (this.scalesInfo.length == 0) {
            await store.dispatch('getScalesInfo').then(async (res: any) => {
                this.scalesInfo = store.getters.scalesInfo;
                this.likelihoodMapping = this.scalesInfo.filter((scale: any) => {
                    return scale.entity == 'Likelihood';
                });
                this.severityMapping = this.scalesInfo.filter((scale: any) => {
                    return scale.entity == 'Likelihood';
                });
                this.impactMapping = this.scalesInfo.filter((scale: any) => {
                    return scale.entity == 'Impact';
                });
                await this.getRisksList();
            });
        } else {
            this.scalesInfo = store.getters.scalesInfo;
            this.likelihoodMapping = this.scalesInfo.filter((scale: any) => {
                return scale.entity == 'Likelihood';
            });
            this.severityMapping = this.scalesInfo.filter((scale: any) => {
                return scale.entity == 'Likelihood';
            });
            this.impactMapping = this.scalesInfo.filter((scale: any) => {
                return scale.entity == 'Impact';
            });
            await this.getRisksList();
        }
        emitter.off('showData123');
        emitter.on('showData123', (riskData: any) => {
            let comingId = riskData.entityData._id;
            let showObj = this.riskList.find((obj:any)=>{
                return (obj._id === comingId)
            });
            this.showEditPopup(showObj);
        });

        this.dataLoading = false;
    },

    methods: {
         async calculateOverallImpact(){
            let a1 = this.riskThreatData.business == '' ? 0 : this.riskThreatData.business;
            let a2 = this.riskThreatData.operations == '' ? 0 : this.riskThreatData.operations;
            let a3 = this.riskThreatData.legal == '' ? 0 : this.riskThreatData.legal;
            let a4 = this.riskThreatData.financial == '' ? 0 : this.riskThreatData.financial;

            let arr = [a1, a2, a3, a4]

            let avg =  arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
            this.riskThreatData.overallImpact = Math.max(Math.round(avg), 1);
        },
        restrictCharacters(code: any){
            if(code === 'Threat'){
                if(this.riskThreatData.threatEvent.length > 300){
                this.displayErrorMessageOfThreat = true;
                }else if(this.riskThreatData.threatEvent.length < 300 || this.riskThreatData.threatEvent.length == 300){
                    this.displayErrorMessageOfThreat = false;
                }
            } else if(code === 'Risk'){
                if(this.riskThreatData.risk.length > 300){
                    this.displayErrorMessageOfRisk = true;
                }else if(this.riskThreatData.risk.length < 300 || this.riskThreatData.risk.length == 300){
                    this.displayErrorMessageOfRisk = false;
                }
            } else if(code === 'Vulnerability'){
                if(this.riskThreatData.vulnerability.length > 300){
                    this.displayErrorMessageOfVulnerability = true;
                }else if(this.riskThreatData.vulnerability.length < 300 || this.riskThreatData.vulnerability.length == 300){
                    this.displayErrorMessageOfVulnerability = false;
                }
            }
            
        },
        showEditPopup(riskData: any) {
            this.showRiskMasterPopup = true;
            this.editpop = true
            this.byRow = true;
            this.byAdd = false;
            this.riskThreatData = { ...riskData };
            this.riskCopy = { ...riskData };
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.riskList;
            } else {
                this.riskList = this.dummyList;
            }
            emitter.emit('visible',this.showFilters)
        },
        fileReader(oEvent: any) {
            var oFile = oEvent.target.files[0];
            var reader = new FileReader();
            reader.onload = async (e: any) => {
                var data = e.target.result;
                data = new Uint8Array(data);
                var workbook = XLSX.read(data, {
                    type: 'array'
                });
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                var jsonArray:any = XLSX.utils.sheet_to_json(worksheet, {
                    raw: true,
                    header: this.fullKeys,
                    range: 1
                });

                let total = jsonArray.length;
                this.riskList = [];

                jsonArray.forEach((jsonObj:any)=>{  
                    let mandatoryCondition = this.mandatoryKeys.every((key: any) => {
                        return jsonObj[key]!='';
                    });
 
                    if (mandatoryCondition) {
                        let findObj = this.notImportedRiskList.find((obj:any)=> {return (jsonObj.risk == obj.risk &&  jsonObj.riskCategory == obj.riskCategory)})
                        if(findObj==undefined){
                            this.riskList.push(jsonObj);
                            this.dummyList.push(jsonObj);
                        }
                    }
                })

                if (this.riskList.length > 0) {
                    toast.info(`Imported ${total} rows, Valid rows: ${this.riskList.length}, Ignored rows: ${total - this.riskList.length}`, {
                        timeout: 3000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });

                    this.riskList.map((riskObj:any)=>{
                        let valuesArray = [ {value:1, label:'very low'}, {value:2, label:'low'}, {value:3, label:'moderate'}, {value:3, label:'medium'}, {value:4, label:'high'}, {value:5, label:'very high'},{value:5, label:'critical'}];

                        let mainbusiness:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.business).toLowerCase()})
                        riskObj.business = mainbusiness.value

                        let mainfinancial:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.financial).toLowerCase()})
                        riskObj.financial = mainfinancial.value

                        let mainlegal:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.legal).toLowerCase()})
                        riskObj.legal = mainlegal.value

                        let mainlikelihood:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.likelihood).toLowerCase()})
                        riskObj.likelihood = mainlikelihood.value

                        let mainoperations:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.operations).toLowerCase()})
                        riskObj.operations = mainoperations.value
                    })
                    await this.addRiskList();
                } else {
                    toast.error('No Rows Imported', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
                
            };
            reader.readAsArrayBuffer(oFile);
        },
        importDoc() {
            this.$refs.file.click();
        },
        showPopupByAdd() {
            this.showRiskMasterPopup = true;
            this.riskThreatData =  {
                risk: '',
                threatCategory: '',
                threatSource: '',
                threatEvent: '',
                vulnerability: '',
                business: '',
                operations: '',
                legal: '',
                financial: '',
                likelihood: '',
                riskCategory: '',
                overallImpact:''
            },
            this.byAdd = true;
            this.byRow = false;
        },
        onCancelbtn() {
            if (this.byAdd) {
                for (const key in this.riskThreatData) {
                    this.riskThreatData[key] = '';
                }
                this.displayErrorMessageOfRisk = false
                this.displayErrorMessageOfThreat = false
                this.displayErrorMessageOfVulnerability = false
            } else if (this.byRow) {
                this.riskThreatData = { ...this.riskCopy };
                this.displayErrorMessageOfRisk = false
                this.displayErrorMessageOfThreat = false
                this.displayErrorMessageOfVulnerability = false
            }
            this.v$.$reset();
        },
        onCrossbtn() {
            this.showRiskMasterPopup = false;
             Object.keys(this.riskThreatData).forEach((prop) => {
                this.riskThreatData[prop] = '';
            });       
            this.displayErrorMessageOfRisk = false
            this.displayErrorMessageOfThreat = false
            this.displayErrorMessageOfVulnerability = false
            this.v$.$reset();
            this.editpop = false;
        },
        selectedRiskCategoryOption(opt:any){
			let isNewOption = !this.riskCategoryArray.includes(opt);
			isNewOption==true ? this.riskCategoryArray.push(opt) : ''
		},
		asyncRiskCategoryDataFind(query:any) {
            this.searchValue = query
			query = query.trim();
			if (query == '') {
				this.searchRiskCategoryList = [];
			} else {
				console.log("this.riskCategoryArray",this.riskCategoryArray)
				this.searchRiskCategoryList = this.riskCategoryArray.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.riskCategoryArray.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.searchRiskCategoryList.push(query);
				}
			}
		},
        selectedThreatSourceOption(opt:any){
			let isNewOption = !this.threatSourceArray.includes(opt);
			isNewOption==true ? this.threatSourceArray.push(opt) : ''
		},
		asyncthreatSourceDataFind(query:any) {
            this.searchValue = query
			query = query.trim();
			if (query == '') {
				this.searchThreatSourceList = [];
			} else {
				console.log("this.threatSourceArray",this.threatSourceArray)
				this.searchThreatSourceList = this.threatSourceArray.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.threatSourceArray.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.searchThreatSourceList.push(query);
				}
			}
		},
        selectedOption(opt:any){
			let isNewOption = !this.threatCategoryArray.includes(opt);
			isNewOption==true ? this.threatCategoryArray.push(opt) : ''
		},
		asyncthreatCategoryDataFind(query:any) {
            this.searchValue = query
			query = query.trim();
			if (query == '') {
				this.searchThreatCategoryList = [];
			} else {
				console.log("this.threatCategoryArray",this.threatCategoryArray)
				this.searchThreatCategoryList = this.threatCategoryArray.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.threatCategoryArray.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.searchThreatCategoryList.push(query);
				}
			}
		},
        filteredCategories(filterColumn: any, status: any) {
            this.selectedCategory = [
                ...new Set(
                    Object.values(this.optionsList).map((x: any) => {
                        x[status];

                        return x[status];
                    })
                )
            ].filter((category: any) => {
                return category.toLowerCase().includes(filterColumn.toLowerCase());
            });
        },
        getName(options: any, status: any) {
            let OptionWords = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
            this.filteredCategories(options, OptionWords);
            this.setState(options, OptionWords);
        },
        setState(options: any, status: any) {
            this.riskThreatData[status] = options;
            this.autoSuggestion = false;
        },
        async addOrUpdatethreat(riskThreatdata: any) {
            this.riskList = [];
            this.v$.$touch();
            if (!this.v$.$invalid && this.displayErrorMessageOfRisk == false && this.displayErrorMessageOfThreat == false && this.displayErrorMessageOfVulnerability == false) {
                this.clickOnButton = true
                this.riskList.push(riskThreatdata);

                if (riskThreatdata._id) {
                    await this.updateRiskList();
                } else {
                    await this.addRiskList();
                }
                this.v$.$reset();
            } else {
                console.log("error");
                // toast.error(`error`, {
                //     timeout: 1000,
                //     closeOnClick: true,
                //     closeButton: 'button',
                //     icon: true
                // });
            }
        },
       
        groupBy(arr: any, key: any) {
            return arr.reduce((storage: any, item: any) => {
                if (_.every(this.mandatoryKeys, _.partial(_.has, item))) {
                    var group = item[key];
                    storage[group] = storage[group] || [];
                    storage[group].push(item);
                }
                return storage;
            }, {});
        },

        async addRiskList() {
            let copyOfriskList:any = [...this.riskList]
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/create/process/risks`, copyOfriskList).then((result: any) => {
                    toast.info(`Uploaded succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
            
            this.showRiskMasterPopup = false;
            this.displayListInTable = [];
            await this.getRisksList();
        },

        async updateRiskList() {
            let copyOfriskThreatData :any = {...this.riskThreatData}
            try {
                let riskId = copyOfriskThreatData._id;
                delete copyOfriskThreatData._id;
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/process/${riskId}/update`, copyOfriskThreatData).then((result: any) => {
                    toast.info(`Uploaded succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
                this.showRiskMasterPopup = false;
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }

            this.displayListInTable = [];
            await this.getRisksList();
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        average(arr: any) {
            return arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
        },
        async getallTeamMembers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
			this.orgUsersList=this.orgUsersList.filter((user:any) => {
					return user.isActive==true
				});
				for (var i = 0; i < this.orgUsersList.length; i++) {
				this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
			}
			});
			console.log("this.orgUsersList",this.orgUsersList)
		},
        async getRisksList() {
            this.riskList = [];
            this.optionsList = [];
            this.threatCategoryArray = [];
            this.threatSourceArray = [];
            const result = await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/process/getall`);
            
            result.data.forEach((risk: any) => {
                this.notImportedRiskList.push({...risk});
                risk.entityId = 20008;
                if (risk.operations) {
                    let averageImpact = Math.round(this.average([risk.business, risk.operations, risk.legal, risk.financial]));
                    risk.overallImpact = averageImpact;
                    risk.severity = this.riskSeverity(risk.overallImpact * risk.likelihood);
                    this.riskList.push(risk); 
                    this.optionsList.push(risk);
                }
            });
            
            this.threatCategoryArray = [
					...new Set(
						Object.values(result.data).map((x: any) => {
							x["threatCategory"];
							return x["threatCategory"];
						})
					)
				]
                this.threatSourceArray = [
					...new Set(
						Object.values(result.data).map((x: any) => {
							x["threatSource"];
							return x["threatSource"];
						})
					)
				]
            console.log("assasasa",this.riskList)
            
            const resultArray = this.notImportedRiskList.map((obj:any) => {
                const { _id: removedProperty, ...newObj } = obj;
                return newObj;
            });
            this.notImportedRiskList = [...resultArray];
            this.riskCategoryArray = [
                ...new Set(
                    Object.values(result.data).map((x: any) => {
                        x["riskCategory"];
                        return x["riskCategory"];
                    })
                )
            ]

            this.riskList.forEach((riskRowData: any) => {
                this.displayListInTable.push({
                    entityId: riskRowData.entityId,
                    _id: riskRowData._id,
                    severity: riskRowData.severity,
                    risk: riskRowData.risk,
                    category: riskRowData.riskCategory,
                    impact: riskRowData.overallImpact,
                    likelihood: riskRowData.likelihood
                });
            });
        },
    },

    created() {
        this.getallTeamMembers()
        window.addEventListener('click', (e) => {
            this.autoSuggestion = false;
        });
    }
});
</script>
