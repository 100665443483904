

<template>
    <div class="pt-3 pl-5 pr-5 overflow-x-hidden">
      
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="row w-full justify-between flex" v-if="riskAcceptanceObj.length > 0">
            <div class="title flex items-center">
                <h1 v-if="thisScreen == 'Acceptance'">Risk Acceptance - {{ riskAcceptanceObj[0].risk }}</h1>
                <h1 v-if="thisScreen == 'reset'">Risk Reset - {{ riskAcceptanceObj[0].risk }}</h1>
                <h1 v-if="thisScreen == 'Mitigate'">Risk Mitigate - {{ riskAcceptanceObj[0].risk }}</h1>
                <h1 v-if="thisScreen == 'delete'">Risk Delete - {{ riskAcceptanceObj[0].risk }}</h1>
                <h1 v-if="thisScreen=='priority'">Risk Priority-{{riskAcceptanceObj[0].risk}}</h1>
            </div>
            <div class="flex items-center">
                <button :class="defNotEqual ? 'btnprimary opacity-100' : 'btndisabled pointer-events-none'" class="mr-3" @click="applyInfo()">Apply</button>
                <button :class="defNotEqual ? 'btnprimary opacity-100' : 'btndisabled pointer-events-none'" @click="cancelInfos()">Cancel</button>
                <button class="ml-3 flex" @click="filterOpen" :class="tableRow.length == 0?'btndisabled pointer-events-none':'filterButton'">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-6">
            <div class="text-xs">
                This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
                to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
            </div>
        </div>
        <div class="mt-6">
            <div class="tableCornerRadius bg-white">
                <div class="table-container">
                <table>
                    <thead>
                        <th class="header cursor-pointer th1">
                            <div class="w-8">
                                <input type="checkbox" :disabled="isAllSelected() && thisScreen == 'Acceptance'" class="checkbox hidden cursor-pointer" :checked="isParentSelected()" v-model="selectAll" @change="selectAllOptions($event)" />
                            </div>
                        </th>
                        <th scope="col" v-for="column in columnNames" :key="column" class="bg-white cursor-pointer capitalize" @click="sort(column)" :class="getColumnHeadWidth(column)">
                            <!-- <div> -->
                                <div class="flex" :class="bindClassForColumn(column)">
                                    <div v-if="column == 'orgName'">Organization Name</div>
                                    <div v-else-if="column == 'processName'">Process Name</div>
                                    <div v-else-if="column == 'systemName'">System Name</div>
                                    <div v-else>{{ column }}</div>
                                    <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                                </div>
                            <!-- </div> -->
                        </th>
                        <tr v-if="showFilters">
                            <td></td>
                            <td v-for="name in columnNames" class="bg-white" :key="name">
                                <select v-if="name=='severity'" class="widthForSelect filter-font-size" v-model="filterDummy[name]" :class="filterDummy[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'">
                                    <option value="" selected disabled hidden>Select</option>
                                    <option class="optionColors" v-for="option in dbSeverity" :key="option" :value="option.value">
                                        {{ option.key }}
                                    </option>
                                </select>
                                <select v-else-if="name=='impact'" class="widthForSelect filter-font-size" v-model="filterDummy[name]" :class="filterDummy[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'">
                                    <option value="" selected disabled hidden>Select</option>
                                    <option class="optionColors" v-for="option in dbOverAllImpact" :key="option" :value="option.value">
                                        {{ option.key }}
                                    </option>
                                </select>
                                <select v-else-if="name=='likelihood'" class="widthForSelect filter-font-size" v-model="filterDummy[name]" :class="filterDummy[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'">
                                    <option value="" selected disabled hidden>Select</option>
                                    <option class="optionColors" v-for="option in dbLikelihood" :key="option" :value="option.value">
                                        {{ option.key }}
                                    </option>
                                </select>
                                <select v-else-if="name=='priority'" class="widthForSelect filter-font-size" v-model="filterDummy[name]" :class="filterDummy[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'">
                                    <option value="" selected disabled hidden>Select</option>
                                    <option class="optionColors" v-for="option in dbPriority" :key="option" :value="option.value">
                                        {{ option.key }}
                                    </option>
                                </select>
                                <select v-else-if="name=='status'" class="widthForSelect filter-font-size" v-model="filterDummy[name]" :class="filterDummy[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'">
                                    <option value="" selected disabled hidden>Select</option>
                                    <option class="optionColors" v-for="option in risksStatusValues" :key="option" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                                <input v-else type='text' placeholder="Search" class="searchForInputBoxStyling filter-font-size" v-model="filterDummy[name]"/>
                            </td>
                        </tr>
                    </thead>
                    <tbody v-if="dataLoading == true">
                        <tr class="cursor-pointer rowHeight">
                            <td class="relative h-5" :colspan="columnNames.size">
                                <div class="flex items-center justify-center w-full h-16 skeleton">
                                    <dots-loader/>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tbody v-else-if="sortedData.length==0">
                        <tr class="cursor-pointer rowHeight">
                            <td class="relative h-10" :colspan="columnNames.size">
                                <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="nonEmptyTable" v-else>
                        <tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer rowHeight">
                            <td @mouseout="doFalse()">
                                <div class="flex items-center ml-4">
                                    <input :disabled="(row.status == 10403 && thisScreen == 'Acceptance')||(thisScreen == 'Mitigate' && (row.status == 10405||row.status == 10403))" class="checkbox cursor-pointer" type="checkbox" :value="row" v-model="selectedItems" @change="changeInfo(row, $event)" />
                                </div>
                            </td>
                            <td v-for="column in columnNames" :key="column" @mouseout="doFalse()" class="whitespace-nowrap cursor-pointer relative">
                                <div v-if="column == 'severity'">
                                    <div class="relative w-28 regular pl-2">
                                        <div class="flex items-center justify-center rounded-full ml-2 w-5 h-5" @mousemove="toolTip(column, $event, index, row, 'circleButton')" :class="colorPropBg('border',row.severity)">
                                            <div class="severityCircle rounded-full" :class="colorPropBg('circle',row.severity)"></div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else-if="column == 'impact' || column == 'likelihood'">
                                    <div class="flex items-center justify-center rounded h-5 w-24" :class="colorRiskBg(row[column])">
                                        <div :class="colorRiskText(row[column])">
                                            <div v-if="column == 'impact'">{{
                                                dbOverAllImpact.find((l) => {
                                                    return l.value == row[column];
                                                }).key
                                            }}</div>
                                            <div v-else-if="column == 'likelihood'">{{
                                                dbLikelihood.find((l) => {
                                                    return l.value == row[column];
                                                }).key
                                            }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else-if="column == 'priority'">
                                    <div class="flex items-center justify-center rounded h-5 w-24" :class="colorRiskBg(row[column])">
                                        <div :class="colorRiskText(row[column])">
                                            <div v-if="column == 'priority'">{{
                                                dbPriority.find((l) => {
                                                    return l.value == row[column];
                                                }).key
                                            }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else-if="column == 'status'">
                                    <div class="flex items-center relative rounded regular text-xs w-24 h-5" :class="colorStatusText(row.status)">
                                    <div class="w-full h-full absolute rounded" :class="colorStatusBg(row.status)"></div>
                                    <div class="flex rounded w-28 items-center regular text-xs tip">
                                        <div v-if="!isNaN(row.status)">
                                            <div  class="flex w-28 items-center justify-center regular">
                                                {{
                                                    risksStatusValues.find((l) => {
                                                        return l.id == row.status;
                                                    }).value
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div v-else>
                                    <div class="table-ellipsis">
                                        <div @mousemove="toolTip(column, $event, index, row, 'text')">
                                            {{ row[column] }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
            </div>
            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>

        </div>
        <popup class="applyPopup" v-if="applyPopup" :popUp="applyPopup">
            <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
                <div class="relative w-full max-w-xl">
                    <!--content-->
                    <div class="border-0 rounded relative flex flex-col w-full bg-white risksAcceptance h-screen">
                        <!--header-->
                        <div class="flex items-center justify-between p-3 rounded popupheadingcontainer">
                            <h1>{{ riskAcceptanceObj[0].risk }}</h1>
                            <button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onCrossbtn()">
                                <!-- <XIcon class="h-5 w-5" /> -->
                                <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                            </button>
                        </div>
                        <div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
                        <!--body-->
                        <div>
                            <div class="impactcontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-col">
                                        <div class="upperspacing">
                                            <label class="controllabel" v-if="systemHead">Systems</label>
                                            <label class="controllabel" v-if="processHead">Process</label>
                                            <label class="controllabel" v-if="organizationHead">Organization</label>
                                            
                                            <div class="text-xs flex flex-wrap">
                                                <div v-for="system in selectedSystemsForAccept" :key="system" class="mr-2 mb-2 bg-primary text-white px-2 py-1 rounded">
                                                    {{ mappedSystemsOptions.find(option => option.value === system)?.label || "No system selected" }}
                                                </div>
                                            </div>

                                            <!-- {{selectedSystemsForAccept}}
                                            {{mappedSystemsOptions}}
                                            <Multiselect 
                                                v-model="selectedSystemsForAccept" 
                                                :options="mappedSystemsOptions" 
                                                :multiple="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                label="label"
                                                track-by="value"
                                                :searchable="true"
                                                :hideArrow="false"
                                                deselectLabel=""
                                                selectLabel=""
                                                class="custom-multiselect" 
                                                :disabled="true"
                                                placeholder="Name"
                                            >
                                            </Multiselect> -->
                                        </div>
                                        <div class="border-b border-solid border-lightgrey ml-4 mr-4 upperspacing" v-if="thisScreen=='Acceptance'||thisScreen=='reset'||thisScreen=='delete'"></div>
                                        <div class="w-full upperspacing" v-if="thisScreen=='Acceptance'||thisScreen=='reset'||thisScreen=='delete'">
                                            <label v-if="thisScreen == 'Acceptance'" class="controllabel">Reason for Accepting Risk / Justification</label>
                                            <label v-if="thisScreen == 'reset'" class="controllabel">Reason for Reset Risk / Justification</label>
                                            <label v-if="thisScreen == 'delete'" class="controllabel">Reason for Delete Risk / Justification</label>
                                            <textarea
                                                type="text"
                                                class="inputboxstyling h-20 max-h-48"
                                                v-model.trim="applyObj.comments"
                                                placeholder="Reason / Justification"
                                                :class="{
                                                    'is-invalid': v$.applyObj.comments.$error
                                                }"
                                                v-on:keyup="changePopup(applyObj)"
                                            ></textarea>
                                            <div v-if="v$.applyObj.comments.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.applyObj.comments.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                         <div class="upperspacing" v-else-if="thisScreen=='priority'">

                                            <div class="w-1/2">
                                                <label class="controllabel">Priority</label>
                                                 <select class="inputboxstyling" placeholder="Priority" @change="priorityChange(selectedPriorityKey)" v-model="selectedPriorityKey">
                                                <option value="" selected disabled hidden>Please select one</option>
                                                <option v-for="(priority,index) in dbPriority" :key="index" :value="priority.value">{{ priority.key }}</option>

                                                </select>

                                            </div>
                                          <div>
                                        
                                    <div class="row flex flex-col w-full">
                                        <div class=" w-full upperspacing" v-if="priorityDataChange">
                                            <label for="Risk" class="controllabel">Justification</label>
                                            <textarea
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="riskAcceptanceObj[0].justification"
                                            placeholder="Justification"
                                            :class=" {
                                                'is-invalid': v$.riskAcceptanceObj[0].justification.$error
                                            }"
                                            ></textarea>
                                        </div>

                                            <div class="text-red-500"  v-if="v$.riskAcceptanceObj[0].justification.$error">
                                                <div v-if="v$.riskAcceptanceObj[0].justification.required.$invalid" class="error-text text-xs" >justification required</div>
                                            </div>
                                            
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--footer-->
                        <div class="buttonposition flex justify-center items-center" v-if="thisScreen=='Acceptance'||thisScreen=='reset'||thisScreen=='delete'">
                            <button :disabled="clickOnButton" :class="(selectedSystemsForAccept.length || defEqual) && applyObj.comments!=''? 'btnprimary opacity-100' : 'btndisabled pointer-events-none'" class="mr-3" @click="onCancelbtn()">Cancel</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'Acceptance'" :class="(selectedSystemsForAccept.length || defEqual) && applyObj.comments!='' ? 'btnprimary opacity-100' : 'btndisabled pointer-events-none'" @click="createTaskForAction(applyObj,10403)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'reset'" :class="(selectedSystemsForAccept.length || defEqual) && applyObj.comments!='' ? 'btnprimary opacity-100' : 'btndisabled pointer-events-none'" @click="createTaskForAction(applyObj,10407)">Apply</button>
                            <button :disabled="clickOnButton" v-if="thisScreen == 'delete'" :class="(selectedSystemsForAccept.length || defEqual) && applyObj.comments!='' ? 'btnprimary opacity-100' : 'btndisabled pointer-events-none'" @click="createTaskForAction(applyObj,10404)">Apply</button>                         
                        </div>
                       <div class="buttonposition flex justify-center items-center" v-else-if="thisScreen=='priority'">
                            <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="(priorityDataChange==true) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelPriorityBtn()">Cancel</button>
                            <button :disabled="clickOnButton" :class="(priorityDataChange==true)? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdatePriority()" >Save</button>
                        </div> 
                    </div>
                </div>
            </div>
        </popup>
    </div>
    <div v-if="showRiskAcceptanceModal || applyPopup" class="opacity-40 fixed inset-0 z-50 bg-black"></div>
</template>
<style scoped>
.z-index {
    z-index: 999999;
}
.tip {
    display: inline;
    font-size: 12px;
}
.positiontooltip {
    font-size: 12px;
    border: 1px solid #e9e9e9;
}
/* .th1:nth-child(1) {
    width: 2rem;
} */
.th1:nth-child(2) {
    width: 150px;
}
.widthForSelect{
    width : 100px !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required, maxLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { emitter, toast } from '@/main';
// import Multiselect from '@vueform/multiselect';
import Multiselect from 'vue-multiselect';
import popup from '@/components/popup.vue';
import { mapGetters } from 'vuex';
import pagination from '@/components/pagination.vue';
import breadCrumb from '@/components/breadCrumb.vue';
import toolTip from '@/components/toolTip.vue';
import dotsLoader from '../../components/dotsLoader.vue';

export default defineComponent({
    components: { popup, pagination,breadCrumb, Multiselect,toolTip,dotsLoader },
    data(): any {
        return {
            v$: useVuelidate(),
            mandatoryKeys: ['Severity', 'systems', 'ownedBy', 'managedBy', 'impact', 'Created on'],
            fullKeys: ['Severity', 'systems', 'ownedBy', 'managedBy', 'impact', 'Created on'],
            showFilters: false,
            filter: {},
            selectedItems: [],
            classSort: '',
            showRiskAcceptanceModal: false,
            selectedSystemsForReset: [],
            applyPopup: false,
            riskPriorityShow:[],
            className: '',
            priorityDataChange:false,
            selectedPriorityKey:'',
            popupData:'',
            filterDummy: {
                severity: '',
                systemName: '',
                likelihood: '',
                impact: '',
                priority:'',
                status:'',
            },
            tipIndex:'',
            tipColumn:'',
            xPos: '',
            yPos: '',
            paginationObj: [],
            pageSize: 20,
            currentPage: 1,
            pageBoxes: 3,
            pageIndexStart: [],
            leftBox: 'left',
            rightBox: 'right',
            pageIndexEnd: [],
            totalPages: '',
            pagesArray: [],
            start: '',
            end: '',
            oldObj: [],
            pop: false,
            styling:{left:'',bottom:''},
            defNotEqual: false,
            defEqual: false,
            mappedSystemsOptions: [],
            mappedSystems: [],
            selectedSystemsForAccept: [],
            riskAcceptanceObj: [
                {
                    justification:''
                }
            ],
            tableRow: [],
            dynamicPagination: '',
            thisScreen: '',
            defaultObject: [],
            selected: '',
            currentSort: 'name',
            selectedObject: [],
            eachMappedRisk: [],
            currentSortDir: 'asc',
            routesArray:[],
            crumbName:'',
            selectAll: false,
            dummyList: [],

            headersData:[],
            organizationHead: false,
            processHead: false,
            systemHead: false,
            allSystems: [],
            businessProcessList: [],
            entitiesSelect: [],

            orgUsersList:[],
            optionsUserArray:[],
            tenants:[],
            applyObj: {
                comments: ''
            },
            insertedIds:[],
            risksStatusValues: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Accepted'
                },
                {
                    id: 10405,
                    value: 'Mitigated'
                },
                {
                    id: 10406,
                    value: 'Partial'
                }
            ],
            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
        };
    },
    validations() {
        return {
            applyObj: {
                comments: { required }
            },
            riskAcceptanceObj :[
                {
                      justification:{required}
                }
            ],
           
            
        };
    },
    computed: {
        ...mapGetters({ userInfo: 'userInfo',systemsSelected:'systemsSelected',dbSeverity:'dbSeverity',dbLikelihood:'dbLikelihood',dbPriority:'dbPriority',dbOverAllImpact: 'dbOverAllImpact' }),

        sortedData: function (): any {
            return [...this.tableRow]
                .filter((row: any) => {
                    for (const key in this.filterDummy) {
                        const inputValue = this.filterDummy[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index+1
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;      
                });
        },
        columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != '_id' && key != 'mappedRisk' && key != 'systemId' && key != 'processId' && key != 'orgId' && key != 'managedBy' && key!= 'ownedBy') names.add(key);
            }
            return names;
        }
    },
    async created() {
        if (this.$route.path.includes('/reset')) {
            this.thisScreen = 'reset';
            this.crumbName = 'Risk-Reset';
        } else if (this.$route.path.includes('/Acceptance')) {
            this.thisScreen = 'Acceptance';
            this.crumbName = 'Risk-Acceptance';
        }else if (this.$route.path.includes('/mitigate')) {
            this.thisScreen = 'Mitigate';
            this.crumbName = 'Risk-Mitigate'
        }else if (this.$route.path.includes('/delete')) {
            this.thisScreen = 'delete';
            this.crumbName = 'Risk-Delete'
        }else if(this.$route.path.includes('/priority')){
            this.thisScreen='priority';
            this.crumbName='Risk-Priority'
        }
        this.routesArray = [{name:'Risks',routeName:'risks'},{name:this.crumbName,routeName:this.thisScreen}];
        this.dataLoading = true;

        await this.getSystems();
        await this.getBusinessProcess();
        await this.getBusinessEntities();
        await this.getallUsers();
        await this.getAllTenants();
        await this.getAllTasks();
        await this.getActionedSystems();
        this.defaultObject = [...this.selectedItems];

        this.dataLoading = false;
    },
    async mounted(){
        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    },
    methods: {
        doFalse() {
            emitter.emit('deActiviteToolTip')
        },
        paginationUpdations(currentPage: any, pageSize: any) {
            console.log('Pagination processing... ', currentPage, pageSize);
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        getColumnHeadWidth(column:any){
            if(column=='impact'||column=='likelihood'||column=='status'||column=='priority'){
                return 'w-1/6'
            }else if(column=='severity'){
                return 'w-1/12'
            }else if(column=='orgName'||column=='processName'||column=='systemName'){
                return 'w-2/5'
            }else{
                return ''
            }
        },
        bindClassForColumn(column:any){
            if(column=='impact'){
                return 'pl-5 w-28'
            }else if(column=='likelihood'){
                return 'pl-3 w-28'
            }else if(column=='orgName'||column=='processName'||column=='systemName'){
                return 'w-40'
            }else if(column=='status'){
                return 'pl-6 w-36'
            }else if(column=='priority'){
                return 'pl-6 w-28'
            }else if(column=='severity'){
                return 'w-28'
            }else{
                return ''
            }
        },
        priorityChange(selectedPriority:any){
                       this.priorityDataChange=true;
                       this.riskAcceptanceObj[0].priority=selectedPriority; 
                       console.log("this.riskAcceptance:",this.riskAcceptanceObj[0].priority);                         
                    },
        
        isAllSelected() {
            return this.sortedData.every((obj: any) => {
                return obj.status == 10403
            });
        },
        colorStatusBg(Status: any) {
            if (Status == 10401) {
                return ['bg-darkred', 'opacity-10'];
            } else if (Status == 10402) {
                return ['bg-electricGreen', 'opacity-10'];
            } else if (Status == 10403) {
                return ['bg-primary', 'opacity-10'];
            } else if (Status == 10405) {
                return ['bg-lightgreen', 'opacity-10'];
            } else if (Status == 10406) {
                return ['bg-yellow', 'opacity-10'];
            }
        },
        colorStatusText(Status: any) {
            if (Status == 10401) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (Status == 10402) {
                return ['text-electricGreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-electricGreen'];
            } else if (Status == 10403) {
                return ['text-primary', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-primary'];
            } else if (Status == 10405) {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
            } else if (Status == 10406) {
                return ['text-yellow', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-yellow'];
            }
        },
        colorPropBg(format:any, scaleScore: any) {
            if(format == 'circle'){
                if (scaleScore == 5) {
                    return ['bg-darkred'];
                } else if (scaleScore == 4) {
                    return ['bg-error'];
                } else if (scaleScore == 3) {
                    return ['bg-yellow'];
                } else if (scaleScore == 2) {
                    return ['bg-green'];
                } else if (scaleScore == 1) {
                    return ['bg-lightgreen'];
                }
            }else if(format == 'border'){
                if (scaleScore == 5) {
                    return ['border-darkred border-2'];
                } else if (scaleScore == 4) {
                    return ['border-error border-2'];
                } else if (scaleScore == 3) {
                    return ['border-yellow border-2'];
                } else if (scaleScore == 2) {
                    return ['border-green border-2'];
                } else if (scaleScore == 1) {
                    return ['border-lightgreen border-2'];
                }
            }
            
        },
        colorRiskBg(information: any) {
            if (information == 1) {
                return ['bg-veryLowColor border-solid border border-lightgreen'];
            } else if (information == 2) {
                return ['bg-lowColor border-solid border border-green'];
            } else if (information == 3) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (information == 4) {
                return ['bg-highColor border-solid border border-error'];
            } else if (information == 5) {
                return ['bg-veryHighColor border-solid border border-darkred'];
            }
        },
        colorRiskText(information: any) {
            if (information == 1) {
                return ['text-lightgreen font-semibold'];
            } else if (information == 2) {
                return ['text-green font-semibold'];
            } else if (information == 3) {
                return ['text-yellow font-semibold'];
            } else if (information == 4) {
                return ['text-error font-semibold'];
            } else if (information == 5) {
                return ['text-darkred font-semibold'];
            }
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.riskAcceptanceObj;
            } else {
                this.riskAcceptanceObj = this.dummyList;
                for (const key in this.filterDummy) {
                    this.filterDummy[key] = '';
                }
            }
        },
        checkCheckbox() {
            let defaultInfoArr: Array<any> = [];
            this.defaultObject.map((obj: any) => {
                defaultInfoArr.push(obj);
            });

            let finalInfoArr: Array<any> = [];
            this.selectedItems.map((obj: any) => {
                finalInfoArr.push(obj);
            });
            if (JSON.stringify(defaultInfoArr) !== JSON.stringify(finalInfoArr)) {
                this.defNotEqual = true;
            } else {
                this.defNotEqual = false;
            }
        },
        changePopup(newObj: any) {
            let defaultInfoArr: Array<any> = [];
            let finalInfoArr: Array<any> = [];
            defaultInfoArr = newObj;
            finalInfoArr = this.oldObj;

            if (JSON.stringify(defaultInfoArr) !== JSON.stringify(finalInfoArr)) {
                this.defEqual = true;
            } else {
                this.defEqual = false;
            }
        },
        async addOrUpdatePriority(){
            console.log("clicked!!");
             this.selectedPriorityKey='';
            this.priorityDataChange=false;
            this.v$.$touch();
            if(!this.v$.riskAcceptanceObj.$invalid){
                this.v$.$reset();
                console.log("PriorityChangeData:",this.riskAcceptanceObj[0].priority);
                 this.popupData={
                    systems:this.selectedSystemsForAccept,
                    priority:this.riskAcceptanceObj[0].priority
                }
                console.log("thepopupData:",this.popupData);
                await this.updateRiskPriority(this.popupData,this.riskAcceptanceObj[0].mappedRisk);
                this.selectedSystemsForAccept = [];
                this.selectedItems = []
            }else{
                toast.error('Validation error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
         async updateRiskPriority(payload:any,mappedRiskId:any){
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/priority/${mappedRiskId}/modify`, payload).then((result: any) => {
                    toast.info(`Updated succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
                
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
				this.clickOnButton = false;
            }
            await this.getActionedSystems();
            this.applyPopup = false;
        },
        average(arr: any[]) {
            return arr.reduce((p, c) => p + c, 0) / arr.length;
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.allSystems = result.data;
                this.dummySystems = result.data;
            });
        },
        async getBusinessProcess() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                this.businessProcessList = result.data
            });
        },
        async getBusinessEntities(){
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                this.entitiesSelect = result.data
            })
        },
        async getallUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
            });
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id:org._id, name: org.organizationName }
                    this.tenants.push(obj);
                });
            });
        },
         async getAllTasks() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.tasksData = result.data;
            });
        },
        async getActionedSystems() {
            this.mappedSystemsOptions = [];
            this.mappedSystems = [];
            this.selectedSystemsForAccept = [];
            this.tableRow = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/${this.$route.params.riskId}/systems`).then((result: any) => {
                // this.riskAcceptanceObj = result.data;
                
                let filter:any = result.data.filter((risk: any) => {
                    return risk.tier == this.$route.query.tier;
                });

                this.riskAcceptanceObj = filter;
                
                for (let i = 0; i < this.riskAcceptanceObj.length; i++) {
                    let openList:any = this.riskAcceptanceObj[i];

                    let condition1 = (Object.keys(this.riskAcceptanceObj[i]).includes('residual') && this.riskAcceptanceObj[i]['residual'].length>0)
                    let averageImpact;
                    let impact;
                    let severity;

                    if(condition1 == true){
                        let lengthOfArray = this.riskAcceptanceObj[i]['residual'].length
                        let indexObj = this.riskAcceptanceObj[i]['residual'][lengthOfArray-1];
                        
                        if(indexObj.isActive == true){
                            averageImpact = this.average([ indexObj['business'], indexObj['operations'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);
                             let arr = ['business','operations','legal','financial','likelihood']
                            for(let key of arr){
                                this.riskAcceptanceObj[i][key] = indexObj[key];
                            }
                        }else{
                            averageImpact = this.average([ openList['business'], openList['operations'], openList['legal'], openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * openList['likelihood']);
                        }
                    }else if(condition1 == false){
                        averageImpact = this.average([ openList['business'], openList['operations'], openList['legal'], openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * openList['likelihood']);
                    }
                    this.riskAcceptanceObj[i]['impact'] = impact;
                    this.riskAcceptanceObj[i]['severity'] = severity;

                    var tierObj: any = {};
                    let mainId:any;
                    let mainName:any;

                    let newTableObject: any = {
                        severity: this.riskAcceptanceObj[i].severity,
                        impact: this.riskAcceptanceObj[i].impact,
                        likelihood: this.riskAcceptanceObj[i].likelihood,
                        mappedRisk: this.riskAcceptanceObj[i].mappedRisk,
                        status: this.riskAcceptanceObj[i].statusCode,
                        priority:this.riskAcceptanceObj[i].priority
                    };

                    

                    if (this.riskAcceptanceObj[i].systemId) {
                            this.systemHead = true;
                            tierObj = this.allSystems.find((obj: any) => this.riskAcceptanceObj[i].systemId == obj._id);
                            mainId = tierObj._id;
                            mainName = tierObj.systemName;
                            newTableObject.systemId = mainId
                            newTableObject.systemName = mainName
                            this.headersData = ['severity', 'systemName', 'impact', 'likelihood','priority', 'status']
                        } else if (this.riskAcceptanceObj[i].processId) {
                            this.processHead = true;
                            tierObj = this.businessProcessList.find((obj: any) => this.riskAcceptanceObj[i].processId == obj._id);
                            mainId = tierObj._id;
                            mainName = tierObj.businessProcessName;
                            newTableObject.processId = mainId
                            newTableObject.processName = mainName
                            this.headersData = ['severity', 'processName', 'impact', 'likelihood','priority', 'status']
                        } else if (this.riskAcceptanceObj[i].orgId) {
                            this.organizationHead = true;
                            tierObj = this.entitiesSelect.find((obj: any) => this.riskAcceptanceObj[i].orgId == obj._id);
                            mainId = tierObj._id;
                            mainName = tierObj.entityDisplayName;
                            newTableObject.orgId = mainId
                            newTableObject.orgName = mainName
                            this.headersData = ['severity', 'orgName', 'impact', 'likelihood','priority', 'status']
                        }
                    this.mappedSystems.push(newTableObject);

                    let { managedBy, ownedBy} = tierObj;

                    let presentTenant = this.tenants.find((tenant:any)=>{return tenant.id==ownedBy})
                    if(presentTenant==undefined){
                        this.ownedBy = ownedBy;
                        
                    }else{
                        this.ownedBy = presentTenant.name;
                    }
                    newTableObject.ownedBy = this.ownedBy;

                    let presentUser = this.optionsUserArray.find((user:any)=>{return user.value==managedBy})
                    if(presentUser==undefined){
                        this.managedBy = managedBy;
                    }else{
                        this.managedBy = presentUser.label;
                    }
                    newTableObject.managedBy = this.managedBy;

                    if(Object.keys(this.riskAcceptanceObj[i]).includes('systemId')){
                        newTableObject.systemId = this.riskAcceptanceObj[i].systemId

                    } else if(Object.keys(this.riskAcceptanceObj[i]).includes('processId')){
                        newTableObject.processId = this.riskAcceptanceObj[i].processId

                    } else if(Object.keys(this.riskAcceptanceObj[i]).includes('orgId')){
                        newTableObject.orgId = this.riskAcceptanceObj[i].orgId

                    }

                    if (this.thisScreen == 'Acceptance') {
                        if (this.riskAcceptanceObj[i].statusCode == 10403) {
                            this.selectedObject.push(newTableObject);                            
                            this.dummyList.push(newTableObject);
                        }
                        this.tableRow.push(newTableObject);
                    }
                     else if (this.thisScreen == 'reset') {
                        if (newTableObject.status != 10401 && newTableObject.status != 10402 && newTableObject.status != 10406) {
                            this.tableRow.push(newTableObject);
                        }
                    } else if (this.thisScreen == 'Mitigate') {
                        if(this.riskAcceptanceObj[i].statusCode == 10401 || this.riskAcceptanceObj[i].statusCode == 10402 || this.riskAcceptanceObj[i].statusCode == 10406){
                            this.tableRow.push(newTableObject);
                        }
                    } else if (this.thisScreen == 'delete') {
                        if (newTableObject.status != 10402 && newTableObject.status != 10406) {
                            this.tableRow.push(newTableObject);
                        }
                    } else if (this.thisScreen == 'priority') {
                        if (newTableObject.status == 10401){
                            this.tableRow.push(newTableObject);
                            this.dummyList.push(newTableObject);
                        }
                    }
                }

                this.selectedObject.map((obj: any) => {
                    const newObj: any = {
                        severity: obj.severity,
                        impact: obj.impact,
                        likelihood: obj.likelihood,
                        mappedRisk: obj.mappedRisk,
                        status: obj.status
                    };

                    if(Object.keys(obj).includes('systemId')){
                        newObj.systemId = obj.systemId

                    } else if(Object.keys(obj).includes('processId')){
                        newObj.processId = obj.processId

                    } else if(Object.keys(obj).includes('orgId')){
                        newObj.orgId = obj.orgId

                    }
                    newObj.ownedBy = this.ownedBy;
                    console.log("newObj", newObj)
                    if (this.thisScreen == 'Acceptance') {
                        this.selectedItems.push(newObj);                        
                    }
                });
                this.isAllSelected();
                
                this.mappedSystems.forEach((item: any) => {
                    let filterObj
                    if(Object.keys(item).includes('systemId')){
                        filterObj = { value: item.systemId, label: item.systemName };
                    } else if(Object.keys(item).includes('processId')){
                        filterObj = { value: item.processId, label: item.processName };
                    } else if(Object.keys(item).includes('orgId')){
                        filterObj = { value: item.orgId, label: item.orgName };
                    }
                    console.log("filterObj priority:",filterObj);
                    
                    this.mappedSystemsOptions.push(filterObj);
                    console.log("mappedsystemoptions:",this.mappedSystemsOptions);
                    
                });
            });
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        selectAllOptions(e: any) {
            const checked = e.target.checked;
            if (checked) {
                for (let obj of this.tableRow) {
                    let newObj: any = {
                        severity: obj.severity,
                        impact: obj.impact,
                        likelihood: obj.likelihood,
                        mappedRisk: obj.mappedRisk,
                        status: obj.status,
                        ownedBy: obj.ownedBy,
                        managedBy: obj.managedBy
                    };
                    if(Object.keys(obj).includes('systemId')){
                        newObj.systemId = obj.systemId
                        newObj.systemName= obj.systemName

                    } else if(Object.keys(obj).includes('processId')){
                        newObj.processId = obj.processId
                        newObj.processName= obj.processName

                    } else if(Object.keys(obj).includes('orgId')){
                        newObj.orgId = obj.orgId
                        newObj.orgName= obj.orgName
                    }
                    
                    if (this.thisScreen == 'reset' && (newObj.status != 10401)) {
                        let findObj = this.selectedItems.find((object:any)=>{
                            return JSON.stringify(object) === JSON.stringify(newObj)
                        });
                        if(findObj==undefined){
                            this.selectedItems.push(newObj);
                        }
                    } else if (this.thisScreen == 'Acceptance' && (newObj.status != 10403)) {
                        let findObj = this.selectedItems.find((object:any)=>{
                            return _.isEqual(object, newObj)
                        });
                        if(findObj==undefined){
                            this.selectedItems.push(newObj);
                        }
                    }else if (this.thisScreen == 'Mitigate') {
                        if(newObj.status!=10405){
                            console.log("fdzfeshfgvhjjkbhfxds", newObj)
                            this.selectedItems.push(newObj);
                        }
                    }
                    else if (this.thisScreen == 'delete') {
                        this.selectedItems.push(newObj);
                    }
                }
            } else {
                if(this.thisScreen == 'Acceptance'){
                    this.selectedItems = [];
                    this.tableRow.map((obj:any)=>{
                        if(obj.status==10403){
                            this.selectedItems.push(obj);
                        }
                    })
                }else if (this.thisScreen == 'Mitigate'){
                    this.selectedItems = [];
                    this.tableRow.map((obj:any)=>{
                        if(obj.status==10405){
                            
                            this.selectedItems.push(obj);
                        }
                    })
                }else{
                    this.selectedItems = [];
                }
            }
            this.checkCheckbox();
        },
        changeInfo(riskAcceptance: any, e: any) {
            if (this.selectedItems.length === this.riskAcceptanceObj.length) {
                this.selectAll = true;
            } else {
                this.selectAll = false;
            }
            this.checkCheckbox();
        },
        isParentSelected() {
            let selectedLength = this.selectedItems.length;
            let tableRowLength = this.tableRow.length;
            if (selectedLength === tableRowLength) {
                this.selectAll = true;
            } else {
                this.selectAll = false;
            }
        },
        async applyInfo() {
            // this.selectedSystemsForAccept = [];
            this.applyPopup = true;
            var mitigateSystems = this.selectedItems.map((riskObj: any) => {
                let idKey
                if(Object.keys(riskObj).includes('systemId')){
                    // { value: item.orgId, label: item.orgName }
                    // idKey = { value: riskObj.systemId, label: riskObj.systemName };
                    idKey = riskObj.systemId;
                    this.applyObj.systemId =[ riskObj.systemId]
                }else if(Object.keys(riskObj).includes('processId')){
                    // idKey = { value: riskObj.processId, label: riskObj.processName };
                    idKey = riskObj.processId ;
                    this.applyObj.processId =[ riskObj.processId]
                }else if(Object.keys(riskObj).includes('orgId')){
                    // idKey = { value: riskObj.orgId, label: riskObj.orgName }
                    idKey = riskObj.orgId 
                    this.applyObj.orgId =[ riskObj.orgId]
                }
                console.log("idKey", idKey)
                if((riskObj.status != 10403) && this.thisScreen == 'Acceptance'){
                    console.log("riskObj.idKey", idKey)
                    this.selectedSystemsForAccept.push(idKey);
                    // this.applyObj.systems.push(idKey);
                } else if(this.thisScreen == 'reset'||this.thisScreen == 'delete'){
                    this.selectedSystemsForAccept.push(idKey);
                    // this.applyObj.systems.push(idKey);
                }
                else if(this.thisScreen == 'Mitigate'){
                    console.log("riskObj", riskObj)
                    // this.selectedSystemsForAccept.push(idKey);
                    return idKey
                }
                else if(this.thisScreen=='priority'){
                    console.log("priority data:",idKey);
                    this.selectedSystemsForAccept.push(idKey)
                    
                }

            });
            this.oldObj = { ...this.applyObj };
            console.log("mitigateSystems", mitigateSystems);
            console.log("applyObj", this.applyObj)
            console.log("oldObj", this.oldObj);
            console.log("this.$route",this.$route)
            if(this.thisScreen=='Mitigate'){
            console.log("this.$route",this.$route)
                localStorage.setItem(this.$route.params.riskId, JSON.stringify(mitigateSystems));
                this.$router.push({name:'systemsControlsMap',params:{riskId: this.$route.params.riskId},query:{risks:true}});
                if(Object.keys(this.applyObj).includes('systemId')){
                    this.$router.push({ name: 'systemsControlsMap', params: { riskId: this.$route.params.riskId, type: 'systems', id: this.applyObj.systemId }, query: { systems: true } });
                } else if(Object.keys(this.applyObj).includes('processId')){
                    console.log("this.$route",this.$route)
                    this.$router.push({ name: 'systemsControlsMap', params: { riskId: this.$route.params.riskId, type: 'process', id: this.applyObj.processId }, query: { process: true } });
                } else if(Object.keys(this.applyObj).includes('orgId')){
                    this.$router.push({ name: 'systemsControlsMap', params: { riskId: this.$route.params.riskId, type: 'organization', id: this.applyObj.orgId }, query: { organization: true } });
                }
            }
        },
        cancelInfos() {
            this.defNotEqual = false;
            if(this.thisScreen == 'Acceptance'){
                this.selectedItems = [];
                this.tableRow.map((obj:any)=>{
                    if(obj.status==10403){
                        this.selectedItems.push(obj);
                    }
                })
            }else if(this.thisScreen == 'reset'||this.thisScreen == 'delete'){
                this.selectedItems = [];
            }else{
                this.selectedItems = [];
                this.tableRow.map((obj:any)=>{
                    if(obj.status==10405){
                        this.selectedItems.push(obj);
                    }
                })
            }
            this.selectAllOptions();
        },
        onCrossbtn() {
            this.applyPopup = false;
            this.v$.$reset();
            this.mappedSystems = [];
            // this.selectedItems = [];
            this.selectedSystemsForAccept = [];
            Object.keys(this.applyObj).forEach((prop) => {
                Array.isArray(this.applyObj[prop]) ? (this.applyObj[prop] = []) : (this.applyObj[prop] = '');
            });

            if(this.thisScreen=='priority'){
                this.selectedItems=[];
            }
        },
        onCancelbtn() {
            this.v$.$reset();
            Object.keys(this.applyObj).forEach((prop) => {
                if (prop != 'systems') {
                    Array.isArray(this.applyObj[prop]) ? (this.applyObj[prop] = []) : (this.applyObj[prop] = '');
                }
            });
        },
        cancelPriorityBtn() {
            // this.riskAcceptanceObj.risk=this.priorityDummy.risk;
            this.riskAcceptanceObj[0].priority='';
            this.selectedPriorityKey='';
            this.riskAcceptanceObj[0].justification='';
            this.dummyList.justification='';
            this.v$.$reset();
        },
        async createActionForRisk(payload: any) {
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/accept`, payload).then((result: any) => {
                    this.systemAssesmentList = result.data;
                });
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            this.$router.push({ name: 'risks' });
        },
        async createTaskForAction(applyObj: any,actionCode:any) {
            this.v$.$touch();
            this.clickOnButton = true
            console.log('khvkasbkjabsd',this.selectedItems)
            this.eachMappedRisk = this.selectedItems[0].mappedRisk;

            let payload: any = [];
            let obj:any = {
                    mappedRisk: this.selectedItems[0].mappedRisk,
                    createdAt: new Date(),
                    createdBy: this.userInfo._id,
                    action: actionCode
                }
            let tierIds: any= []
            if(this.selectedSystemsForAccept != undefined && this.selectedSystemsForAccept.length > 0){
                this.selectedSystemsForAccept.map((obj: any)=>{
                    tierIds.push(obj)
                    // console.log("tierIds",tierIds)
                    // console.log(" this.selectedSystemsForAccept", this.selectedSystemsForAccept)
                })
            }
            
            if(Object.keys(this.selectedItems[0]).includes('systemId')){
                obj.systems = tierIds
                // console.log("tierIds",tierIds);
                // console.log("this.selectedItems[0]",this.selectedItems[0])
                // console.log("selected systems:",obj.systems);
                obj.tier = 3
            }else if(Object.keys(this.selectedItems[0]).includes('processId')){
                obj.process = tierIds
                obj.tier = 2
            }else if(Object.keys(this.selectedItems[0]).includes('orgId')){
                obj.org = tierIds
                obj.tier = 1
            }
            payload.push(obj)
            console.log("obj data:",obj);
            
            if (!this.v$.applyObj.$invalid) {
                
                payload[0].comments = applyObj.comments;
                console.log("payload:",payload[0]);
                
                await this.createWorkFlowTask(payload);
                
                this.v$.$reset();
            } else {
                toast.error(`cannot save with same teamname`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
      async createWorkFlowTask(payload: any){
            try {
                await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/task`, payload).then((result: any) => {
                    this.insertedIds = result.data
                    console.log("inserted id:", result.data);
                    
                    toast.info('Task Created', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                payload[0].insertedIds = this.insertedIds;
                await this.createActionForRisk(payload);
                this.showRiskAcceptanceModal = false;
            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
            this.comments = '';
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.tipText = row[column];
            this.tipColumn = column;
            this.tipIndex = index;
      
            switch (status) {
                case 'text':
                    this.handleTextTooltip(e);
                    break;
                case 'circleButton':
                     let findVal = this.dbSeverity.find((obj:any)=>{return obj.value == row[column]})

                    if(findVal != undefined){
                        this.toolTipText = findVal.key
                    }else{
                        this.toolTipText = row[column]
                    }

                    let obj:any = {
                        tipText : this.toolTipText
                    }
                    emitter.emit('activiteToolTip',obj)
            }
        },
        handleTextTooltip(e:any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                let obj:any = {
                    tipText : this.toolTipText
                }

                emitter.emit('activiteToolTip',obj)
            }
        },
        isTextOverflowed(element:any) {
            return element.scrollHeight > element.clientHeight;
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
    }
});
</script>




