

<template>
	<div class="maincontainer pt-3 pl-5 pr-5">
		 <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
		<div class="flex justify-between">
			<h1>{{riskName}}</h1>
			<div v-if="defaultTaskStatus!=null && !dataLoading" class="flex mr-4">
				<button :class="(JSON.stringify(taskObj)!=JSON.stringify(dummyTaskObj)) ?'btnprimary':'btndisabled pointer-events-none'" :disabled="clickOnButton"  @click="updatetask(taskObj.taskStatus,'forStatus')">Save</button>
			</div>
		</div>
		
		<div class="card mt-4">
			<div class="p-2 flex flex-row gap-1">
				<div class="w-3/4">
					<div class="bg-white w-full">
						<div class="mx-1.5">
							<div class="pt-2 flex flex-row borderBottom justify-between cursor-pointer tab-container">
								<div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in carouselHead" :key="tabIndex" @click="toggleTabIds(tab)">
									<!-- <div :class="{ 'anime-left': !sideButton, 'anime-right': sideButton }"> -->
									<div>
										<div>{{ tab.displayName }}
											<span v-if="tab.displayName=='Attachments' && task.documents && task.documents.length>0"> ({{task.documents.length}})</span>
											<span v-if="tab.displayName=='Links' && task.link && task.link.length>0"> ({{task.link.length}})</span>
										</div>
										<div :class="{ progressx: selectedTabIndex == tab.id }" class="h-1 p-0.75"></div>
									</div>
								</div>
							</div>
							<div class="progressbarB rounded h-1"></div>
						</div>
					</div> 
					<div v-if="selectedTabIndex == 1" class="px-1.5 pb-1.5">
						<div>
							<div class="border-b border-lightgrey flex justify-between">
								<!-- <div class="flex items-center marginTopForDoc marginBottomForDoc justify-center"> -->
									<div>
										<p class="my-1 header">Title</p>
									</div>
								<!-- </div> -->
							</div>
							
							<p class="mt-1.5 text-sm">{{ task.controlTitle }}</p>
						</div>
						<div>
							<div class="border-b border-lightgrey">
								<p class="my-1.5 header">Description</p>
							</div>
							<p class="mt-1.5 headerText"></p>
						</div>
						<div>
							<div v-if="task " class="text-sm">{{ task.controlAuditMethodology || 'N/A' }}</div>
							<div v-else>N/A</div>
						</div>
						<!-- <div class="p-1">
							<div>
								<div>
									<div>Title</div>
									<div>{{ task.controlTitle }}</div>
								</div>
								<div>
									<div>Description</div>
									
								</div>
							</div> -->
							
							<!-- <div class="mt-5 ml-2">
								{{mainDescription}}
							</div> -->
							
											
					</div>
					<div v-if="selectedTabIndex==2" class="px-1.5 pb-1.5">	
						<div>
							<div class="flex justify-between">
								<div class="flex items-center marginTopForDoc marginBottomForDoc justify-center">
									<div>
										<p class="my-1 header">Attachments</p>
									</div>
								</div>
								
									<div class="buttonposition flex justify-center items-center mb-1.5">
										<!-- :class="TaskDetailsDropDown.taskStatus ==10419 || TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentAuditObject.statusCode == 10403|| presentAuditObject.statusCode == 10418 || presentFindingTaskObj.auditor == userInfo._id? 'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'" -->
										<button @click="addDocuments()" for="upload"  class='btnprimary flex justify-center taskEditButtons' >Attach</button>
									</div>
								
								<!-- <div class="buttonposition flex justify-center items-center">
									<button class="btnprimary flex justify-center taskEditButtons" @click="addDocuments()">Attach</button>
									<input type="file" class="hidden" id="upload" ref="file"/>
								</div> -->
							</div>
							
							
						</div>					
						<!-- <div class="header flex items-center mt-4 mb-2 justify-between">
							<div class="flex cursor-pointer">
								<h1 class="ml-2">Attachments</h1>
							</div>
							<div>
								<div class="buttonposition flex justify-center items-center">
									<button class="btnprimary taskEditButtons" @click="addDocuments()">Attach</button>
									<input type="file" class="hidden" id="upload" ref="file"/>
								</div>
							</div>
						</div> -->
						<div class="border-b border-solid border-lightgrey">
						</div>
						<div v-if="Object.keys(task).includes('documents')" class="flex mt-4 gap-4 flex-wrap">
							<div v-for="doc in task.documents" :key="doc">
								<div class="pdf w-full rounded-sm">
									<a :href="doc.documentUrl" class="flex w-full flex-row items-center">
										<img src="@/assets/pdfs.svg" alt="" />
										<p v-if="Object.keys(doc).includes('filename')" class="text-xs ml-2">{{doc.filename}}</p>
										<p v-else class="text-xs ml-1">{{doc.documentName}}</p>
									</a>
								</div>
							</div>
						</div>
						<!-- <div class="flex mt-4 gap-4 flex-wrap">	
							<div v-for="doc in task.documents" :key="doc">
								<div class="pdf w-full">
									<a :href="doc.documentUrl" class="flex w-full flex-row  items-center flex-wrap">
										<img src="@/assets/pdfs.svg" alt=""  />
										<p class="text-xs ml-1">{{doc.documentName}}</p> -->
										<!-- <p class="text-xs ml-1">{{fileName(doc.documentUrl)}}</p> -->
									<!-- </a>
								</div>
							</div>
						</div> -->
					</div>
					<!-- <div v-if="selectedTabIndex==3">
						<div class="header flex items-center justify-between mt-4 mb-2">
							<div class="flex cursor-pointer">
								<h1 class="ml-2">Sub-Tasks</h1>
							</div>
							<div>
								<div class="buttonposition flex justify-center items-center mx-0">
									<button class="btnprimary taskEditButtons" @click="addDocuments()">Add Task</button>
								</div>
							</div>
						</div>
						<div class="border-b border-solid border-lightgrey"></div>
						<div class="w-full mt-4">
							<label class="controllabel"></label>
								<input
									type="text"
									class="inputboxstyling"
									placeholder="No Sub-Tasks"
								/>
						</div>
						
					</div> -->
					<div v-if="selectedTabIndex==4" class="px-1.5 pb-1.5">
						<div>
							<div class=" flex justify-between">
								<div class="flex items-center marginTopForDoc marginBottomForDoc justify-center">
									<div>
										<p class="my-1 header">Links</p>
									</div>
								</div>
								<div class="buttonposition flex justify-center items-center mb-1.5">
									<button class="btnprimary taskEditButtons"  @click="onOpenLinkTab()">Links</button>
								</div>
							</div>
							
							
						</div>	
						<!-- <div class="header flex items-center mt-4 mb-2 justify-between"> -->
							<!-- <div class="flex cursor-pointer"> -->
							<!-- <div><img src="@/assets/arrowLeftNavigation.svg" class="w-6 h-6 mr-4" @click="backtocourseEdit()" /></div> -->
							<!-- <h1 class="ml-2">Links</h1> -->
							<!-- </div> -->
							<!-- <div>
								<div class="buttonposition flex justify-center items-center">
									<button class="btnprimary taskEditButtons"  @click="showTaskModal =true">Links</button>
								</div>
							</div>
						</div> -->
						<div class="border-b border-solid border-lightgrey"></div>
						
						<table class="mt-4">
							<tr class="mt-2" v-for="link in task.link" :key="link">
								<a class="ml-3" href="">{{link.linkUrl}}</a>
							</tr>
						</table>
					</div>
					<div v-if="selectedTabIndex==5" class="px-1.5 pb-1.5">	
						<div>
							<div class="border-b border-lightgrey flex justify-between">
								<div class="flex items-center marginTopForDoc marginBottomForDoc justify-center">
									<div>
										<p class="my-1 header">Comments</p>
									</div>
								</div>
								<div>
									<div class="buttonposition flex justify-center items-center mb-1.5">
										<button class="btnprimary w-24 flex taskEditButtons" v-if="oldComments" @click="sortDates()">
											<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
											Newest
										</button>
										<button class="btnprimary w-24 flex taskEditButtons" v-if="!oldComments" @click="sortDates()">
											<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
											Oldest
										</button>
									</div>
								</div>
							</div>
							
							
						</div>						
						<div class="mt-5">
								<!-- <div class="header flex items-center justify-between">
									<h1 class="ml-2 mb-2">Comments</h1>
									<div>
										<div class="buttonposition flex justify-center items-center mb-3 ml-8">
											<button class="btnprimary w-24 flex taskEditButtons" v-if="oldComments" @click="sortDates()">
												<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
												Newest
											</button>
											<button class="btnprimary w-24 flex taskEditButtons" v-if="!oldComments" @click="sortDates()">
												<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
												Oldest
											</button>
										</div>
									</div>
								</div> -->
								<!-- <div class="border-b border-solid border-lightgrey"></div> -->
								<!-- <div class="flex flex-1 flex-wrap tabs-boxed cursor-pointer mt-2">
									<div class="flex-auto text-center a" v-for="(singlenav, navIndex) in navBarheading" :key="singlenav.key">
										<a class="tab text-sm font-bold" v-on:click="toggleTabs(navIndex)" :class="{ correct: openTab == navIndex }">
											{{ singlenav }}
										</a>
									</div>	
								</div> -->
								<div v-if="openTab == 0" class="mt-4">
									<!-- <textarea name="" class="inputboxstyling" placeholder="Add a Comment" cols="3" rows="3" v-model="comments"
									></textarea>
									
									<div class="flex justify-end">
										<button class="btnprimary mt-2 mr-2" :class="disableComments ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="updateComments()">Add</button>
										<button class="btnprimary mt-2" :class="disableComments ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="clearComments()">Clear</button>
									</div> -->
									<div class="my-3">
										<commentsEditor/>
									</div>
									<!-- <div class="w-4/5 text-xs"><safeHtml :content="obj.comment"/></div> -->
									<div class="flex mt-4 singlecomment" v-for="comment in commentsArray" :key="comment.createdAt">
										<div class="userContainer cursor-pointer p-2" :class="{ fullContainer: comment.isExpanded }">
											<p class="font-semibold ellipsis-multiline-1" @click="toggleCommentHeight(comment)"
											:class="{ expanded: comment.isExpanded }">
												{{comment.user}}
											</p>
											<p class="text-xs mt-1">{{comment.createdAt}}</p>
										</div>
										<div class="commentContainer p-2" :class="{ fullContainer: comment.isExpanded }">
											<p ref="commentParagraph" class="text-left ellipsis-multiline-2 cursor-pointer" @click="toggleCommentHeight(comment)"
											:class="{ expanded: comment.isExpanded }">
												<!-- <safeHtml :content="comment.comments"/> -->
												{{convertToPlainText(comment.comments)}}
											</p>
										</div>
									</div>
								</div>
							</div>
						
					</div>
					<div v-if="selectedTabIndex == 6" class="px-1.5 pb-1.5">
						<div>
							<div class="border-b border-lightgrey flex justify-between">
								<div class="flex items-center marginTopForDoc marginBottomForDoc justify-center">
									<div>
										<p class="my-1 header">Approval</p>
									</div>
								</div>
								<!-- <div class=" flex justify-center items-center"> -->
									<div class="buttonposition flex justify-center items-center mb-1.5">
										<!-- :class="(taskObj.assignee == '' || taskObj.assignee == null || this.task.statusCode == 10403)?'btndisabled pointer-events-none':'btnprimary'" -->
										<button class="btnprimary flex justify-center" :class="(taskObj.assignee == '' || taskObj.assignee == null || this.task.statusCode == 10403)?'btndisabled pointer-events-none taskEditApproval':'taskEditButtons'" @click="ApprovalTabDetails">										
											Send Approval
										</button> 
									</div>
								<!-- </div> -->
							</div>
							
							
						</div>	
						<!-- <div class="flex p-3 items-center justify-between">
							<h1 class="title ml-2">Approval</h1>
							<div class=" flex justify-center items-center">
								<div class=" flex justify-center items-center">
									<button :class="(taskObj.assignee == '' || taskObj.assignee == null || this.task.statusCode == 10403)?'btndisabled pointer-events-none':'btnprimary'"  @click="ApprovalTabDetails">										
										Send Approval
									</button> 
								</div>
							</div>
						</div> -->
							<div v-if="Object.keys(task).includes('approval') && task.approval.length >0" >
								<div  v-for="member in task.approval" :key="member" class="flex items-center p-2 w-full">
									<div class="initials cursor-pointer">
										{{  
											extractInitials(orgUsersList.find((user)=>{
												return user._id == task.createdBy
											}).name) 
										}}
									</div>
									<div class="flex">
										<div class="font-semibold cursor-pointer text-sm">
											{{  
												this.orgUsersList.find((user)=>{
													return user._id == task.createdBy
												}).name
											}}
										</div>
										<div class="flex">
											<p class="mx-2 text-sm">{{"Requested Approval from - "}}</p>
											<p class="font-semibold cursor-pointer text-sm">
												{{  
													member.approval!= null ? orgUsersList.find((user)=>{
														return user._id == member.approval
													}).name : 'N/A'
												}}
											</p>
										</div>
									</div>
								</div>
							
							</div>
					</div>
					<div v-if="selectedTabIndex == 7" class="px-1.5 pb-1.5">
						<div>
							<div class="border-b border-lightgrey flex justify-between">
								<div class="flex items-center marginTopForDoc marginBottomForDoc justify-center">
									<div>
										<p class="my-1 header">Task History</p>
									</div>
								</div>
								<div class=" flex justify-center items-center mb-1.5">
									<div class=" flex justify-center items-center">
										<button class="btnprimary w-24 flex taskEditButtons" v-if="oldComments" @click="sortDates()">
											<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
											Newest
										</button>
										<button class="btnprimary w-24 flex taskEditButtons" v-if="!oldComments" @click="sortDates()">
											<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
											Oldest
										</button> 
									</div>
								</div>
							</div>
							
							
						</div>	
						<!-- <div class="flex p-3 items-center justify-between">
							<h1 class="title ml-2">Task History</h1>
							<div class=" flex justify-center items-center">
								<div class=" flex justify-center items-center">
									<button class="btnprimary w-24 flex taskEditButtons" v-if="oldComments" @click="sortDates()">
										<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
										Newest
									</button>
									<button class="btnprimary w-24 flex taskEditButtons" v-if="!oldComments" @click="sortDates()">
										<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
										Oldest
									</button> 
								</div>
							</div>
						</div> -->
						<div v-for="taskhistory in taskHistoryObject[0]" :key="taskhistory" class="bg-white p-4 border-b border-lightgrey w-full">
							<div class="flex">
								<div class="initials cursor-pointer">
									{{  
										extractInitials(orgUsersList.find((user)=>{
											return user._id == taskhistory.createdBy
										}).name) 
									}}
								</div>
								<div>
									<div class="flex">
										<p class="font-semibold cursor-pointer text-sm">
											{{  
												this.orgUsersList.find((user)=>{
													return user._id == taskhistory.createdBy
												}).name
											}}
										</p>
										<p v-if="(taskhistory.previousStatus==10401 && taskhistory.updatedStatus==10401)" class="mx-2 text-sm">{{"Created this task on"}}</p>
										<p v-else-if="taskhistory.updatedStatus==10408 && Object.keys(taskhistory).includes('insertedIds') && taskhistory.insertedIds!=null" class="mx-2 text-sm">{{'Created the Approval Task on'}}</p>
										<p v-else-if="taskhistory.updatedStatus==10408 &&  Object.keys(taskhistory).includes('insertedIds') && taskhistory.insertedIds==null" class="mx-2 text-sm">{{'Kept this Task in Approval on'}}</p>

										<p v-else-if="taskhistory.updatedStatus==10410 && taskhistory.previousStatus==10408 && Object.keys(taskhistory).includes('taskId')" class="mx-2 text-sm">{{'Rejected the Approval Task on'}}</p>
										<p v-else-if="taskhistory.updatedStatus==10410 && taskhistory.statusCode == 0" class="mx-2 text-sm">{{'Rejected the Subtask on'}}</p>
										<p v-else-if="taskhistory.updatedStatus==10403 && taskhistory.statusCode == 0" class="mx-2 text-sm">{{'Rejected this task on'}}</p>

										<p v-else-if="taskhistory.updatedStatus==10403 && taskhistory.previousStatus==10408 && Object.keys(taskhistory).includes('taskId')" class="mx-2 text-sm">{{'Approved the Approval Task on'}}</p>									
										<p v-else-if="taskhistory.updatedStatus==10403 && taskhistory.statusCode != 0" class="mx-2 text-sm">{{'Accepted this task on'}}</p>

										<p v-else-if="taskhistory.updatedStatus==10409" class="mx-2 text-sm">{{'Kept this task in Review on'}}</p>
										<p v-else-if="taskhistory.updatedStatus==10402" class="mx-2 text-sm">{{'Kept this task in Progress on'}}</p>
										<p v-else-if="taskhistory.updatedStatus==10401" class="mx-2 text-sm">{{'Kept this task in Open on'}}</p>

										<p class="font-semibold cursor-pointer text-sm">{{formatTime(taskhistory.createdAt)}}</p>
										
										
									</div>
									<div v-if="Object.keys(taskhistory).includes('insertedIds') && taskhistory.insertedIds!=null">
										<div class="flex pt-2">
											<p class="font-normal">Approval Id:</p>
											<p class="font-semibold px-2 hover:underline cursor-pointer">{{getTaskInfo(taskhistory.insertedIds)}}</p>
											<p class="font-normal">{{"is Created"}}</p>
										</div>
									</div>
									
								</div>
							
							</div>
						</div>
						<div v-for="document in task.documents" :key="document" class="bg-white p-4 border-b border-lightgrey w-full">
							<div class="initials cursor-pointer">
									<!-- {{ 
										// extractInitials(orgUsersList.find((user)=>{
										// 	return user._id == taskhistory.createdBy
										// }).name) 
									}} -->
								</div>
								<div>
									<p class="font-semibold cursor-pointer text-sm">
										<!-- {{  
											// this.orgUsersList.find((user)=>{
											// 	return user._id == task.createdBy
											// }).name
										}} -->
									</p>
									<p class="font-semibold cursor-pointer text-sm">thgjgfd</p>
								</div>
							<div class="flex pt-2">
									<p class="font-normal">Uploaded file - </p>
									<p class="font-semibold px-2 hover:underline cursor-pointer">{{document.documentName}}</p>
									
								</div>
							</div>
						
					</div>
					
				</div>
				<div class="w-1/4 taskdetails  px-4">
				<h1 class="font py-0.5 mt-2">Risk Details</h1>
				<div class="border-b border-solid border-lightgrey mt-1 rounded "></div>
					<div class="mt-3">
						<label class="controllabel text-xs font-semibold mb-2">Task Status</label>
						<!-- :class="{ 'disabledinputboxstyling':(task.action!=10405 || task.statusCode==10403 ) || (taskObj.assignee == '') , 'inputboxstyling':!((task.action!=10405 || task.statusCode==10403 ) || (taskObj.assignee == ''))} -->

						<select class="custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey" :class="changeBackground()" @change="getTaskStatusInfo()" :disabled='(task.action!=10405 || task.statusCode==10403 ) || (taskObj.assignee == "")' v-model="taskObj.taskStatus" >
							<option value="" selected disabled hidden>Select Option</option>
							<option v-for="pair in taskStatusValue" :key="pair" :value="pair.id">{{pair.value}}</option>
						</select>

					</div>
					
					<div class="mt-3" v-if="task.action == 10405">
						<label class="controllabel text-xs font-semibold mb-2">Assignee</label>
						
						<multiselect
							v-model="taskObj.assignee"
							:options="userOptions"
							:showNoOptions="false"
							@search-change="asyncFind"
							:limit-text="limitText"
							:multiple="false"
							:hide-selected="true"
							:close-on-select="true"
							:clear-on-select="true"
							:disabled="task.statusCode == 10403"
							open-direction="bottom"
							label="label"
							track-by="value"    
							:searchable="true"
							:hideArrow="false"
							placeholder="Assignee"
							class="custom-multiselect userSelect"
							:class="{
								'has-options': userOptions && userOptions.length > 0,
								'no-options': !userOptions.length &&  searchValue !== '',
							}"
						>
							<template v-slot:noResult>
								<span class="text-xs">No Members Found</span>
							</template>
						</multiselect>
					</div>
				<div class="mt-3">
						<label v-if="task&&task.orgId" class="controllabel text-xs font-semibold mb-2">Organization Name</label>
						<label v-else-if="task&&task.systemId" class="controllabel text-xs font-semibold mb-2">System Name</label>
						<label v-else-if="task&&task.processId" class="controllabel text-xs font-semibold mb-2">Process Name</label>
							<input
								type="text"
								disabled
								class="disabledinputboxstyling"
								placeholder="Name"
								v-model="taskObj.Name"
								:class="{
									'is-invalid': v$.taskObj.Name.$error
								}"
							/>
							<div v-if="v$.taskObj.Name.$error" class="text-red-500">
								<div class="error-text text-xs" v-if="v$.taskObj.Name.required.$invalid">Required</div>
							</div>
					</div>
					<div class="mt-3">
						<label class="controllabel text-xs font-semibold mb-2">Severity</label>
							<input
								type="text"
								disabled
								class="disabledinputboxstyling"
								placeholder="Severity"
								v-model="taskObj.severity"
								:class="{
									'is-invalid': v$.taskObj.severity.$error
								}"
							/>
							<div v-if="v$.taskObj.severity.$error" class="text-red-500">
								<div class="error-text text-xs" v-if="v$.taskObj.severity.required.$invalid">Required</div>
							</div>
					</div>
					<div class="mt-3">
						<label class="controllabel text-xs font-semibold mb-2">Impact</label>
							<input
								type="text"
								disabled
								class="disabledinputboxstyling"
								placeholder="Impact"
								v-model="taskObj.impact"
								:class="{
									'is-invalid': v$.taskObj.impact.$error
								}"
							/>
							<div v-if="v$.taskObj.impact.$error" class="text-red-500">
								<div class="error-text text-xs" v-if="v$.taskObj.impact.required.$invalid">Required</div>
							</div>
					</div>
					<div class="mt-3">
						<label class="controllabel text-xs font-semibold mb-2">Likelihood</label>
							<input
								type="text"
								disabled
								class="disabledinputboxstyling"
								placeholder="Likelihood"
								v-model="taskObj.likelihood"
								:class="{
									'is-invalid': v$.taskObj.likelihood.$error
								}"
							/>
							<div v-if="v$.taskObj.likelihood.$error" class="text-red-500">
								<div class="error-text text-xs" v-if="v$.taskObj.likelihood.required.$invalid">Required</div>
							</div>
					</div>
					<!-- <div class="mt-3">
						<label class="controllabel text-xs font-semibold mb-2">Owned By</label>
						<input
							type="text"
							disabled
							class="disabledinputboxstyling"
							placeholder="Owned By"
							v-model="taskObj.ownedBy"
							:class="{
								'is-invalid': v$.taskObj.ownedBy.$error
							}"
						/>
						<div v-if="v$.taskObj.ownedBy.$error" class="text-red-500">
							<div class="error-text text-xs" v-if="v$.taskObj.ownedBy.required.$invalid">Required</div>
						</div>
					</div>
					<div class="mt-3">
						<label class="controllabel text-xs font-semibold mb-2">Managed By</label>
						<input
							type="text"
							disabled
							class="disabledinputboxstyling"
							placeholder="Managed By"
							v-model="taskObj.managedBy"
							:class="{
								'is-invalid': v$.taskObj.managedBy.$error
							}"
						/>
						<div v-if="v$.taskObj.managedBy.$error" class="text-red-500">
							<div class="error-text text-xs" v-if="v$.taskObj.managedBy.required.$invalid">Required</div>
						</div>
					</div> -->
					<div class="mt-3">
						<label class="controllabel text-xs font-semibold mb-2">Risk Owner</label>
						<input
							type="text"
							disabled
							class="disabledinputboxstyling"
							placeholder="Risk Owner"
							v-model="taskObj.riskOwner"
							:class="{
								'is-invalid': v$.taskObj.riskOwner.$error
							}"
						/>
						<div v-if="v$.taskObj.riskOwner.$error" class="text-red-500">
							<div class="error-text text-xs" v-if="v$.taskObj.riskOwner.required.$invalid">Required</div>
						</div>
					</div>
					
					<div class="my-2">
						<label class="controllabel text-xs font-semibold mb-1">Date of Request</label>
						<input
							type="date"
							disabled
							class="disabledinputboxstyling"
							placeholder="DD/MM/YY"
							v-model="taskObj.dateOfRequest"
							:class="{
								'is-invalid': v$.taskObj.dateOfRequest.$error
							}"
						/>
						<div v-if="v$.taskObj.dateOfRequest.$error" class="text-red-500">
							<div class="error-text text-xs" v-if="v$.taskObj.dateOfRequest.required.$invalid">Required</div>
						</div>
						<!-- <p class="mt-1 text-xs font-normal">{{task.createdAt}}</p> -->
					</div>  

					<!-- <div class="flex justify-center items-center">
						<button class="btnprimary my-2" :class="disableSaveTaskObj ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="saveStatus()">Save</button>
					</div> -->
				</div>
				
				<div class="userPopup">
						<div v-if="showTaskModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-indexx justify-center items-center flex">
							<div class="relative my-6 w-full max-w-xl">
								
								<div class="border-0 rounded relative flex flex-col w-full bg-white">
									<!--header-->
									<div class="flex items-start justify-between p-5 rounded">
										<h1>Add Links</h1>
										<button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onCloseLink()">
											<!-- <XIcon class="h-5 w-5" /> -->
											<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
										</button>
									</div>
									<!-- <div class="border-b border-solid border-ightgrey"></div> -->
									<div class="p-2">
										<div class="text-black no-underline mt-0.5 min-h-30 cursor-pointer" >
											<div>
												<hr class="border-t-2" />
												<div class="p-4 flex flex-col mt-3">
													<input type="text" class="inputboxstyling mt-2 w-96 ml-2" v-model="mainLinkName">
												</div>	
												<div class="flex justify-center">
													<button class="mb-4" :class="mainLinkName != undefined && mainLinkName!= '' ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="saveLinks()">Save</button>
													<!-- <button class="btnprimary mb-4" @click="updateControl(controlIndex)" v-if="updateButton">Update</button> -->
												</div>		
											</div>
										</div>	
									</div>
								</div>
							</div>
						</div>
						<div v-if="showTaskModal" class="opacity-40 fixed inset-0 z-50 bg-black"></div>
				</div>
				<popup class="importpopup" v-if="newImportModal" :popUp="newImportModal">
                <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
                    <div class="relative w-full max-w-xl">
                        <!--content-->
                        <div class="border-0 rounded relative flex flex-col w-full bg-white h-screen">
                            <!--header-->
                            <div class="flex items-center justify-between popupheadingcontainer rounded">
                                <h1>Import files</h1>
                                <button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none"  @click="onCancelIcon()">
                                    <!-- <XIcon class="h-5 w-5" /> -->
                                    <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                                </button>
                            </div>
							<!-- <div class="impactcontainer popupbodycontainer">
				<div class="formcontainer flex flex-col">
					<div class="row flex flex-col">
					<div class="upperspacing">
						<label class="controllabel">File Name </label>
						<input class="inputboxstyling mt-2" type="text" v-model="inputFileName" placeholder="Filename"  :class="{'is-invalid': v$.inputFileName.$error }"/>
						<div v-if="v$.inputFileName.$error " class="text-red-500">
							<div class="error-text text-xs" v-if="v$.inputFileName.required.$invalid ">Required</div>
						</div>
					</div>
					<div class="upperspacing">
						<label class="controllabel">Attachment</label>
						<div class="w-auto evidenceHeight bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden mt-2" :class="{'is-invalid':v$.fileToUpload.$error}">
							
							<input type="file" ref="fileInput" class="hidden" id="upload" placeholder="Choose a file to upload" @change="handleFileUpload"/>
							<label for="upload" class="float-right cursor-pointer text-white flex justify-center items-center w-9 h-7 bg-primary"><img src="@/assets/Browse.svg" /></label>
							<div v-if="fileName" class="text-xs p-1">{{ fileName }}</div>
						</div>
					</div>
					
					<div v-if="v$.fileToUpload.$error" class="text-red-500">
						<div v-if="v$.fileToUpload.$invalid" class="error-text text-xs">File upload is required</div>
					</div>
					</div>
				</div>
				</div> -->
                            <div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
                            <div class="impactcontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-col">
										<div class="upperspacing">
											<label class="controllabel">File Name </label>
											<input class="inputboxstyling mt-2" type="text" v-model="inputFileName" placeholder="Filename"  :class="{'is-invalid': v$.inputFileName.$error }"/>
											<div v-if="v$.inputFileName.$error " class="text-red-500">
												<div class="error-text text-xs" v-if="v$.inputFileName.required.$invalid ">Required</div>
											</div>
										</div>
										<div class="upperspacing">
											<label class="controllabel">Attachment</label>
											<div class="w-auto evidenceHeight bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden mt-2" :class="{'is-invalid':v$.fileToUpload.$error}">
												<input  type="file" id="upload" ref="element"  class="hidden" placeholder="Choose a file to upload"  @change="handleFileUpload"/>
												<!-- <input type="file" ref="fileInput" class="hidden" id="upload" placeholder="Choose a file to upload" @change="handleFileUpload"/> -->
												<label for="upload" class="float-right cursor-pointer text-white flex justify-center items-center w-9 h-7 bg-primary"><img src="@/assets/Browse.svg" /></label>
												<div v-if="fileName" class="text-xs p-1">{{ fileName }}</div>
											</div>
										</div>
										
										<div v-if="v$.fileToUpload.$error" class="text-red-500">
											<div v-if="v$.fileToUpload.$invalid" class="error-text text-xs">File upload is required</div>
										</div>
                                        <!-- <div class=" row flex flex-row justify-between upperspacing">
                                            <label htmlFor="upload" class="controllabel">Select File</label>
                                            <input  type="file" id="upload" ref="element"/>
                                        </div> -->
                                        <!-- <div class="border-b border-solid border-lightgrey upperspacing"></div> -->
                                    </div>
                                </div>
                            </div>
                            <!--footer-->
                            <div class="buttonposition flex justify-center items-center">
                                <button :class="(inputFileName.length == 0) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="saveDocuments()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </popup>
				<popup v-if="showApprovalPopup" :popUp="showApprovalPopup">
					<template v-slot:header>
						<div class="flex items-center justify-between popupheadingcontainer rounded">
							<h1>{{riskName}}</h1>

							<button class="float-right text-3xl" @click="onCrossbtn('approval')">
								<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
							</button>
						</div>
					</template>

					<div class="popup-business">
						<div>
							<div>
								<div>
									<div class="statuscontainer popupbodycontainer">
										<div class="formcontainer flex flex-col">
											<div class="row flex flex-row upperspacing">
												<div class=" w-full">
													<label class="controllabel">Name of the Authority</label>
													
													<multiselect
														v-model="taskObj.approval"
														:options="userOptions"
														:showNoOptions="false"
														@search-change="asyncFind1"
														:limit-text="limitText"
														:multiple="false"
														:hide-selected="true"
														:close-on-select="true"
														:clear-on-select="true"
														open-direction="bottom"
														label="label"
														track-by="value"    
														:searchable="true"
														:hideArrow="false"
														placeholder="Approver"
														class="custom-multiselect userSelect"
														:class="{
															'has-options': userOptions && userOptions.length > 0,
															'no-options': !userOptions.length &&  searchValue !== '',
														}"
													>
														<template v-slot:noResult>
															<span class="text-xs">No Members Found</span>
														</template>
													</multiselect>
												</div>
											</div>
											<!-- <div class="border-b border-solid border-lightgrey upperspacing"></div> -->
											<div class="row flex flex-row upperspacing">
												<div class="w-full">
													<label class="controllabel">Justification or Comments for Approving</label>
													<textarea
														class="inputboxstyling"
														placeholder="Comments"
														v-model.trim="approvalComments"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="buttonposition flex justify-center items-center mb-4 mx-0">
								<button class="btnprimary mr-2" :class="(approvalComments==''||taskObj.approval=='')?'btndisabled pointer-events-none':'btnprimary'" @click="onCancelbtn('approval')">Cancel</button>
								<button class="btnprimary" :class="(approvalComments==''||taskObj.approval=='')?'btndisabled pointer-events-none':'btnprimary'" @click="applyAuthority(10408,taskObj)">Save</button>
							</div>
						</div>
					</div>
				</popup>
				<popup v-if="showCloseStatusPopup" :popUp="showCloseStatusPopup">
					<template v-slot:header>
						<div class="flex items-center justify-between popupheadingcontainer rounded">
							<h1>{{riskName}}</h1>

							<button class="float-right text-3xl" @click="onCrossbtn('close')">
								<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
							</button>
						</div>
					</template>

					<div class="popup-business">
						<div>
							<div>
								<div>
									<div class="statuscontainer popupbodycontainer">
										<div class="formcontainer flex flex-col">
											<div class="row flex flex-row upperspacing">
												<div class="w-full">
													<label class="controllabel">Resolution</label>
													<select class="inputboxstyling" @change="getTaskStatusInfo()" v-model="taskObj.taskStatus">
														<option value="" selected disabled hidden>Select Option</option>
														<option v-for="pair in ClosingTaskStatusValue" :key="pair" :value="pair.id">{{pair.value}}</option>
													</select>
												</div>
											</div>
											<div class="border-b border-solid border-lightgrey upperspacing"></div>
											<div class="row flex flex-row">
												<div class="w-full">
													<label class="controllabel">Justification or Comments for Closing</label>
													<textarea
														class="inputboxstyling"
														placeholder="Comments"
														v-model.trim="closingComments"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="buttonposition flex justify-center items-center mb-4 mx-0">
								<button :disabled="clickOnButton" class="btnprimary mr-1.5" @click="onCancelbtn('close')">Cancel</button>
								<button class="btnprimary" :class="(closingComments==''||taskObj.taskStatus=='')?'btndisabled pointer-events-none':'btnprimary'" :disabled="clickOnButton" @click="updatetask(taskObj.taskStatus,'forStatus')">Save</button>
							</div>
						</div>
					</div>
				</popup>
			</div>
		</div>
	</div>
</template>
<style scoped>
/* .z-index{
    z-index: 999;
} */

.taskEditApproval {
	color: #4d4d4d;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 8px;
	padding-left: 8px;
}
.progressbarB {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	z-index: 1;
}
.progressx {
  	background: #152a43 !important;
 	z-index: 999;
  	top: 1px;
  	position: relative;
	border-radius: 4px;
	z-index: 2;
}
.z-indexx{
    z-index: 999;
}
option {
	background-color: #ffffff;
	color: #000000;
	border-color: pink transparent transparent transparent;
}
</style>
<script lang="ts">
import router from '@/router';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { requiredIf } from '@vuelidate/validators';
import { emitter, toast } from '../../main';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import breadCrumb from '@/components/breadCrumb.vue';
import Multiselect from 'vue-multiselect';
import commentsEditor from '@/components/commentsEditor.vue';
import popup from '@/components/popup.vue';
import safeHtml from '@/components/safeHtml.vue';

export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			configData: [],
			showPreview: false,
			singleconfig: [],
			links:[{linkUrl:''}],
			openTab: '',
			comments: '',
			isExpanded: false,
			task: [],
			documentUrl:'',
			documentName:'',
			searchValue: '',
			showTaskModal:false,
			newImportModal:false,
			riskList:[],
			classSort: '',
			systems:[],
            commentsArray:[],
			riskName:null,
			inputFileName: '',
			fileName:'',
			isInputFileNameValid: true,
			fileToUpload: null,
			oldComments:true,
			navBarheading: ['Comments'],
			taskStatusValue: [],
			showApprovalPopup:false,
			showCloseStatusPopup:false,
            clickOnButton: false,
			selectedTabObj: { displayName: 'Details', id: 1 },
			carouselHead: [
                { displayName: 'Details', id: 1 },

                { displayName: 'Attachments', id: 2 },

                // { displayName: 'Sub-Tasks', id: 3 },

                { displayName: 'Links', id: 4 },
				{ displayName: 'Comments', id: 5 },
				{ displayName: 'Approval', id: 6 },
				{ displayName: 'History', id: 7 },
				
            ],
			taskObj:{
				Name:'',
				taskStatus:'',
				severity:'',
				impact:'',
				likelihood:'',
				ownedBy:'',
				managedBy:'',
				riskOwner:'',
				approval:'',
				dateOfRequest:'',
				assignee:'',
			},
			tenants:[],
			insertedIds:[],
			mainDescription:'',
			orgUsersList:[],
			optionsUserArray:[],
			approvingMembers:[],
			riskData: '',
			closingComments:'',
			approvalComments:'',
			selectedTabIndex:1,
			routesArray:[],
			defaultTaskStatus:null,
			mainLinkName:'',
			tasksData:[],
			TaskStatusPresent:'',
			dataLoading:true,
			risksSeverityValue: [
                {
                    id: 1,
                    value: 'Very Low'
                },
                {
                    id: 2,
                    value: 'Low'
                },
                {
                    id: 3,
                    value: 'Moderate'
                },
                {
                    id: 4,
                    value: 'High'
                },
                {
                    id: 5,
                    value: 'Very High'
                }
            ],
			ClosingTaskStatusValue: [
                {
                    id: 10403,
                    value: 'Fixed / Done'
                },
                {
                    id: 10410,
                    value: " Wont'do / Can't Fix "
                },
            ],
			taskHistoryObject:[],
			businessProcessList:[],
            businessEntitiesList:[],
			dummyTaskObj:{},
			userOptions:[]
			
		};
	},
	components: {
        breadCrumb,
		Multiselect,
		popup,
		commentsEditor,
		safeHtml
    },
	computed: {
		...mapGetters({ userInfo: 'userInfo',dbSeverity:'dbSeverity',dbLikelihood:'dbLikelihood' }),
		disableComments: function (): any {
			let result;
			if(this.comments == ''){
				result = true;
			}else{
				result = false;
			}
			return result;
		},
		disableSaveTaskObj: function (): any {
			let result = true;
			for (const key in this.taskObj) {
				if(this.taskObj[key] !== ''){
					result = false;
					break;
				}
			}
			return result;
		},
		
	},
	validations() {
		return {
		// comments:{required},
			inputFileName:{required},
			fileToUpload: { required },
			taskObj:{
				Name:{required},
				taskStatus:{required},
				severity:{required},
				impact:{required},
				likelihood:{required},
				// ownedBy:{required},
				// managedBy:{required},
				riskOwner:{required},
				dateOfRequest:{required},
			}
		}
        
		},
	methods: {
		onOpenLinkTab(){
			this.showTaskModal =true
			this.mainLinkName = ''
		},
		onCloseLink(){
			this.showTaskModal=false
			this.mainLinkName = ''
		},
		onCancelIcon(){
			this.newImportModal =!this.newImportModal
			this.inputFileName = ''
			this.fileName = ''
			this.v$.$reset();
		},
		limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any){
			this.searchValue = query
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		handleFileUpload(event: any) {
            const file = this.$refs.element.files[0];
            if (file) {
                this.fileName = file.name;
                this.fileToUpload = file
                console.log("file",file)
                 const fileNameParts = file.name.split('.');
                // console.log('fileNameParts1',fileNameParts)
                fileNameParts.pop(); // Remove the extension
                // console.log('fileNameParts2',fileNameParts)
                const nameWithoutExtension = fileNameParts.join('.');
                // console.log('fileNameParts',nameWithoutExtension)
                if (this.inputFileName.trim() === '') {
                this.inputFileName = nameWithoutExtension;
                }
                // console.log('inputFileName',this.inputFileName)
            } else {
                this.fileName = '';
                this.inputFileName = '';
                console.log('inputFileName',this.inputFileName)
            }
        },
		asyncFind1(query:any){
			this.searchValue = query
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.approvingMembers.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		addLink(){
			this.links.push({linkUrl:""})
		},
		clearComments(){
			this.comments='';
			this.v$.$reset();
		},
		getTaskStatusInfo(){
			console.log('ghjkjhghjkjk')
			// if(this.taskObj.taskStatus == 10408){
			// 	this.showApprovalPopup = true;
			// }else
			 if(this.taskObj.taskStatus == 10403){
				this.showCloseStatusPopup = true;
			}
		},
		ApprovalTabDetails(){
			this.showApprovalPopup = true;
		},
		changeBackground() {
			if (this.taskObj.taskStatus == 10411) {
				return 'bg-disabledgrey text-white';
			} else if (this.taskObj.taskStatus == 10409) {
				return 'bg-yellow text-white';
			} else if (this.taskObj.taskStatus == 10402) {
				return 'bg-electricGreen text-white';
			} else if (this.taskObj.taskStatus == 10403) {
				return 'bg-lightgreen text-white';
			} else if (this.taskObj.taskStatus == 10401) {
				return 'bg-darkred text-white';
			} else if (this.taskObj.taskStatus == 10408) {
				return 'bg-primary text-white';
			} else {
				return '';
			}
		},
		onCrossbtn(state:any){
			if(state == 'approval'){
				this.showApprovalPopup = false;
				this.taskObj.taskStatus = this.task.statusCode;
				this.approvalComments = '';
				this.taskObj.approval = '';
			}else{
				this.showCloseStatusPopup = false;
				this.taskObj.taskStatus = this.defaultTaskStatus;
				this.closingComments = '';
			}
		},
		convertToPlainText(html:any) {
			var tempDiv = document.createElement("div");
			tempDiv.innerHTML = html;

			return tempDiv.textContent || tempDiv.innerText || "";
		},
		onCancelbtn(state:any){
			if(state == 'approval'){
				this.approvalComments = '';
				this.taskObj.approval = '';
			}else{
				this.taskObj.taskStatus = '';
				this.closingComments = '';
			}
		},
		getTaskInfo(taskIdNumber:any){
            let mainTask = this.tasksData.find((taskObj:any)=>{
                return taskObj._id == taskIdNumber;
            })
            if(mainTask != undefined){
                let formattedId = this.formatTaskId(mainTask.taskId);
                return formattedId;
            }else{
                return ''
            }
        },
		formatTaskId(taskId:any) {
            return taskId.toString().padStart(4, '0');
        },
		async applyAuthority(taskStatus:any,obj:any){
			// console.log("this.taskObj",this.taskObj)
			this.taskObj.comments = this.approvalComments;
			this.showApprovalPopup = false;
			 let payload:any = [
                {
                    mappedTaskId: this.$route.params.taskId,
                    createdAt: new Date(),
                    createdBy: this.userInfo._id,
                    action: 10408,
					comments:obj.comments,
					approvalFor:obj.approval.value
                }
            ];
			await this.createWorkFlowTask(payload);
			
		},
		 async createWorkFlowTask(payload: any){
            try {
                //    payload.createdAt=new Date(),
                //    payload.createdBy=this.userInfo._id,
                // ${process.env.VUE_APP_API_URL}
                await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/task`, payload).then((result: any) => {
                    this.insertedIds = result.data
                    
                });
                payload[0].insertedIds = this.insertedIds;
				await this.updatetask(10408,'forApproval')

            } catch (e) {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
		toggleCommentHeight(comment:any) {
			comment.isExpanded = !comment.isExpanded;
		},
		toggleTabIds(tabId:any){
			this.selectedTabIndex = tabId.id;
			sessionStorage.setItem('selectedTab', tabId.id);
            this.selectedTabObj = tabId;
		},
		toggleTabs(navIndex: any) {
			this.openTab = navIndex;
			this.filteredCourses = [];
		},
		sortDates(){
			if(this.oldComments){
				this.commentsArray.sort().reverse();
				this.taskHistoryObject.sort().reverse();
				this.oldComments = false;
			}else{
				this.commentsArray.sort().reverse();
				this.taskHistoryObject.sort().reverse();
				this.oldComments = true;
			}
		},
		extractInitials(name:any) {
            const names = name.split(' ');
            return names.map((name:any) => name[0]).join('').toUpperCase();
        },
		formatTime(time:any){
            let now = moment(time);
			return now.format('DD-MM-YYYY, hh:mm:ss');
        },
		addDocuments(){
			this.newImportModal=true
			// this.$refs.file.click();
		},
		 fileName  (url: any) { 
			console.log("dddd",url)
			return url.substring(url.lastIndexOf('/') + 1)
			},
		async saveDocuments(){
			this.v$.fileToUpload.$touch()
			if(this.fileToUpload){
			await this.sendData(this.$refs.element.files[0])
			console.log("this.documentUrl",this.documentUrl)
			let a = {documentUrl:this.documentUrl,documentName:this.inputFileName}
			this.$http
				.post(`${process.env.VUE_APP_RISK_API_URL}/task/${this.$route.params.taskId}/update?documents`, a)
				.then(async (response: any) => {
					if (response.status == 200) {
						toast.info(`Saved`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
					await this.getSingleTask();
				})
				.catch((error: any) => {
					toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.newImportModal = false;
				this.fileName = '';
				this.inputFileName = '';
			}
		},
		backtoTasks(){
			this.$router.push({name:'tasks'})	
		},
		getRiskLabel(num:any,key:any){
			let obj:any 
			if(key == 'impact' || key=='severity'){
				obj = this.dbSeverity.find((object:any)=>{return object.value == num})
			}else if(key=='likelihood'){
				obj = this.dbLikelihood.find((object:any)=>{return object.value == num})
			}
			
			return obj.key;
		},
		async getAllTasks() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.tasksData = result.data;
            });
        },
		async getSingleTask() {
			await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/task/${this.$route.params.taskId}/get`).then((res: any) => {
				this.task = res.data[0];
				console.log("ssss",this.task)

				if(Object.keys(this.task).includes('systemId') && this.task.tier == 3){
					let findObj = this.systems.find((obj:any)=>{return obj._id == this.task.systemId;})
					console.log('Ca,eeeee',this.systems)
					if(findObj!=undefined){
						this.taskObj.Name = findObj.systemName;
					} else {
						this.taskObj.Name = 'N/A';
					}

				}else if(Object.keys(this.task).includes('processId') && this.task.tier == 2){
					let findObj = this.businessProcessList.find((obj:any)=>{return obj._id == this.task.processId;})
					if(findObj!=undefined){
						this.taskObj.Name = findObj.businessProcessName;
					}else {
						this.taskObj.Name = 'N/A';
					}

				}else if(Object.keys(this.task).includes('orgId') && this.task.tier == 1){
					let findObj = this.businessEntitiesList.find((obj:any)=>{return obj._id == this.task.orgId;})
						if(findObj!=undefined){
							this.taskObj.Name = findObj.entityName;
						} else {
							this.taskObj.Name = 'N/A';
						}
					// console.log("this.taskObj.Namethis.taskObj.Name",findObj)

				}

				if(Object.keys(this.task).includes('comments') && this.task.comments.length>0 && (typeof(this.task.comments)=="object")){
					this.commentsArray=[];
					this.task.comments.map((obj:any)=>this.commentsArray.push(obj));
					this.oldComments = true;
				}else{
					this.commentsArray=[];
				}
				
				if(this.task.action == 10405){
					this.taskStatusValue = [
					{id: 10401,value: 'Open'},
					{id:10408,value:'Approval'},
					{id:10402,value: 'In Progress'},
					{id:10409,value:'In Review'},
					{id:10403,value: 'Closed'}
				]
				}else{
					this.taskStatusValue = [{id: 10401,value: 'Open'},
					{id:10408,value:'Pending'},
					{id:10410,value:'Rejected'},
					{id: 10403,value: 'Approved'}]
				}
				
				this.taskObj.taskStatus = this.task.statusCode;
				if(Object.keys(this.task).includes('approval')){
					this.taskObj.approval = this.task.approval;
				}
				if(Object.keys(this.task).includes('assignee')){
					let findUserObj = this.optionsUserArray.find((obj:any)=>{return obj.value == this.task.assignee});
					this.taskObj.assignee = (findUserObj != undefined) ? findUserObj : '';
				}
				this.taskObj.dateOfRequest = new Date().toISOString().slice(0, 10);

				this.defaultTaskStatus = this.task.statusCode
			});
		},

		async updatetask(statusCode:any,text:any){
			this.clickOnButton =true
				let payload:any;
				if(statusCode==10408){
					payload={
						statusCode:statusCode,
						comments:this.taskObj.comments,
						taskStatus:statusCode,
						createdAt:new Date(),
						createdBy:this.userInfo._id,
						action:10405, 
						approval:[this.taskObj.approval.value],
						assignee:this.taskObj.assignee.value,
						insertedIds: this.insertedIds,
						mappedTaskId : this.$route.params.taskId
					}
					if(text=='forApproval'){
						payload.approveTask = true;
					}else{
						payload.approveTask = false;
					}

				}else if(statusCode==10402 || statusCode==10409 || statusCode==10401){
					payload={
						statusCode:statusCode,
						comments:'',
						createdAt:new Date(),
						createdBy:this.userInfo._id,
						taskStatus:statusCode,
						action:10405,
						assignee:this.taskObj.assignee.value
					}

				}else{
					payload={
						statusCode:10403, 
						comments:this.closingComments,
						createdAt:new Date(),
						createdBy:this.userInfo._id,
						action:10405, 
						assignee:this.taskObj.assignee.value
					}

					if(statusCode == 10403){
						payload.taskStatus = 10403;
					}else if(statusCode == 10410){
						payload.taskStatus = 10410;
					}
				}
				
			this.showTaskModal=false
			try{
				await this.$http
				.post(`${process.env.VUE_APP_RISK_API_URL}/task/${this.$route.params.taskId}/check`, [payload])
				.then((response: any) => {
					if (response.status == 200) {
						toast.info(`Saved`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
					this.showCloseStatusPopup = false;
					this.showApprovalPopup = false;
					
					// this.$router.push({ name: 'myTasks' });
				})
				
			} catch(error: any) {
					toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
				await this.getRisksList();
				await this.getAllTasks();
				await this.getSingleTask();
				this.dummyTaskObj = {...this.taskObj}
				await this.getTaskHistory()
				this.comments ='';
				this.clickOnButton = false
		
		},
		async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id:org._id, name: org.organizationName }
                    this.tenants.push(obj);
                });
            });
        },
		async getallUsers() {
            
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                    
					if(this.userInfo._id != this.orgUsersList[i]._id){
						this.approvingMembers.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
					}
                }
            });
        },
		async getRisksList() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risks/getall`).then((result: any) => {     
            this.riskList = result.data;
			
			for (let i = 0; i < this.riskList.length; i++) {
					this.openList = this.riskList[i];

					let condition1 = (Object.keys(this.riskList[i]).includes('residual') && this.riskList[i]['residual'].length>0)
                    let averageImpact;
                    let impact;
                    let severity;

					if(condition1 == true){
                        let lengthOfArray = this.riskList[i]['residual'].length
                        let indexObj = this.riskList[i]['residual'][lengthOfArray-1];
                        
                        if(indexObj.isActive == true){
                            averageImpact = this.average([ indexObj['business'], indexObj['operations'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);
							let arr = ['business','operations','legal','financial','likelihood']
                            for(let key of arr){
                                this.riskList[i][key] = indexObj[key];
                            }
                        }else{
                            averageImpact = this.average([ this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    }else if(condition1 == false){
                        averageImpact = this.average([ this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    this.riskList[i].impact = impact;
                    this.riskList[i].severity = severity;
					
					// console.log('this.risklist[i]', this.riskList[i]);
				}
            });
			// console.log("this.taskthis.task",this.task)

			let riskObj = this.riskList.find((risk:any)=>{

				if(Object.keys(risk).includes('systemId') && risk.tier == 3){
					return ((this.task.mappedRisk == risk.mappedRisk)&&(risk.systemId==this.task.systemId));

				}else if(Object.keys(risk).includes('processId') && risk.tier == 2){
					return ((this.task.mappedRisk == risk.mappedRisk)&&(risk.processId==this.task.processId))

				}else if(Object.keys(risk).includes('orgId') && risk.tier == 1){
					return ((this.task.mappedRisk == risk.mappedRisk)&&(risk.orgId==this.task.orgId));
				}

			});

			
			if(riskObj != undefined){
				let owner = this.optionsUserArray.find((user:any)=>{return user.value == riskObj.riskOwner});
			
				this.riskData = riskObj
				this.riskName = riskObj.risk;
				this.mainDescription = riskObj.comments;

				this.taskObj.severity = this.getRiskLabel(riskObj.severity,'severity');
				this.taskObj.impact = this.getRiskLabel(riskObj.impact,'impact');
				this.taskObj.likelihood = this.getRiskLabel(riskObj.likelihood,'likelihood');
				this.taskObj.riskOwner = owner != undefined ? owner.label : 'N/A';
			}else{
				this.taskObj.severity = 'N/A'
				this.taskObj.impact = 'N/A'
				this.taskObj.likelihood = 'N/A'
				this.taskObj.riskOwner = 'N/A'
			}
        },
		average(arr: any[]) {
            return arr.reduce((p, c) => p + c, 0) / arr.length;
        },
		colorTaskText(Status: any) {
            if (Status == 10401) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (Status == 10403) {
                return ['text-yellow', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-yellow'];
            } else if (Status == 10402) {
                return ['text-primary', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-primary'];
            }
        },
         colorTaskBg(Status: any) {
            if (Status == 10401) {
                return ['bg-darkred', 'opacity-10'];
            } else if (Status == 10403) {
                return ['bg-yellow', 'opacity-10'];
            } else if (Status == 10402) {
                return ['bg-primary', 'opacity-10'];
            }
        },
		riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
		saveLinks(){
			this.showTaskModal=false;
			let payload:any = [{linkUrl:this.mainLinkName}]
			this.$http
				.post(`${process.env.VUE_APP_RISK_API_URL}/task/${this.$route.params.taskId}/update?links`,payload)
				.then(async (response: any) => {
					if (response.status == 200) {
						toast.info(`Saved`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
					await this.getSingleTask();
				})
				.catch((error: any) => {
					toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.comments =''
		},
		updateComments() {
			// console.log('userInfo', this.userInfo);
			
			const payload: Record<string, any>  = { user: this.userInfo.name, comments: this.comments };
			let now = moment(payload.createdAt);
			payload.createdAt = now.format('DD-MM-YYYY, HH:mm:ss');
			// console.log("updated comment payload",payload)

			this.$http
				.post(`${process.env.VUE_APP_RISK_API_URL}/task/${this.$route.params.taskId}/update?comments`, payload)
				.then(async (response: any) => {
					if (response.status == 200) {
						toast.info(`Saved`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
					await this.getSingleTask();
				})
				.catch((error: any) => {
					toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.comments ='';
		},
		async getSystems() {
            let entityId='20005'
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
            });
			// let mappedSystem = this.systems.find((system:any)=>{return this.task.systemId == system._id});
			
			// this.taskObj.systemName = mappedSystem.systemName;
			
			// let mainTenant = this.tenants.find((ten:any)=>{return ten.id == mappedSystem.ownedBy});
			// if(mainTenant == undefined){
			// 	this.taskObj.ownedBy = mappedSystem.ownedBy;
			// }else{
			// 	this.taskObj.ownedBy = mainTenant.name;
			// }
			
			// let mainUser = this.optionsUserArray.find((user:any)=>{return user.value==mappedSystem.managedBy})
			// if(mainUser == undefined){
			// 	this.taskObj.managedBy = mappedSystem.managedBy;
			// }else{
			// 	this.taskObj.managedBy = mainUser.label;
			// }
        },

		saveStatus() {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				console.log("tasks",this.task)
				let id:any
				if(this.task.action==10403){
					id=10403
				}else if(this.task.action==10405){

					id=10405
				}
				
				else{
					id=10407
				}
				
				let payload={taskId:this.$route.params.taskId,systemId:this.task.systemId,riskId:this.task.mappedRisk, createdAt:new Date(), action:id , createdBy:this.userInfo._id,
				previousStatus:this.riskData.statusCode}
					this.$http
					.post(`${process.env.VUE_APP_RISK_API_URL}/task/${this.$route.params.taskId}/update?status`, payload)
					.then(async (response: any) => {
						if (response.status == 200) {
							toast.info(`Saved`, {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
						}
						await this.getSingleTask();
						this.v$.$reset();
					})
					.catch((error: any) => {
						toast.error(`Save failed with ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
				this.comments = '';
			}
		},
		async getBusinessProcess(){
            this.businessProcessList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                this.businessProcessList = result.data;
            })
        },
        async getBusinessEntities(){
            this.businessEntitiesList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                this.businessEntitiesList = result.data;
            })
        },
		
		async uploadImage (data:any) {
			return new Promise(resolve => {
			const reader:any = new FileReader()
			reader.onload = function () {
			resolve(reader.result.replace('data:', '')
			.replace(/^.+,/, '')) 
			}
			reader.readAsDataURL(data)
			})
		},

		async getTaskHistory() {
			await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/task/${this.$route.params.taskId}/history`).then((res: any) => {
				res.data.map((history: any) => {

					this.taskHistoryObject.push(history.taskHistory)
				
				});
				// console.log("hello this is tenanats",this.taskHistoryObject)
			});
        },
		async sendData(data:any){
			this.spinnerLoading =true
			// console.log("$refs.fileInput.files[0]",this.$refs.fileInput.files[0])
			const imageFile = await this.uploadImage(data);
			console.log("data.name",data.name);
			let fileExtension = data.name.split('.').pop()
			let id = uuidv4();
			let payload ={fileData:imageFile,name:`${id}.${fileExtension}`}
			let folderName ="risk"
			try {
			await this.$http
			.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${folderName}/file/upload`, payload)
				// .post(`http://localhost:4030/services/filemanagement/bucket/${folderName}/file/upload`, payload)
				// bucket/:folderName/file/upload
				.then((response: any) => {
			if (response.status == 200) {
					console.log(response.data)
					this.documentUrl = response.data;
					this.documentName = data.name;
					this.spinnerLoading=false
				} 
				// if (response.status == 200) {
				// 				console.log(response.data);
				// 				let payload: any = {
				// 					// name: `${uuid}.${fileExtension}`,
				// 					url: response.data,
				// 					filename:this.inputFileName
				// 				};
				// 				await this.updateTemplateTask(2, payload);
				// 				this.inputFileName = ''
                //                 this.fileName = ''
                //                 this.v$.$reset();
				// 			}
			else {
					console.log('response status', response.status);
				}
			})
			} catch (err) {
			console.error(err);
			}
		},
	},
	async created(){
		let savedTabKey: any = sessionStorage.getItem('selectedTab');
        console.log("savedTabKey",savedTabKey)
		if (savedTabKey) {
            savedTabKey = parseInt(savedTabKey)
		    const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
            console.log("savedTab", savedTab)
            if (savedTab) {
                await this.toggleTabIds(savedTab);
            }else{
                this.selectedTabObj = { displayName: 'Details', id: 1 },
                this.selectedTabIndex = 0;
            }
		}
		else{
			this.selectedTabObj = { displayName: 'Details', id: 1 },
			this.selectedTabIndex = 0;
            await this.toggleTabIds(this.selectedTabObj);
		}
	},
	async mounted() {

		this.dataLoading = true;
		await this.getAllTenants();
		await this.getallUsers();
		await this.getSystems();
		await this.getBusinessProcess();
        await this.getBusinessEntities();

		await this.getAllTasks()
		await this.getSingleTask();
		
		await this.getRisksList();
		this.dummyTaskObj = {...this.taskObj}

		await this.getTaskHistory();

		this.dataLoading = false;
		let taskId = this.$route.params.id;
		if(Object.keys(this.$route.query).includes('approvals')){
			this.routesArray = [{name:'Approvals',routeName:'approvals'},{name:'Task-Edit',routeName:'riskName',id:`${taskId}`}]
		}else{
			this.routesArray = [{name:'My-Tasks',routeName:'myTasks'},{name:'Task-Edit',routeName:'riskName',id:`${taskId}`}];
		}
        
		// this.toggleTabs(0);
		emitter.off('addedComments');
		emitter.on('addedComments',(data: any)=>{
			console.log("data",data)
			this.comments = data.comments
			this.updateComments()
		})
		
	},
	beforeUnmount() {
        sessionStorage.removeItem('selectedTab')
    }
	
});



// function uuidv4() {
//   throw new Error('Function not implemented.');
// }
</script>