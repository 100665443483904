<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="items-center justify-between flex">
            <div class="title flex items-center">
                <h1 class="title">Information</h1>
            </div>
            <div class="flex items-center">
                <!-- v-if="userInfo.currentRole==10207" -->
                <button class="btnprimary ml-2" @click="importDoc()">Import</button>
                <button class="btnprimary ml-2" v-if="pageType" @click="gotoInfoMapping()">Map</button>
                <input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" />
                <button class="btnprimary ml-2 flex items-center" @click="showInformationPopup">Add</button>
                <button class="ml-2 flex" @click="filterOpen" :class="tableRow.length == 0?'btndisabled pointer-events-none':'filterButton'">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        
        <div class="mt-3 text-xs">
            <div>
                This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
                to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
            </div>
        </div>
        
        <div>
            <newTable :tableRowData="tableRow" :headersData="headersRoot" :navigate="true" :openPopup="false" :loadData="dataLoading"></newTable>
            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>
        </div>
       
        <div>
            <popup v-if="showUserModal" :popUp="showUserModal"> 
                <template v-slot:header>
                    <div class="flex items-center justify-between popupheadingcontainer rounded">
                        <h1>Information</h1>
                        <button
                            class="float-right text-3xl"
                            @click="
                                onCancelbtn();
                                this.showUserModal = false;
                            "
                        >
                            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                        </button>
                    </div>
                </template>
              <!-- <fieldset :disabled="userInfo.currentRole!=10207"> -->
              <fieldset>
                  <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="w-full upperspacing">
                                    <label class="controllabel">Information Category</label>
                                    <div>
                                        <div v-if="pageType">
                                            <multiselect
                                                v-model="information.informationCategory"
                                                :options="infoCategoryList" 
                                                :searchable="true" 
                                                :close-on-select="true"
                                                :show-labels="false"
                                                :hide-selected="true"
                                                placeholder="Information Category"
                                                open-direction="bottom"
                                                class="custom-multiselect"
                                                :class="{
                                                    'is-invalid borderRed': v$.information.informationCategory.$error,
                                                    'has-options': infoCategoryList && infoCategoryList.length > 0,
                                                    'no-options':  !infoCategoryList.length,
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Categories Found</span>
                                                </template>
                                            </multiselect>
                                        </div>
                                        <div v-else>
                                            <multiselect
                                                v-model="information.informationCategory"
                                                :options="subInfoCategoryList" 
                                                @search-change="asyncCategoryFind"
                                                :searchable="true" 
                                                :close-on-select="true"
                                                :show-labels="false"
                                                :hide-selected="true"
                                                :showNoOptions="false"
                                                placeholder="Information Category"
                                                open-direction="bottom"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid borderRed': v$.information.informationCategory.$error,
                                                    'has-options': subInfoCategoryList && subInfoCategoryList.length > 0,
                                                    'no-options': !subInfoCategoryList.length &&  searchValue !== '',
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Categories Found</span>
                                                </template>
                                            </multiselect>
                                        </div>

                                        <div v-if="v$.information.informationCategory.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.informationCategory.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Information</label>
                                        <div>
                                        <div v-if="pageType">
                                             <multiselect 
                                                v-model="information.information"
                                                :options="infoList" 
                                                :searchable="true"
                                                :disabled="information.informationCategory==''" 
                                                :close-on-select="true"
                                                :show-labels="false"
                                                :hide-selected="true"
                                                placeholder="Information"
                                                open-direction="bottom"
                                                class="custom-multiselect"
                                                :class="{
                                                        'is-invalid borderRed': v$.information.information.$error,
                                                        'has-options': infoList && infoList.length > 0,
                                                        'no-options':  !infoList.length,
                                                    }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Information Found</span>
                                                </template>
                                            </multiselect>
                                        </div>
                                        <div v-else>
                                            <multiselect
                                                v-model="information.information"
                                                :options="subInfoList" 
                                                @search-change="asyncInformationFind"
                                                :searchable="true"
                                                :disabled="information.informationCategory==''"  
                                                :close-on-select="true"
                                                :show-labels="false"
                                                :hide-selected="true"
                                                :showNoOptions="false"
                                                placeholder="Information"
                                                open-direction="bottom"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid borderRed': v$.information.information.$error,
                                                    'has-options': subInfoList && subInfoList.length > 0,
                                                    'no-options': !subInfoList.length &&  searchValue !== '',
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No Information Found</span>
                                                </template>
                                            </multiselect>
                                        </div>
                                        
                                        <div v-if="v$.information.information.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.information.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="pageType">
                                        <label class="controllabel">Information Owner</label>
                                        <multiselect
                                            v-model="information.informationOwner"
                                            :options="userOptions"
                                            :showNoOptions="false"
                                            @search-change="asyncFind"
                                            :limit-text="limitText"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Info Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.information.informationOwner.$error,
                                                'has-options': userOptions && userOptions.length > 0,
                                                'no-options': !userOptions.length &&  searchValue !== '',
                                            }"
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Members Found</span>
                                            </template>
                                        </multiselect>

                                        <div v-if="v$.information.informationOwner.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.informationOwner.required.$invalid">Required</div>
                                        </div>

                                        <!-- <Multiselect
                                            v-model="information.informationOwner"
                                            :options="optionsUserArray"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{singleLabelText:'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full'}"
                                            :canClear='false'
                                            :caret='false'
                                            placeholder="Info Owner"
                                            :class="{
                                                'is-invalid borderRed': v$.information.informationOwner.$error,
                                            }"
                                        ></Multiselect>

                                        <div v-if="v$.information.informationOwner.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.informationOwner.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                    <div class="w-6/12" :class="pageType ? 'leftspacing' : ''">
                                        <label class="controllabel">Confidentiality</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="inputboxstylingForSelect"
                                            v-model="information.confidentiality"
                                            :class="{
                                                'is-invalid': v$.information.confidentiality.$error,'inputboxstylingForSelectAtPopup': information.confidentiality === '','stylingForInputboxstylingForSelectAtPopup': information.confidentiality !== ''  
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option class="optionColors" v-for="obj in dbConfidentiality" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>
                                        <div v-if="v$.information.confidentiality.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.confidentiality.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing" v-if="!pageType">
                                        <label class="controllabel">Integrity</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="inputboxstylingForSelect"
                                            v-model="information.integrity"
                                            :class="{
                                                'is-invalid': v$.information.integrity.$error,'inputboxstylingForSelectAtPopup': information.integrity === '','stylingForInputboxstylingForSelectAtPopup': information.integrity !== ''  
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option class="optionColors" v-for="obj in dbIntegrity" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>
                                        <div v-if="v$.information.integrity.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.integrity.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="pageType">
                                        <label class="controllabel">Integrity</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="inputboxstylingForSelect"
                                            v-model="information.integrity"
                                            :class="{
                                                'is-invalid': v$.information.integrity.$error,'inputboxstylingForSelectAtPopup': information.integrity === '','stylingForInputboxstylingForSelectAtPopup': information.integrity !== ''  
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option class="optionColors" v-for="obj in dbIntegrity" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>
                                        <div v-if="v$.information.integrity.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.integrity.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" :class="pageType ? 'leftspacing' : ''">
                                        <label class="controllabel">Availability</label>
                                        <select
                                            v-on:change="getMaxIdValue()"
                                            class="inputboxstylingForSelect"
                                            v-model="information.availability"
                                            :class="{
                                                'is-invalid': v$.information.availability.$error,'inputboxstylingForSelectAtPopup': information.availability === '','stylingForInputboxstylingForSelectAtPopup': information.availability !== ''  
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option class="optionColors" v-for="obj in dbAvailability" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>
                                        <div v-if="v$.information.availability.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.availability.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" v-if="!pageType">
                                        <div class="w-full leftspacing" v-if="information.classification != '' && addInfo">
                                            <label class="controllabel">Classification</label>
                                            <div v-for="(dataValue, indexNum) in classificationSelect" :key="indexNum">
                                                <div v-if="information.classification == dataValue.id">
                                                    <input type="text" class="disabledinputboxstyling" disabled v-model="dataValue.status" placeholder="Classification" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full leftspacing">
                                            <label class="controllabel">Classification</label>
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="information.classification"
                                                :class="{
                                                    'is-invalid': v$.information.classification.$error,'inputboxstylingForSelectAtPopup': information.classification === '','stylingForInputboxstylingForSelectAtPopup': information.classification !== ''  
                                                }"
                                            >
                                                <option value="" selected disabled hidden>Please select one</option>

                                                <option class="optionColors" v-for="option in dbInformationClassification" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>

                                            <div v-if="v$.information.classification.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.information.classification.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12" v-if="pageType">
                                        <div class="w-full" v-if="information.classification != '' && addInfo">
                                            <label class="controllabel">Classification</label>
                                            <div v-for="(dataValue, indexNum) in classificationSelect" :key="indexNum">
                                                <div v-if="information.classification == dataValue.id">
                                                    <input type="text" class="disabledinputboxstyling" disabled v-model="dataValue.status" placeholder="Classification" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full">
                                            <label class="controllabel">Classification</label>
                                            <select
                                                class="inputboxstylingForSelect"
                                                v-model="information.classification"
                                                :class="{
                                                    'is-invalid': v$.information.classification.$error,'inputboxstylingForSelectAtPopup': information.classification === '','stylingForInputboxstylingForSelectAtPopup': information.classification !== ''  
                                                }"
                                            >
                                                <option value="" selected disabled hidden>Please select one</option>

                                                <option class="optionColors" v-for="option in dbInformationClassification" :key="option" :value="option.value">
                                                    {{ option.key }}
                                                </option>
                                            </select>

                                            <div v-if="v$.information.classification.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.information.classification.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12" :class="pageType ? 'leftspacing' : ''" v-if="information.securityCategory != '' && !addInfo">
                                        <div v-for="(dataValue, indexNum) in dbSecurityCategory" :key="indexNum">
                                            <div v-if="information.securityCategory == dataValue.value">
                                                <label class="controllabel">Security Category</label>
                                                <input type="text" class="disabledinputboxstyling" disabled v-model="dataValue.key" placeholder="Security Category" />
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <!-- <div class="row flex flex-row" v-if="pageType">
                                    <div class="w-full p-2">
                                        <label class="controllabel">System(s)</label>
                                        <Multiselect
                                            v-model="information.systems"
                                            mode="tags"
                                            :close-on-select="false"
                                            :create-option="true"
                                            :searchable="true"
                                            :disabled="userInfo.currentRole!=10207"
                                            :options="allSystems"
                                            placeholder="Select"
                                            :classes="{
                                                container: 'relative h-fit mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-lightgrey rounded bg-white text-base leading-snug outline-none',
                                            }"
                                            :class="{
                                                'is-invalid borderRed': v$.information.systems.$error
                                            }"
                                        />
                                        <div v-if="v$.information.systems.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.systems.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row" v-if="pageType">
                                    <div class="w-full p-2">
                                        <label class="controllabel">Process(s)</label>
                                        <Multiselect
                                            v-model="information.process"
                                            mode="tags"
                                            :close-on-select="false"
                                            :create-option="true"
                                            :searchable="true"
                                            :disabled="userInfo.currentRole!=10207"
                                            :options="businessProcessList"
                                            placeholder="Select"
                                            :classes="{
                                                container: 'relative h-fit mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-lightgrey rounded bg-white text-base leading-snug outline-none',
                                            }"
                                            :class="{
                                                'is-invalid borderRed': v$.information.process.$error
                                            }"
                                        />
                                        <div v-if="v$.information.process.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.process.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                <!-- <div class="buttonposition flex justify-center items-center mb-4 mx-0" v-if="userInfo.currentRole==10207"> -->
                    <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn(information)">Cancel</button>
                        <button :disabled="clickOnButton" @click="addOrUpdateInformation()" v-if="!information._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-else @click="addOrUpdateInformation(information)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                    </div>
                
                
              </fieldset>
            </popup>
        </div>
    </div>
</template>
<style scoped>
.borderRed{
    border: 1px solid red !important;
}
.multiselect-wrapper {
    min-height: 20px !important;
}
.multiselect-tags-search-wrapper {
    margin: 0px !important;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { required, maxLength, requiredIf } from '@vuelidate/validators';
import popup from '@/components/popup.vue';
import { emitter, toast } from '../../main';
import newTable from '@/components/newTable.vue'
import useVuelidate from '@vuelidate/core';
import multiselect from 'vue-multiselect';
import toolTip from '@/components/toolTip.vue'

import moment from 'moment';
import { filter } from 'lodash';
import { omit } from 'lodash';
import { mapGetters } from 'vuex';

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            mandatoryKeys: ['informationCategory', 'information', 'confidentiality', 'integrity', 'availability','classification'],
            fullKeys: ['informationCategory', 'information', 'confidentiality', 'integrity', 'availability','classification'],
            savedInformationSets: [],
            notImportedArray: [],
            orgUsersList: [],
            optionsUserArray: [],
            infoList: [],
            subInfoList:[],
            infoCategoryList: [],
            subInfoCategoryList:[],
            showFilters: false,
            clickOnButton: false,
            filter: {},
            searchList: [],
            classSort: '',
            className: '',
            pageType: '',
            pop: false,
            xPos: '',
            yPos: '',
            selected: '',
            popUp: false,
            pageSize: 20,
            currentPage: 1,
            currentSort: 'name',
            currentSortDir: 'asc',
            showUserModal: false,
            a: 0,
            // showInformationIndex: '',
            editPop: false,
            informationList: [],
            categories: [],
            allCategories: [],
            selectedCategory: [],
            byClickOnAdd: false,
            byClickOnRow: false,
            optionsList: [],
            dummyList: [],
            rowData: [],
            tableRow: [],
            allSystems: [],
            headersRoot:['entityId','_id','category','information','securityCategory'],
            // headersOrg:['entityId','_id','category','information','securityCategory','infoAction'],
            
            information: {
                information: '',
                informationCategory: '',
                confidentiality: '',
                integrity: '',
                availability: '',
                securityCategory: '',
                informationOwner: '',
                systems: [],
                process:[],
                businessProcessList:[],
                classification: ''
            },

            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
            userOptions:[],
            searchValue: '',
        };
    },
    validations() {
        return {
            information: {
                informationCategory: { required },
                information: { required },
                confidentiality: { required },
                integrity: { required },
                availability: { required },
                securityCategory: { required },
                classification: { required },
                informationOwner: { required: requiredIf(() => {
                        return this.pageType == true;
                    }) },
                // systems: { required: requiredIf(() => {
                //         return this.pageType == true;
                //     }) },
                // process: { required: requiredIf(() => {
                //     return this.pageType == true;
                // }) },
            }
        };
    },
    components: {
        popup,
        multiselect,
        toolTip,
        newTable
    },
    computed: {
        ...mapGetters({ userInfo: 'userInfo',dbConfidentiality:'dbConfidentiality',dbAvailability:'dbAvailability',
        dbIntegrity:'dbIntegrity',dbInformationClassification:'dbInformationClassification',dbSecurityCategory:'dbSecurityCategory' }),

        disableStatus: function (): any {
            let output;
            if (this.editPop) {
                let objCopy = Object.assign({}, this.information);
                
                if (JSON.stringify(objCopy) == JSON.stringify(this.dummyObj)) {
                    output = false;
                } else {
                    output = true;
                }
            } else {
                let val: any = [];
                Object.values(this.information).forEach((value: any) => {
                    val.push(value);
                });
                for (let n of val) {
                    if (n != '') {
                        output = true;
                    }
                }
            }

            return output;
        },
        e: function (): any {
            return false;
        },
        cantGoBack(): any {
            return this.currentPage === 1;
        },
        cantGoForward(): any {
            return this.savedInformationSets.length / this.pageSize <= this.currentPage;
        }
    },
    async mounted() {

        this.dataLoading = true;
        
        await this.getBusinessProcess();
        await this.getSystems();

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });


        emitter.off('gotoInfoRisksPage');
        emitter.on('gotoInfoRisksPage', (isData: any) => {
            if(this.pageType){
                this.$router.push({name:'informationRisks',params:{informationId:isData._id}})
            }else{
                let comingId = isData._id;
                let showObj = this.savedInformationSets.find((obj:any)=>{
                    return (obj._id === comingId)
                });
                
                this.showEditPopUp(showObj);
                this.popUp = true;
            }
        });

        if (this.$route.path.includes('org')) {
            await this.getInformationSetsforOrg();
        } else {
            await this.getInformationSets();
        }

        this.dataLoading = false;
    },
    methods: {
        asyncCategoryFind(query:any) {
            this.searchValue = query
			query = query.trim();
			if (query == '') {
				this.subInfoCategoryList = [];
			} else {
				this.subInfoCategoryList = this.infoCategoryList.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.infoCategoryList.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.subInfoCategoryList.push(query);
				}
			}
		},
        asyncInformationFind(query:any){
            this.searchValue = query
            query = query.trim();
			if (query == '') {
				this.subInfoList = [];
			} else {
				this.subInfoList = this.infoList.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.infoList.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.subInfoList.push(query);
				}
			}
        },
        limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any){
            this.searchValue = query
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        showEditPopUp(obj: any) {
            this.showUserModal = true;
            this.dummyObj = { ...obj };
            this.editPop = true;
            this.information = { ...obj };
            this.byClickOnRow = true;
            this.byClickOnAdd = false;
        },
        getMaxIdValue() {
            this.information.securityCategory = Math.max(this.information.confidentiality, this.information.availability, this.information.integrity);
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.savedInformationSets;
            } else {
                this.savedInformationSets = this.dummyList;
            }
            emitter.emit('visible', this.showFilters);
        },
        async addOrUpdateInformation(info: any) {
            this.v$.$touch();
            // this.$refs.modal.open();
            if (!this.v$.$invalid) {
                this.clickOnButton = true
                this.informationList = []
                let copyOfInformation:any = {...this.information}
                this.informationList.push(copyOfInformation);
                if (copyOfInformation._id) {
                    await this.updateInformation(info);
                } else {
                    await this.addInformation();
                }
                Object.keys(copyOfInformation).forEach((prop) => {
                    Array.isArray(copyOfInformation[prop]) ? (copyOfInformation[prop] = []) : (copyOfInformation[prop] = '');
                });
                this.v$.$reset();
            } else {
                console.log("error");
                // toast.error(`error`, {
                //     timeout: 1000,
                //     closeOnClick: true,
                //     closeButton: 'button',
                //     icon: true
                // });
            }
        },
        onCancelbtn(information: any) {
            this.v$.$reset();
            if (this.byClickOnAdd) {
                Object.keys(this.information).forEach((prop) => {
                    Array.isArray(this.information[prop])? this.information[prop]=[]:this.information[prop] = '';
                });
            } else if (this.byClickOnRow) {
                this.information = { ...this.dummyObj };
                this.information.informationCategory = this.dummyObj.informationCategory;
            }
        },
        showInformationPopup() {
            Object.keys(this.information).forEach((prop) => {
                if(prop == '_id'||prop=='createdAt'||prop=='updatedAt'||prop=='entityId'){
                    delete this.information[prop];
                }else{
                    Array.isArray(this.information[prop])? this.information[prop]=[]:this.information[prop] = '';
                }
            });
            this.editPop = false;
            this.showUserModal = true;
            this.byClickOnRow = false;
            this.byClickOnAdd = true;
        },
        async getSystems() {
             let entityId='20005'
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.allSystems = result.data.map((sys: any) => {
                    console.log("sysyems", sys)
                    return { value: sys._id, label: sys.systemName };
                });
            });
       
        },
        async getBusinessProcess() {
            this.businessProcessList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                result.data.forEach((entity: any) => {
                    let a = { value:entity._id, label:entity.businessProcessName }
                    this.businessProcessList.push(a);
                    
                });
            });
        },
        async fileReader(oEvent: any) {
            var oFile = oEvent.target.files[0];
            var reader = new FileReader();
            reader.onload = async (e: any) => {
                var data = e.target.result;
                data = new Uint8Array(data);
                var workbook = XLSX.read(data, { type: 'array' });
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                var jsonObj = XLSX.utils.sheet_to_json(worksheet, {
                    raw: true,
                    header: this.mandatoryKeys,
                    range: 1
                });
                let total = jsonObj.length;
                this.informationList = [];
                console.log("userInfo", this.userInfo)
                jsonObj.forEach((each:any)=>{
                    let mandatoryCondition = this.mandatoryKeys.every((key: any) => {
                        return jsonObj[key]!='';
                    });

                    if (mandatoryCondition) {
                        let validRows = this.notImportedArray.find((obj:any)=> {return each.informationCategory == obj.informationCategory && each.information == obj.information})
                        
                        if(validRows == undefined){
                            this.informationList.push(each);
                            this.dummyList.push(each);
                        } 
                    }
                })

                this.informationList.forEach((element: any) => {
                    element.showData = false;
                    let newNum = 'securityCategory';
                    let valuesArray = [ {value:1, label:'low'}, {value:2, label:'moderate'}, {value:3, label:'high'}];
                    let infoClassArray = [ {value:1, label:'public'}, {value:2, label:'internal'}, {value:3, label:'confidential'}, {value:4, label:'private'}, {value:5, label:'topsecret'}];

                    let mainConfidentailty:any = valuesArray.find((obj:any)=>{return obj.label == (element.confidentiality).toLowerCase()});

                    let mainIntegrity:any = valuesArray.find((obj:any)=>{return obj.label == (element.integrity).toLowerCase()});
                    
                    let mainAvailability:any = valuesArray.find((obj:any)=>{return obj.label == (element.availability).toLowerCase()});
                    console.log("mainAvailability", mainAvailability)
                    
                    if(mainAvailability == undefined || mainConfidentailty == undefined || mainIntegrity == undefined){
                        toast.error('error', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                    element.confidentiality  = mainConfidentailty.value;
                    element.integrity = mainIntegrity.value;
                    element.availability = mainAvailability.value;
                    element[newNum] = Math.max(element.confidentiality, element.integrity, element.availability);

                    if(Object.keys(element).includes('classification')){
                        let mainClass:any = infoClassArray.find((obj:any)=>{return obj.label == (element.classification).toLowerCase()});
                        element.classification = mainClass.value
                    }else{
                        element.classification = 1
                    }

                    element.informationOwner = this.userInfo._id

                });
                let valid = this.informationList.length;

                if (this.informationList.length > 0) {
                    toast.info(`Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${total - valid}`, {
                        timeout: 3000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                    
                } else {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
                await this.addInformation();
            };
            reader.readAsArrayBuffer(oFile);
        },
        groupBy(arr: any[], key: string | number) {
            return arr.reduce((group: any, product: any) => {
                const { informationCategory } = product;
                group[informationCategory] = group[informationCategory] ?? [];
                group[informationCategory].push(product);
                return group;
            }, {});
        },
        async addInformation() {                    
            const headers = {'resourceId': 20007,};
            if (this.pageType) {
                try {
                    var informationId: Record<string, any> = []
                    this.informationList[0].createdAt = new Date();
                    this.informationList[0].informationOwner = this.informationList[0].informationOwner.value
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/create?org==true`,this.informationList, {headers}).then((result: any) => {
                        
                        console.log("rrrr",result.data.insertedIds)
                        console.log("rrrr",result.data.insertedIds[0])

                        informationId=result.data.insertedIds[0]
                        toast.info(`Saved successfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                        this.informationList = [];
                       
                    });
                    const payload: Record<string, any> = [];
                    this.allSystems.map((sys: any) => {
                        let infoSystems = [ ...this.information.systems ]
                        infoSystems.filter((infoo:any)=>{
                            let systemId = sys.value
                            if(infoo == systemId){
                                let systemValue = {
                                "systemId": systemId,
                                "information": [informationId] 
                                }
                                payload.push(systemValue);
                            }
                        })
                    });
                    this.showUserModal = false;
                    
                    
                    console.log("payload....", payload)
                    // await this.updateMapping(payload)
                }
                catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } finally {
                    this.clickOnButton = false 
                }
                this.tableRow = [];
                await this.getInformationSetsforOrg();
            } else {
                try {
                    delete this.informationList[0].systems; 
                    delete this.informationList[0].informationOwner;
                    this.informationList[0].createdAt = new Date();
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/create`, this.informationList,{headers}).then((result: any) => {
                        toast.info(`Saved succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    this.showUserModal = false;
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } finally {
                    this.clickOnButton = false 
                }
                
                this.tableRow = [];
                this.getInformationSets();
            }
        },

        async updateInformation(info: any) {
            if (this.pageType) {
                console.log("infoUpdate", info)
                try {
                    let informationId = info._id;
                    delete this.information._id;
                    // delete this.information.entityId;
                    this.information.updatedAt= new Date();
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/${informationId}/update?org==true`, info).then((result: any) => {
                        toast.info(`Updated succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    const payload: Record<string, any> = [];
                    this.allSystems.map((sys: any) => {
                        let systemId = sys.value
                        let systemValue = {
                        "systemId": systemId,
                        "information": [informationId] 
                        }
                        payload.push(systemValue);
                    });
                    
                    
                    console.log("payload....", payload)
                    // this.updateMapping(payload)
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
                this.tableRow = [];
                await this.getInformationSetsforOrg();
            } else {
                try {
                    let informationId = this.information._id;
                    delete this.information._id;
                    delete this.information.systems; 
                    delete this.information.informationOwner;
                    this.information.updatedAt= new Date();
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/${informationId}/update`, this.information).then((result: any) => {
                        console.log("this.information", this.information)
                        toast.info(`Updated succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } finally {
                    this.clickOnButton = false 
                }
                this.tableRow = [];
                this.getInformationSets();
            }

            this.showUserModal = false;
        },
        gotoInfoMapping(){
            this.$router.push({name:'informationMapping'})
        },
        async updateMapping(payload: any){
			console.log("mapping final",payload)
			try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/system/mapping/create`, payload)
                .then((result: any) => {
					console.log('updated');
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
             }		
        },
        async getInformationSets() {
            this.savedInformationSets = [];
            this.optionsList = [];
            // this.notImportedArray = [];
            this.rowData = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall`).then((result: any) => {
                this.rowData = JSON.parse(JSON.stringify(result.data));
                result.data.forEach((info: any) => {
                    this.notImportedArray.push({...info});
                    this.savedInformationSets.push(info);
                    this.optionsList.push(info);
                });
                console.log("savedInformationSets", this.savedInformationSets)

                const resultArray = this.notImportedArray.map((obj:any) => { delete obj._id; delete obj.showData; delete obj.securityCategory;
                delete obj.createdAt; delete obj.updatedAt; return obj; });
                this.notImportedArray = [...resultArray];
                console.log("notImportedArray", this.notImportedArray)

                this.savedInformationSets.forEach((information: any) => {
                    information.entityId = 20007;
                    this.categories.push(information.informationCategory);
                    this.tableRow.push({
                        entityId: information.entityId,
                        _id: information._id,
                        category: information.informationCategory,
                        information: information.information,
                        securityCategory: information.securityCategory
                    });
                });
            });
                this.optionsList.forEach((info: any) => {
                    !this.infoCategoryList.includes(info.informationCategory) ? this.infoCategoryList.push(info.informationCategory) : '';
                    !this.infoList.includes(info.information) ? this.infoList.push(info.information) : '';                        
                });            
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async getInformationSetsforOrg() {
            this.tableRow = [];
            // this.optionsList = [];
            // this.notImportedArray = [];
            this.rowData = [];
            this.savedInformationSets = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall?org=true`).then(async (result: any) => {
                this.rowData = JSON.parse(JSON.stringify(result.data));
                console.log("rowdata", this.rowData)
                result.data.forEach((info: any) => {                    
                    this.notImportedArray.push({...info});
                    this.savedInformationSets.push(info);
                    this.optionsList.push(info);                    
                });
                // await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall`).then((result: any) => {
                //     result.data.forEach((info: any) => {
                //         this.optionsList.push(info);
                //     });
                //     // this.categories = [...new Set(this.categories)];
                // });
                    this.optionsList.forEach((info: any) => {
                        console.log("hhhhhhh",info);
                        
                        !this.infoCategoryList.includes(info.informationCategory) ? this.infoCategoryList.push(info.informationCategory) : '';
                        !this.infoList.includes(info.information) ? this.infoList.push(info.information) : '';                        
                    });
                    
                    const resultArray = this.notImportedArray.map((obj:any) => { delete obj._id; delete obj.showData; delete obj.securityCategory;
                    delete obj.createdAt; delete obj.updatedAt; return obj; });
                    this.notImportedArray = [...resultArray];
                    console.log("notImportedArray", this.notImportedArray)

                    this.savedInformationSets.forEach((information: any) => {
                        information.entityId = 20007;
                        this.categories.push(information.informationCategory);
                        this.tableRow.push({
                            entityId: information.entityId,
                            _id: information._id,
                            category: information.informationCategory,
                            information: information.information,
                            securityCategory: information.securityCategory,
                        });

                    });
                // this.categories = [...new Set(this.categories)];
            });
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async getallUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                    console.log('this.optionsUserArray', this.optionsUserArray);
                }
            });
            console.log('this.orgUsersList', this.orgUsersList);
        },
        importDoc() {
            this.$refs.file.click();
        }
    },
    created() {
        this.getallUsers();
        this.pageType = this.$route.path.includes('org');
    }
});
</script>
