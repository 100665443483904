
<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray" ></breadCrumb>
        </div>
        <div class="flex justify-between items-center h-8">
            <div>
                <h1>Information Risks</h1>
            </div>
            <div class="flex items-center">
                <!-- <button class="btnprimary mr-3 flex items-center" @click="addRiskForOrganiztaion()" v-if="selectedIndex == 0">Add</button> -->
                <button v-if="selectedIndex != 1" :class="selectedIndex == 0 && riskList.length != 0 ? 'filterButton' : 'btndisabled pointer-events-none'" class="flex" @click="filterOpen">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-5 px-5 pb-5 bg-white w-full border-lightgrey pt-5 relative rounded">
           
           
            <!-- <div class="flex justify-between items-center w-full">
                <div class="tab-container flex items-center justify-start gap-1" ref="tabContainer" >
                    <div class="pb-1 adjust text-xs font-semibold" v-for="(tab, index) in tabHead" :key="index" @click="toggleTabs(tab.id)">
                        <div>
                            <div ref="actualIndex" class="cursor-pointer tab-name p-0.75">{{ tab.displayName }}</div>
                            <div :class="{ progress: selectedIndex === tab.id }" class="h-1 p-0.75"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="progressbarB h-1"></div> ---> 
            <div>
            <div>
                <div class="flex flex-row borderBottom cursor-pointer tab-container" ref="tabContainer" @scroll="handleScroll">
                    <div class="pb-1" :class="selectedIndex == tab.id ? 'adjust header font-semibold primary':'text-sm text-disabledgrey'" v-for="(tab, index) in tabHead" :key="index" @click="toggleTabs(tab)">
                        <div>
                        <div ref="actualIndex" class="cursor-pointer">{{ tab.displayName }}</div>
                            <div :class="{ progress: selectedIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="progressbarB rounded h-1"></div>
            </div>

            <div>
                <div v-if="selectedIndex == 0">
                    <newTable :tableRowData="riskList" :headersData="riskHeaders" :openPopup="true" :navigate="false" :loadData="dataLoading"></newTable>
                     <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                </div>

                <div v-if="selectedIndex == 1">
                    <div>
                        <div class="statuscontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="w-full upperspacing">
                                    <label class="controllabel">Information Category</label>
                                    <!-- <Multiselect
                                        v-model="information.informationCategory"
                                        :options="infoCategoryList"
                                        :searchable="true"
                                        :minChars="1"
                                        :filterResults="true"
                                        disabled
                                        class="multiselect-custom"
                                        :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                        :canClear="false"
                                        :caret="false"
                                        placeholder="Information Category"
                                    ></Multiselect> -->
                                    <multiselect 
                                        v-model="information.informationCategory"
                                        :options="infoCategoryList" 
                                        :searchable="true" 
                                        :close-on-select="true" 
                                        :show-labels="false"
                                        :hide-selected="true"
                                        :clear-on-select="true"
                                        :showNoOptions="false"
                                        placeholder="Information Category"
                                        open-direction="bottom"
                                        class="custom-multiselect"
                                        :class="{
                                            'is-invalid borderRed': v$.information.informationCategory.$error,
                                            'has-options': infoCategoryList && infoCategoryList.length > 0,
                                            'no-options':  !infoCategoryList.length,
                                        }"
                                    >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Categories Found</span>
                                        </template>
                                    </multiselect>

                                    <div v-if="v$.information.informationCategory.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.information.informationCategory.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Information</label>
                                        <!-- <Multiselect
                                            v-model="information.information"
                                            :options="infoList"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            disabled
                                            class="multiselect-custom"
                                            :classes="{
                                                singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
                                                container: 'relative h-fit mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-lightgrey rounded bg-white text-base leading-snug outline-none'
                                            }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Information"
                                        ></Multiselect> -->
                                        <multiselect 
                                            v-model="information.information"
                                            :options="infoList" 
                                            :searchable="true" 
                                            :close-on-select="true" 
                                            :show-labels="false"
                                            :hide-selected="true"
                                            placeholder="Information"
                                            open-direction="bottom"
                                            @select="changeInformation(information.information)"
                                            @deselect="changeInformation(information.information)"
                                            class="custom-multiselect"
                                            :class="{
                                                    'is-invalid': v$.information.information.$error,
                                                    'has-options': infoList && infoList.length > 0,
                                                    'no-options':  !infoList.length,
                                                }"
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Information Found</span>
                                            </template>
                                        </multiselect>
                                        <!-- :class="{
                                                'is-invalid': v$.information.information.$error
                                            }" -->
                                        <div v-if="v$.information.information.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.information.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Information Owner</label>
                                        <!-- <Multiselect
                                            v-model="information.informationOwner"
                                            :options="optionsUserArray"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Info Owner"
                                        ></Multiselect> -->
                                        <multiselect
                                            v-model="information.informationOwner"
                                            :options="userOptions"
                                            :showNoOptions="false"
                                            @search-change="asyncFind"
                                            :limit-text="limitText"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Info Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.information.informationOwner.$error,
                                                'has-options': userOptions && userOptions.length > 0,
                                                'no-options': !userOptions.length &&  searchValue !== '',
                                            }"
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Members Found</span>
                                            </template>
                                        </multiselect>

                                        <div v-if="v$.information.informationOwner.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.informationOwner.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Confidentiality</label>
                                        <select v-on:change="getMaxIdValue()" class="inputboxstyling" v-model="information.confidentiality">
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option v-for="obj in dbConfidentiality" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <!-- :class="{
                                                'is-invalid': v$.information.confidentiality.$error
                                            }"
                                        <div v-if="v$.information.confidentiality.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.confidentiality.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Integrity</label>
                                        <select v-on:change="getMaxIdValue()" class="inputboxstyling" v-model="information.integrity">
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option v-for="obj in dbIntegrity" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <!-- :class="{
                                                'is-invalid': v$.information.integrity.$error
                                            }"
                                        <div v-if="v$.information.integrity.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.integrity.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Availability</label>
                                        <select v-on:change="getMaxIdValue()" class="inputboxstyling" v-model="information.availability">
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option v-for="obj in dbAvailability" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>
            
                                        <!-- :class="{
                                                'is-invalid': v$.information.availability.$error
                                            }"
                                        <div v-if="v$.information.availability.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.information.availability.required.$invalid">Required</div>
                                        </div> -->
                                    </div>

                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Classification</label>

                                        <select class="inputboxstyling" v-model="information.classification">
                                            <option value="" selected disabled hidden>Please select one</option>

                                            <option v-for="obj in dbInformationClassification" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>

                                        <div v-if="v$.information.classification.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.information.classification.required.$invalid">Required</div>
                                            </div>
                                    </div>
                                </div>
                                <div v-if="information.securityCategory != '' && !addInfo" class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <div v-for="(dataValue, indexNum) in dbSecurityCategory" :key="indexNum">
                                            <div v-if="information.securityCategory == dataValue.value">
                                                <label class="controllabel">Security Category</label>
                                                <input type="text" class="disabledinputboxstyling" disabled v-model="dataValue.key" placeholder="Security Category" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!dataLoading" class="buttonposition flex justify-center items-center mt-2 mb-4 mx-0">
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn()">Cancel</button>
                        <button :disabled="clickOnButton" @click="addOrUpdateInformation()" v-if="!information._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-else @click="addOrUpdateInformation(information)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                    </div>
                </div>
            </div>
        </div>
        <popup v-if="openRiskPopup" :popUp="openRiskPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Risk Information</h1>
                    <button class="float-right text-3xl" @click="onCancelButton('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <!-- Risk Div open -->
                            <div class="formcontainer flex flex-col">
                                <div>
                                    <div class="w-full upperspacing">
                                        <label class="controllabel">Risk</label>
                                        <textarea type="text" class="inputboxstyling" @input ="restrictCharacters('Risk')" v-model.trim="orgRisksTab.risk" placeholder="Risk" :class="{ 'is-invalid': v$.orgRisksTab.risk.$error||displayErrorMessageOfRisk }" />
                                        <div v-if="v$.orgRisksTab.risk.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.risk.required.$invalid">Required</div>
                                        </div>
                                        <div v-else-if="displayErrorMessageOfRisk == true">
                                            <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Risk div closed -->
                            <!-- Risk category , Risk owner div opened -->
                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <label class="controllabel">Risk Category</label>
                                        <!-- <Multiselect
                                            v-model="orgRisksTab.riskCategory"
                                            :options="riskCategoryList"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Risk Category"
                                            :class="{
                                                'is-invalid redBorder': v$.orgRisksTab.riskCategory.$error
                                            }"
                                        ></Multiselect>
                                        <div v-if="v$.orgRisksTab.riskCategory.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.riskCategory.required.$invalid">Required</div>
                                        </div> -->
                                        <multiselect
                                                    v-model="orgRisksTab.riskCategory"
                                                    :options="riskCategoryList"
                                                    :multiple="false"
                                                    :close-on-select="true"
                                                    :clear-on-select="true"
                                                    open-direction="bottom"
                                                    :hide-selected="true"
                                                    :searchable="true"
                                                    :hideArrow="false"
                                                    placeholder="Risk Category"
                                                    deselectLabel=""
                                                    :showNoOptions="false"
                                                    selectLabel=""
                                                    class="custom-multiselect userSelect"
                                                    :class="{
                                                        'is-invalid borderRed': v$.orgRisksTab.riskCategory.$error,
                                                        'has-options': riskCategoryList && riskCategoryList.length > 0,
                                                        'no-options':  !riskCategoryList.length,
                                                    }"
                                                >
                                                    <template v-slot:noResult>
                                                        <span class="text-xs">No Categories Found</span>
                                                    </template>
                                                </multiselect>
                                                <div v-if="v$.orgRisksTab.riskCategory.$error" class="text-red-500">
                                                    <div class="error-text text-xs" v-if="v$.orgRisksTab.riskCategory.required.$invalid">Required</div>
                                                </div>
                                    </div>
                                    <div class="w-3/6 leftspacing">
                                        <label class="controllabel">Risk Owner</label>
                                        <!-- <Multiselect
                                            v-model="orgRisksTab.riskOwner"
                                            :options="optionsUserArray"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Risk Owner"
                                            :class="{
                                                'is-invalid redBorder': v$.orgRisksTab.riskOwner.$error
                                            }"
                                        ></Multiselect>
                                        <div v-if="v$.orgRisksTab.riskOwner.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.riskOwner.required.$invalid">Required</div>
                                        </div> -->
                                        
                                      <multiselect
                                            v-model="orgRisksTab.riskOwner"
                                            :options="userOptions"
                                            :showNoOptions="false"
                                            @search-change="asyncFind"
                                            :limit-text="limitText"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Info Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.orgRisksTab.riskOwner.$error,
                                                'has-options': userOptions && userOptions.length > 0,
                                                'no-options': !userOptions.length &&  searchValue !== '',
                                            }"
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Members Found</span>
                                            </template>
                                        </multiselect>

                                        <div v-if="v$.orgRisksTab.riskOwner.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.riskOwner.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Risk category , Risk owner div closed -->

                            <!-- Threat div opened -->
                            <div class="formcontainer flex flex-col upperspacing">
                                <div>
                                    <div class="w-full">
                                        <label class="controllabel">Threat</label>
                                        <textarea type="text" class="inputboxstyling" @input ="restrictCharacters('Threat')" v-model.trim="orgRisksTab.threatEvent" placeholder="Threat" :class="{ 'is-invalid': v$.orgRisksTab.threatEvent.$error||displayErrorMessageOfThreat }" />
                                        <div v-if="v$.orgRisksTab.threatEvent.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.threatEvent.required.$invalid">Required</div>
                                        </div>
                                        <div v-else-if="displayErrorMessageOfThreat == true">
                                            <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Threat div closed -->

                            <!-- Threat source , Threat Category opened -->
                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <label class="controllabel">Threat Source</label>
                                        <!-- <Multiselect
                                            v-model="orgRisksTab.threatSource"
                                            :options="threatSourceList"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :class="{ 'is-invalid redBorder': v$.orgRisksTab.threatSource.$error }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Threat Source"
                                        ></Multiselect>
                                        <div v-if="v$.orgRisksTab.threatSource.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.threatSource.required.$invalid">Required</div>
                                        </div> -->
                                        <multiselect 
                                            v-model="orgRisksTab.threatSource"
                                            :options="threatSourceList" 
                                            :searchable="true" 
                                            :close-on-select="true" 
                                            :show-labels="false"
                                            :hide-selected="true"
                                            placeholder="Threat Source"
                                            open-direction="bottom"
                                            class="custom-multiselect"
                                            :class="{
                                                'is-invalid borderRed': v$.orgRisksTab.threatSource.$error,
                                                'has-options': threatSourceList && threatSourceList.length > 0,
                                                'no-options':  !threatSourceList.length,
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Source Found</span>
                                        </template>
                                    </multiselect>

                                    <div v-if="v$.orgRisksTab.threatSource.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.orgRisksTab.threatSource.required.$invalid">Required</div>
                                    </div>
                                    </div>

                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Threat Category</label>
                                        <!-- <Multiselect
                                            v-model="orgRisksTab.threatCategory"
                                            :options="threatCategoryList"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :class="{ 'is-invalid redBorder': v$.orgRisksTab.threatCategory.$error }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Threat Category"
                                        ></Multiselect>
                                        <div v-if="v$.orgRisksTab.threatCategory.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.threatCategory.required.$invalid">Required</div>
                                        </div> -->
                                         <multiselect 
                                            v-model="orgRisksTab.threatCategory"
                                            :options="threatCategoryList" 
                                            :searchable="true" 
                                            :close-on-select="true" 
                                            :show-labels="false"
                                            :hide-selected="true"
                                            placeholder="Threat Category"
                                            open-direction="bottom"
                                            class="custom-multiselect"
                                            :class="{
                                                'is-invalid borderRed': v$.orgRisksTab.threatCategory.$error,
                                                'has-options': threatCategoryList && threatCategoryList.length > 0,
                                                'no-options':  !threatCategoryList.length,
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Categories Found</span>
                                        </template>
                                    </multiselect>

                                    <div v-if="v$.orgRisksTab.threatCategory.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.orgRisksTab.threatCategory.required.$invalid">Required</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Threat source , Threat Category closed -->

                            <!-- vulnerability div opened -->
                            <div class="formcontainer flex flex-col upperspacing">
                                <div>
                                    <div class="w-full">
                                        <label class="controllabel">Vulnerability</label>
                                        <textarea type="text" class="inputboxstyling" @input ="restrictCharacters('Vulnerability')" v-model.trim="orgRisksTab.vulnerability" placeholder="Vulnerability" :class="{ 'is-invalid': v$.orgRisksTab.vulnerability.$error||displayErrorMessageOfVulnerability }" />
                                        <div v-if="v$.orgRisksTab.vulnerability.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.vulnerability.required.$invalid">Required</div>
                                        </div>
                                        <div v-else-if="displayErrorMessageOfVulnerability == true">
                                            <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- vulnerability div closed -->

                            <!-- <div class="flex items-center justify-between rounded"> -->
                            <h1 class="upperspacing">Impact Analysis</h1>
                            <!-- </div> -->
                            <div class="border-b pt-1 border-solid border-lightgrey"></div>

                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <label class="controllabel">Strategic</label>
                                        <select class="inputboxstylingForSelect" v-model="orgRisksTab.business" :class="{ 'is-invalid': v$.orgRisksTab.business.$error,'inputboxstylingForSelectAtPopup': orgRisksTab.business === '','stylingForInputboxstylingForSelectAtPopup': orgRisksTab.business !== ''  }">
                                            <option value="" selected disabled hidden>Please select one</option>

                                            <option class="optionColors" v-for="i in dbBussiness" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                        <div v-if="v$.orgRisksTab.business.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.business.required.$invalid">Required</div>
                                        </div>
                                    </div>

                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Financial</label>
                                        <select class="inputboxstylingForSelect" v-model="orgRisksTab.financial" :class="{ 'is-invalid': v$.orgRisksTab.financial.$error,'inputboxstylingForSelectAtPopup': orgRisksTab.financial === '','stylingForInputboxstylingForSelectAtPopup': orgRisksTab.financial !== ''  }">
                                            <option value="" selected disabled hidden>Please select one</option>

                                            <option class="optionColors" v-for="i in dbFinancial" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                        <div v-if="v$.orgRisksTab.financial.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.financial.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <label class="controllabel">Operations</label>
                                        <select class="inputboxstylingForSelect" v-model="orgRisksTab.operations" :class="{ 'is-invalid': v$.orgRisksTab.operations.$error,'inputboxstylingForSelectAtPopup': orgRisksTab.operations === '','stylingForInputboxstylingForSelectAtPopup': orgRisksTab.operations !== ''  }">
                                            <option value="" selected disabled hidden>Please select one</option>

                                            <option class="optionColors" v-for="i in dbOperations" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                        <div v-if="v$.orgRisksTab.operations.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.operations.required.$invalid">Required</div>
                                        </div>
                                    </div>

                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Compliance</label>
                                        <select class="inputboxstylingForSelect" v-model="orgRisksTab.legal" :class="{ 'is-invalid': v$.orgRisksTab.legal.$error,'inputboxstylingForSelectAtPopup': orgRisksTab.legal === '','stylingForInputboxstylingForSelectAtPopup': orgRisksTab.legal !== ''  }">
                                            <option value="" selected disabled hidden>Please select one</option>

                                            <option class="optionColors" v-for="i in dbLegal" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                        <div v-if="v$.orgRisksTab.legal.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.legal.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <h1 class="upperspacing">Risk Analysis</h1>
                            <div class="border-b pt-1 border-solid border-lightgrey"></div>
                            <div class="formcontainer upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-3/6">
                                        <label class="controllabel">Likelihood</label>
                                        <select class="inputboxstylingForSelect" v-model="orgRisksTab.likelihood" :class="{ 'is-invalid': v$.orgRisksTab.likelihood.$error,'inputboxstylingForSelectAtPopup': orgRisksTab.likelihood === '','stylingForInputboxstylingForSelectAtPopup': orgRisksTab.likelihood !== ''  }">
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option class="optionColors" v-for="i in dbLikelihood" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                        <div v-if="v$.orgRisksTab.likelihood.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.orgRisksTab.likelihood.required.$invalid">Required</div>
                                        </div>
                                    </div>

                                    <div class="w-3/6 leftspacing">
                                        <label class="controllabel">Overall Impact</label>
                                        <select class="inputboxstyling" disabled v-model="orgRisksTab.overallImpact">
                                            <option value="" selected hidden>Please select one</option>
                                            <option v-for="i in dbSeverity" :value="i.value" :key="i">
                                                {{ i.key }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttonposition flex justify-center items-center mt-2 mb-4 mx-0">
                        <button :disabled="clickOnButton" class="btnprimary mr-2 gap-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelButton('cancel')">Cancel</button>
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-if="addingRisk" @click="addOrUpdaterisk(orgRisksTab)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" v-else @click="addOrUpdaterisk(orgRisksTab)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                    </div>
                    <!-- <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                            <button class="btnprimary mr-1.5" @click="onCancelButton('cancel')" :class="JSON.stringify(riskObjCopy) == JSON.stringify(orgRisksTab) ? 'btndisabled pointer-events-none' : 'btnprimary'">Cancel</button>

                            <button class="btnprimary" @click="submitForm" :class="JSON.stringify(riskObjCopy) == JSON.stringify(orgRisksTab) ? 'btndisabled pointer-events-none' : 'btnprimary'">Update</button>
                        </div> -->
                </div>
            </fieldset>
        </popup>
    </div>
</template>
<style scoped>
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 60px !important; 
}
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #152a43;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}
.midHead {
    width: 160px !important;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.nonEmptyTable tr:last-child {
    border-bottom: 1px solid #e9e9e9;
}
.anime-left {
    animation-name: moveLeft;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
@keyframes moveLeft {
    0% {
        opacity: 1;
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0);
    }
}
.anime-right {
    animation-name: moveRight;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
@keyframes moveRight {
    0% {
        opacity: 1;
        transform: translateX(-100vw);
    }
    100% {
        transform: translateX(0);
    }
}
/* .progressbarSI {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    position: relative;
    top: -8px;
    border-radius: 2px;
}
.progress {
    background: #152a43;
} */
.setBox {
    width: initial;
}

.redBorder {
    border: 1px solid red !important;
}
.adjust {
    flex-shrink: 0;
}
.button-adjust {
    position: relative;
    top: -30px;
    z-index: 1;
    cursor: pointer;
}
.borderBottom {
    position: relative;
    z-index: 2;
    cursor: pointer;
}
.businesssPopup label {
    font-size: 16px;
    font-family: Open Sans !important;
}
.z-index {
    z-index: 100;
}
.accept {
    width: 100px;
}
.ellipsis-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import popup from '@/components/popup.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers, requiredIf, email } from '@vuelidate/validators';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
import { emitter, toast } from '@/main';
import { mapGetters } from 'vuex';
import breadCrumb from '@/components/breadCrumb.vue';
import newTable from '@/components/newTable.vue';
const zip = helpers.regex(/^\d{5}(?:\d{1})?$/);
import Multiselect from 'vue-multiselect';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),

            pop: false,
            xPos: '',
            yPos: '',
            routesArray: [],

            pageSize: 20,
            currentPage: 1,
            pageBoxes: 3,
            pageIndexStart: [],
            leftBox: 'left',
            rightBox: 'right',
            pageIndexEnd: [],
            totalPages: '',
            pagesArray: [],
            sideButton: false,
            start: '',
            end: '',
            //Sort
            addInfo:false,
            currentSort: 'name',
            currentSortDir: 'asc',
            className: '',
            addingRisk: false,
            classSort: '',
            selectedIndex: 0,
            showFilters: false,
            selectedCategory: [],
            systemAssesmentList: [],
            selectAll: false,
            searchValue: '',
            checkedItems: [],
            disabled: false,
            discovery: false,
            dummyOrgObject: {},
            openRiskPopup: false,
            rowData: [],
            riskList: [],
            riskCategoryList: [],
            threatSourceList: [],
            threatCategoryList: [],
            infoDummyObj: [],
            objToBeModified: {},
            thisScreen: '',
            riskObjCopy: [],
            optionsUserArray: [],
            users: [],
            mappedOrgOptions: [],
            mainOrg: [],
            applyPopup: false,
            navIndexNums: [],
            showInfoPopup: false,
            rootInformationsList: [],
            mappedInfoForProcess: [],
            optionsList: [],
            savedInformationSets: [],
            infoCategoryList: [],
            infoList: [],
            addInformationObj: [],
            selectedTabObj: { displayName: 'Risks', name: 'Risk', id: 0 },
            informationHeaders: ['entityId', '_id', 'informationCategory', 'information', 'securityCategory'],
            riskHeaders: ['severity', 'risk', 'riskCategory', 'tier', 'priority'],
            carouselHead: [
                { displayName: 'Risks', name: 'Risk', id: 0 },
                { displayName: 'Properties', name: 'Properties', id: 1 }
            ],
            applyObj: {
                organization: [],
                comments: ''
            },
            filterDummy: {
                severity: '',
                overallImpact: '',
                likelihood: '',
                statusCode: ''
            },
            organizationList: [],
            tasksData: [],
            information: {
                information: '',
                informationCategory: '',
                confidentiality: '',
                integrity: '',
                availability: '',
                securityCategory: '',
                informationOwner: '',
                systems: [],
                process: [],
                businessProcessList: [],
                classification: ''
            },
            countriesStatesCities: [
                {
                    CountryName: 'India',
                    States: [
                        {
                            StateName: 'Maharashtra',
                            Cities: ['Pune', 'Nagpur', 'Mumbai']
                        },
                        {
                            StateName: 'Kerala',
                            Cities: ['Kochi', 'Munnar']
                        },
                        {
                            StateName: 'Telangana',
                            Cities: ['Hyderabad', 'Nizamabad', 'Warangal']
                        },
                        {
                            StateName: 'Andhra Pradesh',
                            Cities: ['Vijayawada', 'Vizag', 'Rajamundry']
                        }
                    ]
                },
                {
                    CountryName: 'Australia',
                    States: [
                        {
                            StateName: 'Aukland',
                            Cities: ['GlenField', 'Henderson', 'MilFord']
                        },
                        {
                            StateName: 'Melbourne',
                            Cities: ['Melbourne', 'South Oakleigh']
                        }
                    ]
                }
            ],
            orgType: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Subsidiary'
                },
                {
                    id: 3,
                    value: 'Branch'
                }
            ],
            ownerTypeValues: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            orgRisksTab: {
                risk: '',
                threatCategory: '',
                threatSource: '',
                vulnerability: '',
                threatEvent: '',
                business: '',
                operations: '',
                legal: '',
                financial: '',
                likelihood: '',
                riskCategory: '',
                riskOwner: ''
            },
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0, // Total number of groups for small screens
            userOptions:[],

            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,
			displayErrorMessageOfRisk : false,
			displayErrorMessageOfThreat : false,
			displayErrorMessageOfVulnerability : false,
            clickOnButton: false
        };
    },
    components: {
        popup,
        newTable,
        breadCrumb,
        toolTip,
        Multiselect
    },
    validations() {
        return {
            information: {
                informationCategory: { required },
                information: { required },
                informationOwner: { required },
                confidentiality: { required },
                integrity: { required },
                availability: { required },
                securityCategory: { required },
                classification: { required }
            },
            orgRisksTab: {
                risk: { required },
                riskOwner: { required },
                riskCategory: { required },
                threatEvent: { required },
                threatSource: { required },
                threatCategory: { required },
                vulnerability: { required },
                business: { required },
                operations: { required },
                legal: { required },
                financial: { required },
                likelihood: { required },
            }
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
            dbLikelihood: 'dbLikelihood',
            dbBussiness: 'dbBussiness',
            dbOrganization: 'dbOrganization',
            dbOperations: 'dbOperations',
            dbLegal: 'dbLegal',
            dbFinancial: 'dbFinancial',
            dbTechnical: 'dbTechnical',
            dbProcessCriticality: 'dbProcessCriticality',
            dbAvailability: 'dbAvailability',
            dbConfidentiality: 'dbConfidentiality',
            dbIntegrity: 'dbIntegrity',
            dbInformationClassification: 'dbInformationClassification',
            dbSecurityCategory: 'dbSecurityCategory',
            dbPriority: 'dbPriority',
            dbSeverity: 'dbSeverity',
        }),

        disableStatusInfo: function (): any {
            let output = false;
            let val: any = [];
            Object.values(this.information).forEach((value: any) => {
                val.push(value);
            });
            for (let n of val) {
                if (n != '') {
                    output = true;
                }
            }
            return output;
        },
        disableStatus: function (): any {
            if (this.selectedIndex == 0) {
                let disableStatusCondition;
                if (this.orgRisksTab && !this.addingRisk) {
                    if (JSON.stringify(this.orgRisksTab) == JSON.stringify(this.riskObjCopy)) {
                        disableStatusCondition = false;
                    } else {
                        disableStatusCondition = true;
                    }
                } else {
                    let valueArray: any = [];
                    Object.values(this.orgRisksTab).forEach((value: any) => {
                        valueArray.push(value);
                    });
                    for (let value of valueArray) {
                        if (value != '') {
                            disableStatusCondition = true;
                        }
                    }
                }
                return disableStatusCondition;
            } else if (this.selectedIndex == 1) {
                console.log('this.selectedIndex', this.selectedIndex);
                let disableStatusCondition;
                if (this.information) {
                    if (JSON.stringify(this.information) == JSON.stringify(this.infoDummyObj)) {
                        disableStatusCondition = false;
                    } else {
                        disableStatusCondition = true;
                    }
                }
                return disableStatusCondition;
            } else {
                let output;
                if (this.editPop) {
                    let objCopy = Object.assign({}, this.information);
                    if (JSON.stringify(objCopy) == JSON.stringify(this.dummyObj)) {
                        output = false;
                    } else {
                        output = true;
                    }
                } else {
                    let val: any = [];
                    Object.values(this.information).forEach((value: any) => {
                        val.push(value);
                    });
                    for (let n of val) {
                        if (n != '') {
                            output = true;
                        }
                    }
                }
                return output;
            }
        },

        tabHead(): any {
            return this.carouselHead.filter((row: any, index: any) => {
                let start = (this.currentPage - 1) * this.pageSize;
                this.index = 0;
                let end = this.currentPage * this.pageSize;

                if (index >= start && index < end) return true;
            });
        }
    },

    async mounted() {
        this.dataLoading = true;

        // this.selectedIndex = 0;
        await this.getAllUsers();
        await this.getRootRisksList();

        this.dataLoading = false;

        
        let orgId = this.$route.params.id;
        this.routesArray = [
            { name: 'Information', routeName: 'orginformation' },
            { name: 'Information - Risks', routeName: 'organizationInformation', id: `${orgId}` }
        ];


        emitter.off('showData123');
        emitter.on('showData123', (isData: any) => {
            let comingId = isData.entityData._id;
            if (this.selectedIndex == 1) {
                let showObj = isData.entityData;
                this.showInfoEditPopUp(showObj);
            } else {
                let showObj = this.riskList.find((obj: any) => {
                    return obj._id === comingId;
                });
                this.showPopupbyRow(showObj);
            }
        });
        emitter.off('changeRouteInOrgInfo');
        emitter.on('changeRouteInOrgInfo', (selectedActionObj: any) => {
            console.log('selectedActionObjs', selectedActionObj);
            let risk = selectedActionObj.data;
            let route = selectedActionObj.route;
            this.changeRoute(risk, route);
        });

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    },
    methods: {
        limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any){
            this.searchValue = query
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        changeInformation(name: any) {
            if (name == '' || name == null || name == undefined) {
                this.information.id = '';
                this.information.confidentiality = '';
                this.information.availability = '';
                this.information.integrity = '';
                this.information.informationOwner = '';
            } else {
                let findObj: any = this.optionsList.find((obj: any) => {
                    console.log("findobj:",obj);
                    
                    return obj.information == name;
                });
                console.log("infoids",this.information.id);
                
                this.information.id = findObj._id;
                                console.log("infoids",this.information.id);

                this.information.confidentiality = findObj.confidentiality;
                this.information.availability = findObj.availability;
                this.information.integrity = findObj.integrity;
                this.information.informationOwner = this.optionsUserArray.find((obj:any)=>{return obj.value == findObj.informationOwner});
                this.getMaxIdValue();
            }
        },
        onCancelButton(status: any) {
            this.v$.$reset();
            if (status == 'close') {
                this.openRiskPopup = false;
                this.displayErrorMessageOfRisk = false
                this.displayErrorMessageOfThreat = false
                this.displayErrorMessageOfVulnerability = false
            } else {
                if (this.orgRisksTab._id) {
                    this.orgRisksTab = { ...this.riskObjCopy };
                    this.displayErrorMessageOfRisk = false
                    this.displayErrorMessageOfThreat = false
                    this.displayErrorMessageOfVulnerability = false
                } else {
                    Object.keys(this.orgRisksTab).forEach((prop) => {
                        Array.isArray(this.orgRisksTab[prop]) ? (this.orgRisksTab[prop] = []) : (this.orgRisksTab[prop] = '');
                    });
                    this.displayErrorMessageOfRisk = false
                    this.displayErrorMessageOfThreat = false
                    this.displayErrorMessageOfVulnerability = false
                }
            }
        },
        showInfoEditPopUp(obj: any) { 
                       
            this.showInfoPopup = true;
           
            this.addInfo = false;
            this.information = { ...obj };
        },
        showPopupbyRow(obj: any) {
            this.openRiskPopup = true;
            this.optionsUserArray.filter((optionsobj:any)=>{
                if(optionsobj.value == obj.riskOwner) {
                let a={label:optionsobj.label,value:optionsobj.value};
                obj.riskOwner = a;
                }  
            })
            this.orgRisksTab = { ...obj };
            this.addingRisk = false;
            this.riskObjCopy = { ...this.orgRisksTab };
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        average(arr: any) {
            return arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
        },
        onCancelbtn() {
            this.information = { ...this.infoDummyObj };
            // this.businesss = { ...this.dummyOrgObject };
            // this.objToBeModified = this.orgRisksTab;
        },
        
        restrictCharacters(code: any){
            if(code === 'Threat'){
                if(this.orgRisksTab.threatEvent.length > 300){
                this.displayErrorMessageOfThreat = true;
                }else if(this.orgRisksTab.threatEvent.length < 300 || this.orgRisksTab.threatEvent.length == 300){
                    this.displayErrorMessageOfThreat = false;
                }
            } else if(code === 'Risk'){
                if(this.orgRisksTab.risk.length > 300){
                    this.displayErrorMessageOfRisk = true;
                }else if(this.orgRisksTab.risk.length < 300 || this.orgRisksTab.risk.length == 300){
                    this.displayErrorMessageOfRisk = false;
                }
            } else if(code === 'Vulnerability'){
                if(this.orgRisksTab.vulnerability.length > 300){
                    this.displayErrorMessageOfVulnerability = true;
                }else if(this.orgRisksTab.vulnerability.length < 300 || this.orgRisksTab.vulnerability.length == 300){
                    this.displayErrorMessageOfVulnerability = false;
                }
            }
            
        },
        getMaxIdValue() {
            this.information.securityCategory = Math.max(this.information.confidentiality, this.information.availability, this.information.integrity);
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
        async toggleTabs(navIndex: any) {
            this.dataLoading = true;
            this.index = navIndex.id;
            this.selectedIndex = navIndex.id;
            sessionStorage.setItem('selectedTab', navIndex.id);
            this.selectedTabObj = navIndex
            this.infoTableRow = [];
            if (navIndex.id == 0) {
                await this.getRootRisksList();
                this.navIndexNums.index = 0;
                this.navIndexNums.name = 'Risks';
                emitter.emit('visible', false);
                this.showFilters = false;
            } else if (navIndex.id == 1) {
                await this.getInformationSetsforOrg();
                this.navIndexNums.index = 1;
                this.navIndexNums.name = 'Properties';
            }
            this.dataLoading = false
        },
        changeRoute(risk: any, route: any) {
            this.mainRisk = risk;
            if (route == '/riskAcceptance' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'Acceptance';
                if (this.mainRisk.statusCode == 10403) {
                    toast.error(`Cannot Accept the Risk in this Status`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForAccept = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForAccept) {
                            let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                            this.applyPopup = true;
                            this.mappedOrgOptions.push(orgObj);
                            this.mainOrg.push(orgObj.value);
                            this.applyObj.organization = [...this.mainOrg];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                        this.applyPopup = true;
                        this.mappedOrgOptions.push(orgObj);
                        this.mainOrg.push(orgObj.value);
                        this.applyObj.organization = [...this.mainOrg];
                    }
                }
            } else if (route == '/mitigateRisk' && Object.keys(this.mainRisk).includes('riskOwner')) {
                if (this.mainRisk.statusCode == 10402 || this.mainRisk.statusCode == 10406) {
                    this.thisScreen = 'Mitigate';
                    let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                    const mitigateProcesses = [];
                    mitigateProcesses.push(orgObj.value);
                    localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateProcesses));
                    // this.$router.push({name:'processControlsMap',params:{riskId: this.mainRisk.mappedRisk,orgId:this.mainRisk.orgId}});

                    console.log('Hi 1');
                    this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'organization', id: this.mainRisk.orgId }, query: { organization: true } });
                } else if (Object.keys(this.mainRisk).includes('tasks')) {
                    let conditionForMitigate = this.mainRisk.tasks.every((taskId: any) => {
                        let a = this.tasksData.find((task: any) => {
                            return task._id == taskId;
                        });
                        return a.statusCode == 10403 || a.statusCode == 10410;
                    });
                    console.log('conditionForMitigateconditionForMitigate', conditionForMitigate);
                    if (this.mainRisk.statusCode != 10402 && this.mainRisk.statusCode != 10406 && conditionForMitigate) {
                        this.thisScreen = 'Mitigate';
                        let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                        const mitigateProcesses = [];
                        mitigateProcesses.push(orgObj.value);
                        if (this.mainRisk.statusCode == 10405 || this.mainRisk.statusCode == 10403) {
                            toast.error(`Cannot Mitigate the Risk in Mitigate Status`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        } else {
                            localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateProcesses));
                            console.log('Hi 2');
                            this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'organization', id: this.mainRisk.orgId }, query: { organization: true } });
                        }
                    } else {
                        toast.error(`Tasks In pending for this Risk`, {
                            timeout: 5000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                } else {
                    this.thisScreen = 'Mitigate';
                    let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                    const mitigateProcesses = [];
                    mitigateProcesses.push(orgObj.value);
                    localStorage.setItem(this.mainRisk.mappedRisk, JSON.stringify(mitigateProcesses));
                    console.log('Hi 3');
                    this.$router.push({ name: 'systemsControlsMapWithId', params: { riskId: this.mainRisk.mappedRisk, type: 'organization', id: this.mainRisk.orgId }, query: { organization: true } });
                }
            } else if (route == '/reset' && Object.keys(this.mainRisk).includes('riskOwner')) {
                this.thisScreen = 'reset';
                if (this.mainRisk.statusCode == 10401) {
                    toast.error(`Cannot Reset the Risk in this Status`, {
                        timeout: 5000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } else {
                    if (Object.keys(this.mainRisk).includes('tasks')) {
                        let conditionForReset = this.mainRisk.tasks.every((taskId: any) => {
                            let a = this.tasksData.find((task: any) => {
                                return task._id == taskId;
                            });
                            return a.statusCode == 10403 || a.statusCode == 10410;
                        });
                        if (conditionForReset) {
                            let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                            this.applyPopup = true;
                            this.mappedOrgOptions.push(orgObj);
                            this.mainOrg.push(orgObj.value);
                            this.applyObj.organization = [...this.mainOrg];
                        } else {
                            toast.error(`Tasks in Pending for this Risk`, {
                                timeout: 5000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        }
                    } else {
                        let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                        this.applyPopup = true;
                        this.mappedOrgOptions.push(orgObj);
                        this.mainOrg.push(orgObj.value);
                        this.applyObj.organization = [...this.mainOrg];
                    }
                }
            } else if (route == '/deactive') {
                this.thisScreen = 'deactive';
                if (Object.keys(this.mainRisk).includes('tasks')) {
                    let conditionForDelete = this.mainRisk.tasks.every((taskId: any) => {
                        let a = this.tasksData.find((task: any) => {
                            return task._id == taskId;
                        });
                        return a.statusCode == 10403 || a.statusCode == 10410;
                    });
                    if (conditionForDelete) {
                        let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                        this.applyPopup = true;
                        this.mappedOrgOptions.push(orgObj);
                        this.mainOrg.push(orgObj.value);
                        this.applyObj.organization = [...this.mainOrg];
                    } else {
                        toast.error(`Tasks in Pending for this Risk`, {
                            timeout: 5000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                } else {
                    let orgObj = { value: this.$route.params.orgId, label: this.businesss.entityName };
                    this.applyPopup = true;
                    this.mappedOrgOptions.push(orgObj);
                    this.mainOrg.push(orgObj.value);
                    this.applyObj.organization = [...this.mainOrg];
                }
            } else {
                toast.error(`No Risk Owner`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async addOrUpdaterisk(riskData: any) {
            this.v$.orgRisksTab.$touch();

            if (!this.v$.orgRisksTab.$invalid && this.displayErrorMessageOfRisk == false && this.displayErrorMessageOfThreat == false && this.displayErrorMessageOfVulnerability == false) {
                this.clickOnButton = true
                console.log('riskData', riskData);
                let copyOfriskData:any = {...riskData}
                if (this.addingRisk == true) {
                    await this.addRiskList();
                } else {
                    let oldObj: any = {};
                    let newObj: any = {};
                    Object.keys(copyOfriskData).map((key: any) => {
                        if (copyOfriskData[key] != this.riskObjCopy[key] && key != 'updatedValues') {
                            oldObj[key] = this.riskObjCopy[key];
                            newObj[key] = copyOfriskData[key];
                        }
                    });

                    copyOfriskData.updatedValues = { oldRisk: [], newRisk: [] };
                    copyOfriskData.updatedValues.oldRisk.push(oldObj);
                    copyOfriskData.updatedValues.newRisk.push(newObj);
                    oldObj.riskOwner = this.orgRisksTab.riskOwner.value;
                    newObj.riskOwner = this.orgRisksTab.riskOwner.value

                    let risk: any = [];

                    risk.push(copyOfriskData);
                    console.log('this is updated risk object', risk);
                    await this.updateRisk(risk);
                    this.openRiskPopup = false;
                }
            } else {
                console.log("error");
                // toast.error(`error`, {
                //     timeout: 1000,
                //     closeOnClick: true,
                //     closeButton: 'button',
                //     icon: true
                // });
            }
        },
        async updateRisk(riskObj: any) {
            try {
                let riskId = riskObj[0]._id;
                delete riskObj[0]._id;
                delete riskObj[0].overallImpact;
                delete riskObj[0].severity;

                riskObj[0].updatedAt = new Date();
                riskObj[0].updatedBy = this.userInfo._id;
                riskObj[0].riskOwner = riskObj[0].riskOwner.value;
                // riskObj[0].tier = 1;

                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${riskId}/update?org==true`, riskObj[0]).then((result: any) => {
                    toast.info(`Uploaded succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                    this.openRiskPopup = false;
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
            await this.getRootRisksList();
        },
        async addRiskList() {
            let copyOforgRisksTab:any = {...this.orgRisksTab}
            const finalArray: Record<string, any> = [];

            delete copyOforgRisksTab.overallImpact;
            delete copyOforgRisksTab.severity;

            finalArray.push(copyOforgRisksTab);
            finalArray.map((obj: any) => {
                obj.statusCode = 10401;
                obj.createdAt = new Date();
                obj.createdBy = this.userInfo._id;
                obj.riskOwner = copyOforgRisksTab.riskOwner.value;
                obj.org = [];
                let orgId: any = this.$route.params.orgId;
                obj.org.push(orgId);
            });
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/create?org==true`, finalArray).then((result: any) => {
                    let riskId = result.data.insertedIds[0];
                    const payload: Record<string, any> = [];

                    finalArray[0].org.map((presentProcessId: any) => {
                        let newObj = { orgId: '', riskId: [`${riskId}`], statusCode: 10401 };
                        newObj.orgId = presentProcessId;
                        payload.push(newObj);
                    });
                });
            } catch (e) {
                toast.info('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
                this.orgUsersList = res.data;
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
            });

                 for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }

        },
        async getInformationSetsforOrg() {
            this.information = {};
            this.infoDummyObj ={};
            this.optionsList = [];

            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall?org=true`).then(async (result: any) => {
                result.data.forEach((info: any) => {
                    this.optionsList.push(info);
                });
                await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall`).then((result: any) => {
                    result.data.forEach((info: any) => {
                        this.optionsList.push(info);
                    });
                });
                this.optionsList.forEach((info: any) => {
                    !this.infoCategoryList.includes(info.informationCategory) ? this.infoCategoryList.push(info.informationCategory) : '';
                    !this.infoList.includes(info.information) ? this.infoList.push(info.information) : '';
                  
                });
            });

            this.information = this.optionsList.find((information: any) => {
                return information._id == this.$route.params.informationId;
            });

            let findOwner = this.optionsUserArray.find((obj:any)=>{return obj.value == this.information.informationOwner});
            this.information.informationOwner = findOwner != undefined ? findOwner : '';

            this.infoDummyObj = { ...this.information };
        },
        async getRootRisksList() {
            const result = await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/information/${this.$route.params.informationId}/risks`);
            this.riskList = result.data;
            for (let i = 0; i < this.riskList.length; i++) {
                this.openList = this.riskList[i];
                !this.riskCategoryList.includes(this.openList.riskCategory) ? this.riskCategoryList.push(this.openList.riskCategory) : '';

                !this.threatCategoryList.includes(this.openList.threatCategory) ? this.threatCategoryList.push(this.openList.threatCategory) : '';

                !this.threatSourceList.includes(this.openList.threatSource) ? this.threatSourceList.push(this.openList.threatSource) : '';
                let averageImpact = this.average([ this.openList['business'], this.openList['operations'], this.openList['legal'], this.openList['financial']]);
                let impact = Math.round(averageImpact);
                let severity = this.riskSeverity(impact * this.openList['likelihood']);
                this.riskList[i].impact = impact;
                this.riskList[i].severity = severity;
                this.riskList[i].entityId = 20008;
            }
            this.dummyList = [...this.riskList];
        },
        async addOrUpdateInformation(info: any) {
            console.log('this.v$.$invalid', this.v$.information.$invalid);
            this.v$.$touch();
            if (!this.v$.information.$invalid) {
                this.clickOnButton = true
                console.log('asdfasdfsdf');
                await this.updateInformation(info);
                this.v$.$reset();
            } else {
                console.log("error")
                // toast.error(`error`, {
                //     timeout: 1000,
                //     closeOnClick: true,
                //     closeButton: 'button',
                //     icon: true
                // });
            }
        },
        async updateInformation(info: any) {
            let copyOfInfo:any = {...info}
            console.log('infoUpdate', info);
            copyOfInfo.informationOwner = copyOfInfo.informationOwner.value
            try {
                let informationId = copyOfInfo._id;
                delete copyOfInfo._id;
                // delete this.information.entityId;
                copyOfInfo.updatedAt = new Date();
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/${informationId}/update?org==true`, copyOfInfo).then((result: any) => {
                    toast.info(`Updated succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
            } catch (e) {
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
            await this.getInformationSetsforOrg();
        },
        
    },
    async created() {
        let savedTabKey: any = sessionStorage.getItem('selectedTab');
        savedTabKey = parseInt(savedTabKey)
		if (savedTabKey != undefined) {
		    const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
            if (savedTab) {
                await this.toggleTabs(savedTab);
            }else{
                this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
                this.selectedIndex = 0;
                this.navIndexNums = { index: 0, name: 'Risks' };
            }
		}
		else{
			this.selectedTabObj = { displayName: 'Risks', name: 'Risk', id: 0 },
            this.navIndexNums = { index: 0, name: 'Risks' };
			this.selectedIndex = 0;
		}
    },
    beforeUnmount() {
        sessionStorage.removeItem('selectedTab')
    }
});
</script>

